import { Vue, axios, store, EventBus, router } from './common.js'

export default class training extends Vue {

    checkregex(valuse, id) {
        let isValidPattern = true;
        try {
            new RegExp(valuse);
        } catch (e) {
            isValidPattern = false;
        }
        var element = document.getElementById(id);
        if (element) {
            if (!isValidPattern) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
        return isValidPattern;
        // callback(isValidPattern)
    }
    getboxposition(pos) {
        const returnposition = {};
        returnposition.left = "";
        returnposition.right = "";
        returnposition.top = "";
        returnposition.bottom = "";
        returnposition.x = 0;
        returnposition.y = 0;
        const position = pos;
        // Calculate the element's right, bottom, left, and top positions
        const right = position.right + 264;
        const rightcheck = position.right + 528;
        const bottom = position.bottom + 180;
        const bottomcheck = position.bottom + 360;
        // const left = position.left + 264;
        // const top = position.top + 180;

        // Get the dimensions of the window
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        /*
        if (rightcheck > windowWidth || bottomcheck > windowHeight) {
        // Adjust the element's position to keep it within the screen boundaries
        if (rightcheck > windowWidth) {
           returnposition.left = (position.left - 264) + 'px';
           returnposition.top = bottom + 'px';
        }

        if (bottomcheck > windowHeight) {
           returnposition.top = (position.top - 180) + "px";
           returnposition.left = right + "px";
        }
        */

        if (rightcheck > windowWidth && bottomcheck > windowHeight) {
            returnposition.left = (position.left - 264) + 'px';

            if ((position.top - 180) < 250) {
                returnposition.top = "250px";
            } else {
                returnposition.top = (position.top - 180) + "px";
            }

        } else if (rightcheck > windowWidth) {
            returnposition.left = (position.left - 264) + 'px';
            returnposition.top = bottom + 'px';

        } else if (bottomcheck > windowHeight) {
            returnposition.top = (position.top - 180) + "px";
            returnposition.left = right + "px";
        }
        else {
            // Set the element's original position
            returnposition.top = bottom + 'px';
            returnposition.left = right + 'px';
        }

        if (windowWidth > position.x + 528) {
            returnposition.x = position.x + 264;
        } else {
            returnposition.x = position.x - 264;
        }

        if (windowHeight > position.y + 360) {
            returnposition.y = position.y + 180
        } else {
            returnposition.y = position.y - 180
        }
        return returnposition;
    }
    formatversion(data, name) {
        const isValidFormat = /^\d{2}\.\d{2}$/.test(data[name]);
        data.isvalidversion = isValidFormat;
        if (!isValidFormat) {
            this.$alert("Please enter a version in the format 00.00", 'Invalid format', {
                confirmButtonText: 'OK',
                dangerouslyUseHTMLString: true,
                type: 'warning'
            })
            data[name] = "";
        }
    }
    Gettrainingpopup(cellname) {
        var customParam = { showguidancedialog: true };
        var routeWithParams = Object.assign({}, router.currentRoute, { params: customParam });
        EventBus.$emit('userguidegotit', null, routeWithParams, cellname);
    }
    getdirectionsbypagename(route) {
        var query = "";
        if (route && route.name != "") {
            query = route.name;
        }
        if (route.name != "access" && route.name != "updatedatabasestructure" && route.name != "errorlog" && route.name != "superadmin") {
            axios.post("direction/getdirectionsbypagename?pagename=" + query, {})
                .then(response => {
                    store.state.directions = response.data;
                })
                .catch(err => {
                    EventBus.$emit("error", err);
                });
        }
    }
    RG_Direction_Match(checkvalue, inc_pattern, exc_pattern, format_pattern) {
        var isinclude = null;
        var isexclude = null;
        var isformat = null;
        var value = "";
        checkvalue = checkvalue == undefined ? '' : checkvalue;
        if (typeof checkvalue != "string") {
            value = checkvalue.toString();
        } else {
            value = checkvalue;
        }
        if (inc_pattern != null) {
            isinclude = inc_pattern.test(value);
        }
        if (exc_pattern != null) {
            isexclude = !exc_pattern.test(value);
        }
        if (format_pattern != null) {
            isformat = format_pattern.test(value);
        }
        if (isinclude !== null && isexclude !== null && isformat !== null) {
            return isinclude && isexclude && isformat;
        }
        if (isinclude !== null && isexclude !== null) {
            return isinclude && isexclude;
        }
        if (isinclude !== null && isformat !== null) {
            return isinclude && isformat;
        }
        if (isexclude !== null && isformat !== null) {
            return isexclude && isformat;
        }
        if (isinclude !== null) {
            return isinclude
        }
        if (isexclude !== null) {
            return isexclude
        }
        if (isformat !== null) {
            return isformat
        }
        return true;

    }
    checkcellvalue(data) {
        var newarray = [];
        var inc_pattern = null;
        var exc_pattern = null;
        var format_pattern = null;
        if (data.includerule != null && data.includerule != "") {
            inc_pattern = new RegExp(data.includerule, "i");
        }
        if (data.excluderule != null && data.excluderule != "") {
            exc_pattern = new RegExp(data.excluderule, "i");
        }
        if (data.formatrule != null && data.formatrule != "") {
            format_pattern = new RegExp(data.formatrule, "i");
        }
        var chickvalues = document.querySelectorAll(`[id^="${data.pagecell.cellname}"]`);
        if (chickvalues && chickvalues.length > 0) {
            var isshow = false;
            //   var newelement = null;
            for (let index = 0; index < chickvalues.length; index++) {
                const element = chickvalues[index];
                //   if(element.value != undefined){ 
                // Also check the direction popup if the value is undefined as Amal said to allow us to check for empty cells and extra spaces.
                var isshow = this.RG_Direction_Match(element.value, inc_pattern, exc_pattern, format_pattern);
                if (isshow == false) {
                    newarray.push({
                        element: element,
                        id: element.id,
                        inc_pattern: inc_pattern,
                        exc_pattern: exc_pattern,
                        format_pattern: format_pattern
                    });
                }
                // }  
            }
            return newarray;
        } else {
            return newarray;
        }
    }
    getdirectionbycellname(actionname, callback, newsection, testdata, testcallback) {
        var section = "";
        if (newsection) {
            section = newsection;
        }
        if (!section && store.state.section) {
            section = store.state.section;
        }
        axios.post("Direction/getdirectionbyactionname?actionname=" + actionname + "&sectionname=" + section, {})
            .then(response => {
                if (response.data.length > 0) {
                    if (testdata != true) {
                        var myarray = [];
                        var countindex = 1;
                        response.data.forEach(ee => {
                            var canshow = true;
                            if (!section && ee.directionsections.length > 0) {
                                canshow = false;
                            }
                            let matchedcellscount = 0;
                            for (let i = 0; i < ee.directioncelldetails.length; i++) {
                                const directioncell = ee.directioncelldetails[i];
                                ee.includerule = directioncell.includerule;
                                ee.excluderule = directioncell.excluderule;
                                ee.formatrule = directioncell.formatrule;
                                ee.pagecell = directioncell.pagecell;
                                ee.pagecellid = directioncell.pagecellid;
                                ee.celltype = directioncell.pagecell.celltype;
                                ee.objectpath = directioncell.pagecell.objectpath;
                                ee.checkvalue = directioncell.checkvalue;
                                ee.operation = directioncell.operation;
                                ee.inc_pattern = null;
                                ee.exc_pattern = null;
                                ee.format_pattern = null;

                                var FormData = ee;
                                const option = JSON.parse(JSON.stringify({
                                    element: null,
                                    uniqueid: null,
                                    visible: true,
                                    sectionname: section,
                                    isedit: false,
                                    id: FormData.id,
                                    boxtype: "Small 2 Part Pop up",
                                    content: FormData.message,
                                    mediacontent: FormData.mediacontent,
                                    link: FormData.link,
                                    offercloseoption: FormData.offercloseoption,
                                    heading: FormData.directionname,
                                    directiondegree: FormData.directiondegree,
                                }));

                                let newarray = this.$directionutility.validatedirectionmatch(FormData, actionname);
                                //    var newarray =  this.checkcellvalue(FormData)
                                if (newarray.length > 0 && canshow == true) {
                                    newarray.forEach(newvalue => {
                                        var newobj = JSON.parse(JSON.stringify(option))
                                        let element = newvalue;
                                        // var topPos = {};
                                        // topPos = element.getBoundingClientRect(); 
                                        // newobj.position = topPos;
                                        newobj.uniqueid = countindex;
                                        newobj.elementid = element.id;
                                        newobj.element = element.element;
                                        newobj.inc_pattern = element.inc_pattern;
                                        newobj.exc_pattern = element.exc_pattern;
                                        newobj.format_pattern = element.format_pattern;
                                        newobj.celltype = element.celltype;
                                        newobj.objectpath = element.objectpath;
                                        newobj.checkvalue = element.checkvalue;
                                        newobj.operation = element.operation;
                                        newobj.enteredvalue = element.enteredvalue;
                                        myarray.push(newobj);
                                        countindex++;
                                    });
                                    matchedcellscount++;
                                }
                            }

                            if (matchedcellscount != ee.directioncelldetails.length) {
                                myarray = [];
                            }

                        });
                        if (myarray.length > 0) {
                            store.state.currentdirectionindex = 0;
                            store.state.directionsdialogue = [];
                            store.state.directionsdialogue = myarray;
                            store.state.currentdirection = myarray[0];
                        } else {
                            callback();
                        }
                    } else {
                        testcallback(response.data, callback);
                        // this.validationdirectionfromdata(response.data,testdata)
                    }
                } else {
                    callback();
                }
            })
            .catch(err => {
                this.$bus.$emit("error", err);
            });
    }
    ignoredirection(directionid, section) {
        axios.post("Direction/ignoredirection?directionid=" + directionid + "&section=" + section, {})
            .then(response => {
            })
            .catch(err => {
                EventBus.$emit("error", err);
            });
    }

    async savepagelayout(row) {
        try {
            const response = await axios.post("account/savepagelayout", row.ugv);
            store.state.user.usergridviews = response.data;
            let ret = response.data.find(x => x.p.name === row.p.name);
            return ret;

        } catch (error) {
            EventBus.$emit("error", error);
        }
    }

    searchuniqueparts(query) {
        if (query == undefined) {
            query = "";
        }
        return new Promise((resolve, reject) => {
            this.$http.post("project/searchuniqueparts?q=" + query, {})
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    searchuniquesections(query) {
        if (query == undefined) {
            query = "";
        }
        return new Promise((resolve, reject) => {
            this.$http.post("project/searchuniquesections?q=" + query, {})
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    getallprojects() {
        return this.$http.post("project/getallprojects")
            .then(response => {
                return response.data;
            })
            .catch((err) => {
                this.$bus.$emit('error', err);
            });
    }

    getallsection() {
        return this.$http.get('project/GetSectionsProjectCreate')
            .then(response => {
                return response.data;
            }).catch(err => {
                this.$bus.$emit('error', err);
            });
    }

    getallrfisection() {
        return this.$http.get('RFI/getrifsection')
            .then(response => {
                return response.data;
            }).catch(err => {
                this.$bus.$emit('error', err);
            });
    }

    getusergridrow(currentpage) {
        let ret = store.state.user.usergridviews.find(x => x.p.name === currentpage.name);
        if (ret.ugv.rowperpage <= 0) {
            ret.ugv.rowperpage = 20; //Default pages 20
        }
        return ret;
    }

    calculaterows(comment, val) {
        const contentLength = comment ? comment.length : 1;
        const rows = Math.ceil(contentLength / val);
        return rows > 1 ? rows : 1;
    }

    calculateheight(comment, val) {
        const lineHeight = 20;
        const rows = this.calculaterows(comment, val);
        let size = rows * lineHeight;
        if (rows == 1) {
            return size + 6;
        }
        if (rows == 2) {
            return size + 4;
        }
        return size;
    }

    getprojectusingparam(params) {
        let projectid = parseInt(params.get('projectid'));
        let partid = parseInt(params.get('partid'));
        let sectionid = parseInt(params.get('sectionid'));

        if (isNaN(projectid) || isNaN(partid) || isNaN(sectionid)) {
            this.$alert("One or more parameters are not valid numbers", 'Invalid URL');
        } else {
            store.state.projectids = {
                projectid,
                partid,
                sectionid
            };
            let data = store.state.projectids;
            this.$bus.$emit("addproject", data);
        }
    }

    checkandparseint(val) {
        let parsedVal = parseInt(val);
        if (!isNaN(parsedVal)) {
            return parsedVal;
        }
        return null;
    }

    checksectionforchecklist() {
        return this.$http.post("ElementList/getelementlisttemplatebytype?type=" + 3,)
            .then(response => {
                return response.data;
            }).catch(err => {
                this.$bus.$emit('error', err);
            });
    }

    verifycheckingpage(data) {
        store.state.isLoading = true;
        let url = "amos/verifycheckingpage";
        return this.$http.post(url, data)
            .then(response => {
                store.state.isLoading = false;
                return response.data;
            }).catch(err => {
                store.state.isLoading = false;
                this.$bus.$emit('error', err);
            });
    }
}