<template>
    <div v-on:resize  v-loading="loading">
          <div class="fixdheader">
        <el-row>
        <el-col :span="12" class="headerstyle"> AMOS (Automated Measurement of Schedules)</el-col>
        <el-col :span="12">
            <div v-show="detail" class="padding2px right" >
                <!-- <el-button title="RFI" type="primary" @click="$router.push('/rfientryform')" style="padding: 7px 7px;" class="marginleft4px left" :disabled="!editpermission"> RFI </el-button> -->
                  <el-upload ref="upload"
                                            action=""
                                            :disabled="!editpermission"
                                            :http-request="(file, fileList)=>{addAttachment(file, fileList),clickevant('amos_amos_importamos') } "
                                            :on-remove="deleteAttachment"
                                            :file-list="attachments"
                                            accept=".xlsx"
                                             class="upload-demo left"
                            >
                            <el-button id="amos_amos_importamos"  :disabled="!editpermission"  class="marginleft4px " slot="trigger" type="primary" >Import</el-button>
                        </el-upload>

                    <el-button id="amos_amos_filehistory" type="primary" class="marginleft4px padding7" @click="()=>{getfilehistory(),clickevant('amos_amos_filehistory') }">File History
                    </el-button>

                    <el-button type="primary"
                               class="padding7" 
                               @click="viewheader" 
                               :disabled="!editpermission">
                               Add Header</el-button>
                        
                    <el-button  id="amos_amos_rowdesignationch" :disabled="!editpermission" class="marginleft4px" slot="" @click="()=>{clickevant('amos_amos_rowdesignationch',undefined,()=>{$router.push('/rowdesignationch')},'RD') } " type="primary" >RD</el-button>
                    <el-button id="amos_amos_runallocation"  v-if="canaccessallocation && editpermission" :disabled="!editpermission" class="marginleft4px" @click="()=>{setamosallocation(),clickevant('amos_amos_runallocation')}" slot="" type="primary" >Run Allocation</el-button>

                <el-button id="amos_amos_saveamos" :key="savebuttonkey" :disabled="!editpermission" class="marginleft4px" slot=""  @click.once="cellchanged('amos_amos_saveamos',()=>{save()},false),clickevant('amos_amos_saveamos')" type="success" >Save</el-button>

                <el-button  id="amos_amos_savetoproject" :disabled="!editpermission" slot="" @click=" cellchanged('amos_amos_savetoproject',()=>{savetoproject(true)},false),clickevant('amos_amos_savetoproject') " type="success" >Save to Project</el-button>


        </div>
        </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <projectselectpart :absoluteurl="true" :training="true" @projectsectionchange="projectsectionchange"> </projectselectpart>
            </el-col>
            <!-- <el-col :span="7" > -->
        <!-- <div class="centerscreen" v-loading="loading"></div> -->

            <!-- </el-col> -->
        </el-row>
        <!-- <hr class="hrstyle" /> -->
        <el-row v-show="detail" class="topbotmrowborder">
            <el-col>
                 <div style="width: 200px; display: inline-block; float: left">
                     <el-input
                     class="padding2px"
                     id="amos_amos_bqcode_search"
                            v-model="amosFilters.searchBQCode"
                            clearable
                               @change="searchbqcodechange"
                            :spellcheck="isspellcheck"
                            placeholder="BQ Code"
                            @keyup.enter.native="()=>{handleFilterChanges(),clickevant('amos_amos_bqcode_search')}"
                            @clear="handleFilterChanges()">
                            <el-button slot="append" class="paddingl13" circle @click="()=>{handleFilterChanges(),clickevant('amos_amos_bqcode_search')}">Q</el-button>
                        </el-input>
                 </div>

                    <div style="width: 230px; display: inline-block;float: left">
                        <el-input
                        class="padding2px"
                        v-model="amosFilters.searchAstSpeOther"
                        clearable
                        id="amos_amos_AstSpeOther_search"
                        @change="searchastspeotherchange"
                        :spellcheck="isspellcheck"
                        placeholder="Specification/Asterisk//End Notes"
                        @keyup.enter.native="()=>{handleFilterChanges(),clickevant('amos_amos_AstSpeOther_search')}"
                        @clear="handleFilterChanges()"
                       >
                        <el-button slot="append" class="paddingl13" circle @click="()=>{handleFilterChanges(),clickevant('amos_amos_AstSpeOther_search')}">Q</el-button>
                        </el-input>
                    </div>
                    <div>
                        <el-select :style="{ width: '100px' }" id="amos_amos_view_mode_search" class="padding2px" v-model="viewmode" @change="()=>{changedisplaymode(),clickevant('amos_amos_view_mode_search')}">
                            <el-option v-for="(item, index) in viewlist"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.active">
                            </el-option>
                        </el-select>
                    </div>
            </el-col>
        </el-row>

        <el-row v-show="detail && openheader" class="toprowborder">
            <el-col :span="24" v-if="detailform && detailform.section">
                <el-select class="width-530 padding2px iconbackgroundwhite iconheight92pr suffix-icon-right1"
                    placeholder="Select Header" filterable clearable  v-model="detailform.section.headerlibraryid">
                    <el-option class="width-530 padding5px fontsize12 custom_select_dropdown_item"
                        v-for="item in sectionheaders" :key="item.id" :label="item.description"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>

          </div>

            <div class="file-container" v-if="viewmode && detail">                
                <div v-for="(row, index) in displayimportedtab" :key="index" 
                    :class="`file-group ${row.id == showamosid ? ' highlightfile' : ''}`">
                    <span @click="expandfilebytab(row)">Added by: {{ row.editedbyloginname }} File Name: {{ row.filename }}
                    </span>
                </div>
            </div>

            <div class="spacebetween" />            
            <el-table ref="pa"
                      class="tableBox fullwidth"
                      :data="getprojectamoses"
                      row-key="id"
                      :border="true"
                      :expand-row-keys="expandedrows"
                      @expand-change="expandchange"
                      :show-header="false"   >
                <el-table-column label=""
                                 width="35"
                                 type="expand"
                                 :border="true">
                    <template slot-scope="pa">
                        <!-- :height="scheduleheight(pa.row, getprojectamoses)" -->
                        <el-table :data="getdetails(pa.row.details,pa.row)"
                                  class="tableBox fullwidth"
                                  :height="mycustomheight"
                                  :row-style="($event)=>{return getamosrowstyle($event)} "
                                  :border="true"
                                  :cell-style="getamoscellstyle"
                                  @header-click="(column, event)=>{myresizelclick(column, event,pa.row,pa.$index)}"
                                  @header-dragend="myresizel"
                                  ref="detailtable"
                                  id="detailtable"
                                  :key="pa.row.id">
                            <el-table-column  :resizable="true" label="RN" align="center" prop="rowno" width="30" fixed>
                                <template slot="header">
                                    <div :id="`amos_amos_table_columns_1to20_${pa.$index}_NR`" @click="clickevant('amos_amos_table_columns_1to20_',`amos_amos_table_columns_1to20_${pa.$index}_NR`)">
                                        <el-popover placement="top" trigger="hover" content="Row No">
                                            <span slot="reference"  >RN</span>
                                        </el-popover>
                                    </div>
                                </template>
                                <template slot-scope="scope">
                                    <span :id="`${rowhightlightids[5]}_${pa.$index}_${scope.$index}`">{{ scope.row.rowno }}</span>
                                </template>
                            </el-table-column>

                            <template v-if="pa.row.command != 'amos'">
                                <template v-for="cl in 20">
                                    <!-- <div v-if="cl > 0 && pa.row.maxcolumn >= cl" > -->
                                    <el-table-column
                                        v-if="cl > 0 && pa.row.maxcolumn >= cl"
                                        :resizable="true" 
                                        :key="'excel-' + cl"
                                        :prop="'col'+cl" 
                                        :label="'col'+cl"
                                        :width="columnwidth(pa.row,cl)"
                                        :min-width="10"
                                        :class="pa.row.command == 'amos' || pa.row.command == 'other' ? '' : ''" 
                                        >
                                        <template slot="header">
                                            <div :id="`amos_amos_table_columns_1to20_${pa.$index}_col${cl}`" class="center">
                                                {{ cl > 9 ? cl+'' : '0'+cl }}
                                            </div>
                                        </template>

                                        <template slot-scope="scope">
                                            <div class="CH_row_style" v-if="scope.row.predicate == 'CH' || scope.row.predicate == 'SN'">
                                                {{ scope.row['col'+cl] }}
                                            </div>

                                            <div v-else class="padding_left_5 " :class=" !iswrapColumn(scope,pa.row,cl)  ? 'wrapColumnTexttwonormal' : 'wrapColumnTexttwopre'">
                                                    {{ scope.row['col'+cl] }}
                                            </div>
                                        </template>
                                    </el-table-column>
                                <!-- </div> -->
                                </template>
                            </template>
                            <!-- <el-table-column :index="1" v-if="pa.row.command == 'excel'" :resizable="true" label="01" prop="col1" :width="columnwidth(pa.row,1)"></el-table-column>
                            <el-table-column :index="2" v-if="pa.row.command == 'excel'" :resizable="true" label="02" prop="col2" :width="columnwidth(pa.row,2)"></el-table-column>
                            <el-table-column :index="3" v-if="pa.row.command == 'excel'" :resizable="true" label="03" prop="col3" :width="columnwidth(pa.row,3)"></el-table-column>
                            <el-table-column :index="4 " v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 4 " :resizable="true" label="04" prop="col4" :width="columnwidth(pa.row,4)"></el-table-column>
                            <el-table-column :index="5 " v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 5 " :resizable="true" label="05" prop="col5" :width="columnwidth(pa.row,5)"></el-table-column>
                            <el-table-column :index="6 " v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 6 " :resizable="true" label="06" prop="col6" :width="columnwidth(pa.row,6)"></el-table-column>
                            <el-table-column :index="7 " v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 7 " :resizable="true" label="07" prop="col7" :width="columnwidth(pa.row,7)"></el-table-column>
                            <el-table-column :index="8 " v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 8 " :resizable="true" label="08" prop="col8" :width="columnwidth(pa.row,8)"></el-table-column>
                            <el-table-column :index="9 " v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 9 " :resizable="true" label="09" prop="col9" :width="columnwidth(pa.row,9)"></el-table-column>
                            <el-table-column :index="10" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 10" :resizable="true" label="10" prop="col10" :width="columnwidth(pa.row,10)"></el-table-column>
                            <el-table-column :index="11" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 11" :resizable="true" label="11" prop="col11" :width="columnwidth(pa.row,11)"></el-table-column>
                            <el-table-column :index="12" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 12" :resizable="true" label="12" prop="col12" :width="columnwidth(pa.row,12)"></el-table-column>
                            <el-table-column :index="13" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 13" :resizable="true" label="13" prop="col13" :width="columnwidth(pa.row,13)"></el-table-column>
                            <el-table-column :index="14" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 14" :resizable="true" label="14" prop="col14" :width="columnwidth(pa.row,14)"></el-table-column>
                            <el-table-column :index="15" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 15" :resizable="true" label="15" prop="col15" :width="columnwidth(pa.row,15)"></el-table-column>
                            <el-table-column :index="16" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 16" :resizable="true" label="16" prop="col16" :width="columnwidth(pa.row,16)"></el-table-column>
                            <el-table-column :index="17" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 17" :resizable="true" label="17" prop="col17" :width="columnwidth(pa.row,17)"></el-table-column>
                            <el-table-column :index="18" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 18" :resizable="true" label="18" prop="col18" :width="columnwidth(pa.row,18)"></el-table-column>
                            <el-table-column :index="19" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 19" :resizable="true" label="19" prop="col19" :width="columnwidth(pa.row,19)"></el-table-column>
                            <el-table-column :index="20" v-if="pa.row.command == 'excel' && pa.row.maxcolumn >= 20" :resizable="true" label="20" prop="col20" :width="columnwidth(pa.row,20)"></el-table-column> -->
                            <!-- <div v-if="pa.row.command != 'excel'">   -->
                                <el-table-column   class-name="lightgray" :resizable="true" width="10"></el-table-column>
                                
                            <template v-if="pa.row.command != 'excel'">
                            <el-table-column  :resizable="true" label="RD" width="80">
                                 <template slot="header" >
                                    <el-popover placement="top" trigger="hover" content="Row Designation">
                                        <span slot="reference">
                                            <el-button :id="`amos_amos_table_columns_header_RD_${pa.$index}`" type="info" @click="()=>{clickevant('amos_amos_table_columns_header_RD_',`amos_amos_table_columns_header_RD_${pa.$index}`,()=>{FunRD(pa.row)},'Go to Row Designation')}" :disabled="!editpermission" plain><b>RD</b></el-button>
                                        </span>
                                    </el-popover>
                                </template>

                                <template slot-scope="scope">
                                    <el-row>
                                        <el-col :span="scope.row.predicate == 'CH' ? 16 : 24">
                                            <el-select v-model="scope.row.predicate"
                                                    filterable
                                                    :id="`amos_amos_table_predicate_${pa.$index}_${scope.$index}`"
                                                    placeholder="Predicate"
                                                    :disabled="scope.row.predicate == 'Edit'"
                                                    @change="()=>{predicatechanged(pa.row, scope.row, scope.$index, 'predicate'),clickevant('amos_amos_table_predicate_',`amos_amos_table_predicate_${pa.$index}_${scope.$index}`)}">
                                                <el-option v-for="item in predicates"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value"
                                                        placeholder="Predicate"
                                                        :disabled="item.value == 'Edit' || !editpermission">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                        <el-col :span="8" v-if="scope.row.predicate == 'CH'">
                                            <i class="el-icon-caret-bottom" v-if="pa.row.lastch != scope.row.id" :id="'chrow_'+scope.row.id" @click="setchbottom('chrow_',pa.row,scope.$index,scope.row)"></i>
                                            <i class="el-icon-caret-top" :class="pa.row.lastch != scope.row.id ? '':'marginright11px'" v-if="canshowctop(pa.row,scope.row)"  @click="setchtop('chrow_',pa.row,scope.$index,scope.row)"></i>
                                            
                                        </el-col>
                                </el-row>
                                </template>
                            </el-table-column>

                            <el-table-column :resizable="true" label="AM_T" width="80">
                                <template slot="header">
                                    <el-popover placement="top" trigger="hover" content="Automated Measurement Template">
                                        <span slot="reference">
                                             <el-button :id="`amos_amos_table_columns_header_AM_T_${pa.$index}`"  @click="clickevant('amos_amos_table_columns_header_AM_T_',`amos_amos_table_columns_header_AM_T_${pa.$index}`)" type="info" :disabled="!editpermission" plain><b>AM_T</b></el-button>
                                        </span>
                                    </el-popover>
                                </template>

                                <template slot-scope="scope">
                                    <!--<div v-if="scope.row.predicate=='SN'">
                                        <el-input autocomplete="off" v-model="scope.row.nrmsortingref"> </el-input>
                                    </div>-->
                                    <div v-show="scope.row.predicate=='CH'">
                                        <el-select v-model="scope.row.amtemplateid"
                                                   filterable
                                                   remote
                                                   :id="`amos_amos_table_amt_${pa.$index}_${scope.$index}`"
                                                   clearable
                                                   :remote-method="query => remoteSearchAmt(query, scope.row)"
                                                   @focus="focusSearchAmt(scope.row)"
                                                   @change="amtchanged(scope.row, $event, true, pa.row, scope.$index)">
                                            <el-option v-for="item in scope.row.amtoptions" :disabled="!editpermission"
                                                       :key="item.id"
                                                       :label="item.amtemplatename"
                                                       :value="item.id">
                                                <div v-if="item.id != null">
                                                    <span style="float: left">{{ item.amtemplatename }} &nbsp;&nbsp;</span>
                                                    <el-button icon="el-icon-edit" @click="amtemplateid=item.id;amtemplateedit=true" type="text" size="mini" class="floatright"></el-button>
                                                </div>
                                                <div v-if="item.id == null">
                                                    <el-button icon="el-icon-circle-plus-outline" @click="amtemplateid=0;amtemplateedit=true" type="text" size="mini" style="float: left"></el-button>
                                                </div>
                                            </el-option>
                                        </el-select>
                                        

                                        <!--<el-autocomplete class="inline-input fullwidth"
                                                         autocomplete="off"
                                                         popper-class="morewidth-autocomplete"
                                                         v-model="scope.row.amt.amtname"
                                                         :fetch-suggestions="querySearchAmt"
                                                         placeholder=""
                                                         @select="handleSelectAmt(scope.row, $event,'select')"
                                                         :select-when-unmatched="true"
                                                         value-key="amtname"
                                                         :trigger-on-focus="true"
                                                         :clearable="false"
                                                         @blur="handleSelectAmt(scope.row, $event,'blur')"
                                                         @Clear="handleSelectAmt(scope.row, $event)">
                                            <template slot-scope="{ item }">
                                                <div style="white-space:normal;">{{ item.amtname }}</div>
                                            </template>
                                        </el-autocomplete>-->
                                    </div>
                                    <div v-if="scope.row.predicate=='Pre'">
                                        <i v-if="editpermission && canaccessallocation" @click="amosallocationopen(scope.row)" style="color: rgb(0, 162, 232) !important;" class="el-icon-s-tools"></i>
                                            <!-- <el-button    icon="el-icon-s-tools"  slot="" type="primary" ></el-button> -->
                                        </div>
                                </template>
                            </el-table-column>

                            <el-table-column :resizable="true" label="NRM Sort" width="200">
                                 <template slot="header">
                                     <el-button class="nrmslot" :id="`amos_amos_table_columns_header_NRM_Sort_${pa.$index}`"  @click="clickevant('amos_amos_table_columns_header_NRM_Sort_',`amos_amos_table_columns_header_NRM_Sort_${pa.$index}`)" type="info" :disabled="!editpermission" plain><b>NRM Sort</b></el-button>
                                </template>
                                <template slot-scope="scope">
                                    <div v-if="scope.row.predicate=='CH'">
                                        <el-select v-model="scope.row.nrmsortingref"
                                                    @focus="amtchanged(scope.row, scope.row.amtemplateid, false, pa.row, scope.$index)"
                                                   filterable
                                                   remote
                                                   clearable
                                                   :id="`amos_amos_table_nrmsort_${pa.$index}_${scope.$index}`"
                                                   class="fullwidth">
                                            <el-option v-for="item in scope.row.nrmsortoptions" :disabled="!editpermission"
                                                       :key="item.id"
                                                       :label="item.description"
                                                       :value="item.description">
                                                <span :style="(item.fromamt) ? 'font-weight: bold;' : ''">{{ item.description }}</span>
                                            </el-option>
                                        </el-select>
                                        <!--<el-input autocomplete="off" v-model="scope.row.nrmsortingref"> </el-input>-->
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column :resizable="true" label="BQ_Code" width="320">
                                <template slot="header">
                                    <el-button type="info" :id="`amos_amos_table_columns_header_BQ_Code_${pa.$index}`"   :disabled="!editpermission" @click="()=>{clickevant('amos_amos_table_columns_header_BQ_Code_',`amos_amos_table_columns_header_BQ_Code_${pa.$index}`,()=>{bqcodeselection(pa.row)},'Go to BQ_Code')}" plain><b>BQ_Code</b></el-button>
                                </template>
                                <template slot-scope="scope">
                                    <div v-if="scope.row.predicate=='CH'" >
                                        <el-button type="info" :id="`amos_amos_table_CH_header_BQ_Code_${pa.$index}_${scope.$index}`" :disabled="!editpermission" plain @click="()=>{clickevant('amos_amos_table_CH_header_BQ_Code_',`amos_amos_table_CH_header_BQ_Code_${pa.$index}_${scope.$index}`,()=>{getsectionbqcode(pa.row, scope.row, scope.$index)},'Select a BQ_Code')}"><b>Select a BQ_Code for All Rows Under CH</b></el-button>
                                        <el-button type="primary" class="padding7px9px" :id="`amos_amos_table_CH_header_setting_box_${pa.$index}_${scope.$index}`" @click="()=>{openCHSettingBoxDialgoue(pa.row, scope.row, scope.$index),clickevant('amos_amos_table_CH_header_setting_box_',`amos_amos_table_CH_header_setting_box_${pa.$index}_${scope.$index}`)}" :disabled="!editpermission" plain><b>CH</b></el-button>
                                    </div>
                                    <div v-if="scope.row.predicate=='Pre'">
                                        <el-autocomplete class="inline-input fullwidth"
                                                         popper-class="morewidth-autocomplete"
                                                         v-model="scope.row.bqcodelibrary.bqcode"
                                                         :fetch-suggestions="(query, done) => {querySearchBQCode(query, done, pa.row, scope.row, scope.$index)}"
                                                         placeholder=""
                                                         :disabled="!editpermission"
                                                         @select="handleSelectBQCode(scope.row, $event)"
                                                         :select-when-unmatched="true"
                                                         value-key="bqcode"
                                                         :id="`amos_amos_table_bq_code_${pa.$index}_${scope.$index}`"
                                                         :trigger-on-focus="true"
                                                         :clearable="false"
                                                         @change="handlechangeBQCode(scope.row)"
                                                         @blur="bqcodeblur(scope.row, $event)"
                                                         @Clear="handleSelectBQCode(scope.row, $event)"
                                                         @input="BQAstSpeEndInputChanges(scope.row)">
                                            <template slot-scope="{ item }">
                                                <div v-if="item.id > 0">
                                                    <div style="white-space:normal;"><span :style="(item.fromamt) ? 'font-weight: bold;' : ''">{{ item.bqcode }}</span></div>
                                                    <div style="white-space:normal;font-size: x-small"><span :style="(item.fromamt) ? 'font-weight: bold;' : ''">{{ item.section }} | {{ item.group }} | {{ item.work }} | {{ item.level1 }} | {{ item.level2 }} | {{ item.level3 }}</span></div>
                                                </div>
                                            </template>
                                            <el-button :disabled="!scope.row.bqcodelibrary.bqcode || !editpermission" slot="suffix" icon="el-icon-edit" @click="bqcodelibraryid=scope.row.bqcodelibrary.id;projectbqcode=true" type="text" size="mini"> </el-button>
                                        </el-autocomplete>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column :resizable="true" label="Qty" width="60" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.predicate=='CH'">
                                        <el-select v-model="scope.row.qty"
                                                   filterable
                                                   placeholder="Qty"
                                                   :id="`amos_amos_table_CH_header_qty_${pa.$index}_${scope.$index}`"
                                                   @change="()=>{headerchanged(pa.row, scope.row, scope.$index, 'qty',true),clickevant('amos_amos_table_CH_header_qty_',`amos_amos_table_CH_header_qty_${pa.$index}_${scope.$index}`)}">
                                            <template slot="prefix">
                                                <el-tag v-if="scope.row.qty" style="color: #909399;">{{scope.row.qty}}</el-tag>
                                            </template>
                                                <el-option v-for="item in scope.row.qtycolumns"  :disabled="!editpermission"
                                                           :key="item.key"
                                                           :label="item.key"
                                                           :value="item.key">
                                                </el-option>
                                        </el-select>
                                    </div>
                                    <div v-if="scope.row.predicate=='Pre'">
                                        <div :id="`amos_amos_table_qty_${pa.$index}_${scope.$index}`">
                                            {{scope.row.qty}}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column :resizable="true" label="Unit"  width="33" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.predicate=='Pre' && scope.row.bqcodelibrary.bqcode != null ">
                                        {{scope.row.bqcodelibrary.unit}}
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column :resizable="true" label="Specification" width="173">
                                  <!-- <template slot="header">
                                             <el-button type="info" :disabled="!editpermission" plain><b>Specification</b></el-button>
                                </template> -->
                                <template slot-scope="scope">
                                    <!--<div v-if="scope.row.predicate=='SN'">
                                        <el-input v-model="scope.row.specification"
                                                  placeholder="Separator"
                                                  @change="headerchanged(pa.row, scope.row, scope.$index+1, 'speseparator')">
                                        </el-input>
                                    </div>-->
                                    <div v-if="scope.row.predicate=='CH'">
                                <el-select v-model="scope.row.lstspecification"
                                                   filterable
                                                   :id="`${rowhightlightids[0]}_${pa.$index}_${scope.$index}`"                                                   
                                                   multiple
                                                   placeholder="Specification"
                                                   class="selecttag selectinputleft5"
                                                   @remove-tag="removetag($event,pa.row, scope.row, scope.$index, 'specification',true)"
                                                   @change="()=>{headerchanged(pa.row, scope.row, scope.$index, 'specification',true),clickevant(`${rowhightlightids[0]}_`,`${rowhightlightids[0]}_${pa.$index}_${scope.$index}`)}"
                                                  
                                                   style="width:136px;">
                                            <el-option v-for="item in scope.row.specolumns" :disabled="!editpermission"
                                                       :key="item.key"
                                                       :label="item.key"
                                                       :value="item">
                                            </el-option>
                                        </el-select>

                                        <el-popover placement="bottom-start"
                                                    width="300"
                                                    trigger="click">
                                            <span style="font-size:16px;">Add Additional Text</span>
                                            <el-popover placement="top-end"
                                                        width="300"
                                                        trigger="hover">
                                                <i class="el-icon-info floatright" slot="reference"></i>
                                                <span>
                                                    <i class="el-icon-info"></i>
                                                    Form to allow users to insert puctuation,
                                                    text, etc. after each section of transposed
                                                    data (relating to this column heading CH)
                                                </span>
                                            </el-popover>
                                             <el-table :data="specificationhomearray"
                                                          v-if="scope.row.lstspecification.length == 0" >
                                                    <el-table-column prop="key" label="Column" width="60" >
                                                        <template slot-scope="lst">
                                                                {{homelable}}
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="text"  label="Text" >
                                                        <template slot-scope="lst">
                                                            <!-- :id="`amos_amos_table_specification_add_text_home__${pa.$index}_${scope.$index}_${lst.$index`}" -->
                                                            <el-input :spellcheck="isspellcheck" :disabled="!editpermission" v-model="scope.row.specificationhome"
                                                                      @change="headerchanged(pa.row, scope.row, scope.$index, 'specification',true)">
                                                            </el-input>

                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            <el-table :data="scope.row.lstspecification"
                                                      :key="scope.row.id" v-if="scope.row.lstspecification.length != 0">
                                                <el-table-column prop="key" label="Column" width="60">
                                                    <template slot-scope="lst">
                                                       <div v-if="lst.$index == 0">
                                                                {{homelable}}
                                                             <hr class="spaceBetween">
                                                            </div>
                                                             <div v-if="scope.row.lstspecification.length != 0">
                                                             <div v-if="lst.$index != scope.row.lstspecification.length - 1 ">
                                                                {{ lst.row.key }} / {{ scope.row.lstspecification[lst.$index + 1].key }}
                                                             </div>

                                                             <div v-if="lst.$index == scope.row.lstspecification.length - 1">
                                                             {{endlable}}
                                                             </div>
                                                             </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="text" label="Text">
                                                    <template slot-scope="lst">
                                                        <div v-if="lst.$index == 0 " style="margin-bottom: 3px;">
                                                            <!-- :id="`amos_amos_table_specification_add_text_2_${pa.$index}_${scope.$index}_${lst.$index`}" -->
                                                            <el-input :spellcheck="isspellcheck" :disabled="!editpermission" v-model="scope.row.specificationhome"
                                                                      @change="headerchanged(pa.row, scope.row, scope.$index, 'specification',true)">
                                                            </el-input>
                                                            </div>
                                                            <div v-if="scope.row.lstspecification.length != 0">
                                                                <!-- :id="`amos_amos_table_specification_add_text_3_${pa.$index}_${scope.$index}_${lst.$index`}" -->
                                                            <el-input :spellcheck="isspellcheck" :disabled="!editpermission" v-model="lst.row.text" 
                                                                      @change="headerchanged(pa.row, scope.row, scope.$index, 'specification',true)">
                                                            </el-input>
                                                            </div>
                                                        <!-- <el-input v-model="lst.row.text"
                                                                  @change="headerchanged(pa.row, scope.row, scope.$index, 'specification')">
                                                        </el-input> -->
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <el-button slot="reference" :id="`amos_amos_table_ch_specification_edit_${pa.$index}_${scope.$index}`" @click="clickevant('amos_amos_table_ch_specification_edit_',`amos_amos_table_ch_specification_edit_${pa.$index}_${scope.$index}`)" icon="el-icon-edit" class="floatright padding7"></el-button>
                                        </el-popover>
                                    </div>
                                    <div v-if="scope.row.predicate=='Pre'">
                                        <div :id="`amos_amos_table_specification_${pa.$index}_${scope.$index}`">
                                            <span v-if="scope.row.edit" style="text-decoration:line-through;">{{scope.row.specification}}</span>
                                            <span v-else>{{scope.row.specification}}</span>
                                        </div>
                                    </div>
                                    <div v-if="scope.row.predicate=='Edit'">
                                        <el-popover placement="bottom-start"
                                                    width="300"
                                                    trigger="focus"
                                                    :open-delay="500">
                                            <el-input :disabled="!editpermission" type="textarea"
                                                      :spellcheck="isspellcheck"                                                      
                                                      ref="inputTest"
                                                      :autosize="{ minRows: 2, maxRows: 4}"
                                                      v-model="scope.row.specification"
                                                      @input="BQAstSpeEndInputChanges(scope.row)">
                                            </el-input>
                                            <el-input :spellcheck="isspellcheck" :id="`amos_amos_table_specification_edit_${pa.$index}_${scope.$index}`" :disabled="!editpermission" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" :ref="scope.row.id" slot="reference" v-model="scope.row.specification" @input="BQAstSpeEndInputChanges(scope.row)"> </el-input>
                                        </el-popover>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column :resizable="true" label="Asterisk" width="385">
                                  <!-- <template slot="header">
                                             <el-button type="info" :disabled="!editpermission" plain><b>Asterisk</b></el-button>
                                </template> -->
                                <template slot-scope="scope">
                                    <!--<div v-if="scope.row.predicate=='SN'">
                                        <el-input v-model ="scope.row.asterisk"
                                                  placeholder="Separator"
                                                  @change="headerchanged(pa.row, scope.row, scope.$index+1, 'astseparator')">
                                        </el-input>
                                    </div>-->
                                    <div v-if="scope.row.predicate=='CH'">
                                        <div>
                                            <el-select v-model="scope.row.lstasterisk"
                                                       filterable
                                                       multiple
                                                       class="selecttag selectinputleft5"
                                                       placeholder="Asterisks"
                                                       :id="`${rowhightlightids[1]}_${pa.$index}_${scope.$index}`"
                                                       @remove-tag="removetag($event,pa.row, scope.row, scope.$index, 'asterisk')"
                                                       @change="()=>{headerchanged(pa.row, scope.row, scope.$index, 'asterisk',true),clickevant(`${rowhightlightids[1]}_`,`${rowhightlightids[1]}_${pa.$index}_${scope.$index}`)}"
                                                       style="width:348px;">
                                                <el-option v-for="item in scope.row.astcolumns"  :disabled="!editpermission"
                                                           :key="item.key"
                                                           :label="item.key"
                                                           :value="item">
                                                </el-option>
                                            </el-select>

                                            <el-popover placement="bottom-start"
                                                        width="300"
                                                        trigger="click">
                                                <span style="font-size:16px;">Add Additional Text</span>
                                                <el-popover placement="top-end"
                                                            width="300"
                                                            trigger="hover">
                                                    <i class="el-icon-info floatright" slot="reference"></i>
                                                    <span>
                                                        <i class="el-icon-info"></i>
                                                        Form to allow users to insert puctuation,
                                                        text, etc. after each section of transposed
                                                        data (relating to this column heading CH)
                                                    </span>
                                                </el-popover>
                                                 <el-table :data="asteriskhomearray"
                                                          v-if="scope.row.lstasterisk.length == 0" >
                                                    <el-table-column prop="key" label="Column" width="60" >
                                                        <template slot-scope="lst">
                                                                {{homelable}}
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="text" label="Text" >
                                                        <template slot-scope="lst">
                                                            <!-- :id="`amos_amos_table_asterisk_add_text_home_${pa.$index}_${scope.$index}_${lst.$index}`" -->
                                                            <el-input :spellcheck="isspellcheck" :disabled="!editpermission" v-model="scope.row.asteriskhome"
                                                                      @change="headerchanged(pa.row, scope.row, scope.$index, 'asterisk',true)">
                                                            </el-input>

                                                        </template>
                                                    </el-table-column>
                                                </el-table>

                                                <el-table :data="scope.row.lstasterisk"
                                                          :key="scope.row.id"  v-if="scope.row.lstasterisk.length != 0">
                                                    <el-table-column prop="key" label="Column" width="60" >
                                                        <template slot-scope="lst">
                                                            <div v-if="lst.$index == 0">
                                                                {{homelable}}
                                                             <hr class="spaceBetween">
                                                            </div>
                                                             <div v-if="scope.row.lstasterisk.length != 0">
                                                             <div v-if="lst.$index != scope.row.lstasterisk.length - 1 ">
                                                                {{ lst.row.key }} / {{ scope.row.lstasterisk[lst.$index + 1].key }}
                                                             </div>

                                                             <div v-if="lst.$index == scope.row.lstasterisk.length - 1">
                                                             {{endlable}}
                                                             </div>
                                                             </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="text" label="Text" >
                                                        <template slot-scope="lst">
                                                            <div v-if="lst.$index == 0 " style="margin-bottom: 3px;">                                                                
                                                                <!-- :id="`amos_amos_table_asterisk_add_text_2_${pa.$index}_${scope.$index}_{lst.$index}`" -->
                                                            <el-input :spellcheck="isspellcheck" :disabled="!editpermission" v-model="scope.row.asteriskhome"
                                                                      @change="headerchanged(pa.row, scope.row, scope.$index, 'asterisk',true)">
                                                            </el-input>
                                                            </div>
                                                            <div v-if="scope.row.lstasterisk.length != 0">
                                                                <!-- :id="`amos_amos_table_asterisk_add_text_3_${pa.$index}_${scope.$index}_{lst.$index}`" -->
                                                            <el-input :spellcheck="isspellcheck" :disabled="!editpermission" v-model="lst.row.text"
                                                                      @change="headerchanged(pa.row, scope.row, scope.$index, 'asterisk',true)">
                                                            </el-input>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                                <el-button  :id="`amos_amos_table_ch_asterisk_edit_${pa.$index}_${scope.$index}`" @click="clickevant('amos_amos_table_ch_asterisk_edit_',`amos_amos_table_ch_asterisk_edit_${pa.$index}_${scope.$index}`)" slot="reference" icon="el-icon-edit" class="floatright padding7"></el-button>
                                            </el-popover>
                                        </div>
                                    </div>
                                    <div v-if="scope.row.predicate=='Pre'">
                                        <div :id="`amos_amos_table_asterisk_${pa.$index}_${scope.$index}`">
                                            <span  v-if="scope.row.edit" style="text-decoration:line-through;">{{scope.row.asterisk}}</span>
                                            <span v-else>{{scope.row.asterisk}}</span>
                                        </div>
                                    </div>
                                    <div v-if="scope.row.predicate=='Edit'">
                                        <el-popover placement="bottom-start"
                                                    width="300"
                                                    trigger="focus"
                                                    :open-delay="500">
                                            <el-input type="textarea"
                                                      :spellcheck="isspellcheck"
                                                      :disabled="!editpermission"                                                      
                                                      :autosize="{ minRows: 2, maxRows: 4}"
                                                      v-model="scope.row.asterisk"
                                                      @input="BQAstSpeEndInputChanges(scope.row)">
                                            </el-input>
                                            <el-input :spellcheck="isspellcheck" :id="`amos_amos_table_asterisk_edit_${pa.$index}_${scope.$index}`" :disabled="!editpermission" type="textarea" autosize :ref="scope.row.id" slot="reference" v-model="scope.row.asterisk" @input="BQAstSpeEndInputChanges(scope.row)"> </el-input>
                                        </el-popover>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column :resizable="true" label="End Notes" width="173">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.predicate=='CH'">
                                        <div>
                                            <el-select v-model="scope.row.lstendnotes" filterable multiple class="selecttag selectinputleft5"
                                                placeholder="End Notes" :id="`amos_amos_table_end_notes_${pa.$index}_${scope.$index}`"
                                                @remove-tag="removetag($event, pa.row, scope.row, scope.$index, 'endnotes')"
                                                @change="()=>{headerchanged(pa.row, scope.row, scope.$index, 'endnotes', true),clickevant('amos_amos_table_end_notes_',`amos_amos_table_end_notes_${pa.$index}_${scope.$index}`)}" style="width:136px;">
                                                <el-option v-for="item in scope.row.endnotescolumns" :disabled="!editpermission" :key="item.key"
                                                    :label="item.key" :value="item">
                                                </el-option>
                                            </el-select>

                                            <el-popover placement="bottom-start" width="300" trigger="click">
                                                <span style="font-size:16px;">Add Additional Text</span>
                                                <el-popover placement="top-end" width="300" trigger="hover">
                                                    <i class="el-icon-info floatright" slot="reference"></i>
                                                    <span>
                                                        <i class="el-icon-info"></i>
                                                        Form to allow users to insert puctuation,
                                                        text, etc. after each section of transposed
                                                        data (relating to this column heading CH)
                                                    </span>
                                                </el-popover>

                                                <el-table :data="endnoteshomearray" v-if="scope.row.lstendnotes.length == 0">
                                                    <el-table-column prop="key" label="Column" width="60">
                                                        <template slot-scope="lst">
                                                            {{ homelable }}
                                                        </template>
                                                        
                                                    </el-table-column>
                                                    <el-table-column prop="text" label="Text">
                                                        <template slot-scope="lst">
                                                            <!-- :id="`amos_amos_table_asterisk_add_text_home_${pa.$index}_${scope.$index}_${lst.$index}`" -->
                                                            <el-input :spellcheck="isspellcheck" :disabled="!editpermission"
                                                                v-model="scope.row.endnoteshome"
                                                                @change="headerchanged(pa.row, scope.row, scope.$index, 'endnotes', true)">
                                                            </el-input>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>

                                                <el-table :data="scope.row.lstendnotes" :key="scope.row.id" v-if="scope.row.lstendnotes.length != 0">
                                                    <el-table-column prop="key" label="Column" width="60">
                                                        <template slot-scope="lst">
                                                            <div v-if="lst.$index == 0">
                                                                {{ homelable }}
                                                                <hr class="spaceBetween">
                                                            </div>
                                                            <div v-if="scope.row.lstendnotes.length != 0">
                                                                <div v-if="lst.$index != scope.row.lstendnotes.length - 1">
                                                                    {{ lst.row.key }} / {{ scope.row.lstendnotes[lst.$index + 1].key }}
                                                                </div>

                                                                <div v-if="lst.$index == scope.row.lstendnotes.length - 1">
                                                                    {{ endlable }}
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column prop="text" label="Text">
                                                        <template slot-scope="lst">
                                                            <div v-if="lst.$index == 0" style="margin-bottom: 3px;">
                                                                <el-input :spellcheck="isspellcheck" :disabled="!editpermission"
                                                                    v-model="scope.row.endnoteshome"
                                                                    @change="headerchanged(pa.row, scope.row, scope.$index, 'endnotes', true)">
                                                                </el-input>
                                                            </div>
                                                            <div v-if="scope.row.lstendnotes.length != 0">
                                                                <el-input :spellcheck="isspellcheck" :disabled="!editpermission" v-model="lst.row.text"
                                                                    @change="headerchanged(pa.row, scope.row, scope.$index, 'endnotes', true)">
                                                                </el-input>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                                <el-button slot="reference" @click="clickevant('amos_amos_table_ch_end_note_edit_',`amos_amos_table_ch_end_note_edit_${pa.$index}_${scope.$index}`)" :id="`amos_amos_table_ch_end_note_edit_${pa.$index}_${scope.$index}`" icon="el-icon-edit" class="floatright padding7"></el-button>
                                            </el-popover>
                                        </div>
                                    </div>
                                    <div v-if="scope.row.predicate=='Pre'">
                                        <span v-if="scope.row.edit" style="text-decoration:line-through;">{{scope.row.endnotes}}</span>
                                        <span v-else>{{scope.row.endnotes}}</span>
                                    </div>
                                    <div v-if="scope.row.predicate=='Edit'">
                                        <el-popover placement="bottom-start"
                                                    width="300"
                                                    trigger="focus"
                                                    :open-delay="500">
                                            <el-input :disabled="!editpermission" type="textarea"
                                                      :spellcheck="isspellcheck"                                                      
                                                      :autosize="{ minRows: 2, maxRows: 4}"
                                                      v-model="scope.row.endnotes"
                                                      @input="BQAstSpeEndInputChanges(scope.row)">
                                            </el-input>
                                            <el-input :spellcheck="isspellcheck" :id="`amos_amos_table_end_notes_edit_${pa.$index}_${scope.$index}`" :disabled="!editpermission" type="textarea" autosize :ref="scope.row.id" slot="reference" v-model="scope.row.endnotes" @input="BQAstSpeEndInputChanges(scope.row)"> </el-input>
                                        </el-popover>
                                    </div>
                                </template>
                            </el-table-column>

                            <!--<el-table-column :resizable="true" label="OFL" width="40" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.predicate=='Pre'">
                                        <el-checkbox v-model="scope.row.omitfromlibrary"> </el-checkbox>
                                    </div>
                                </template>
                            </el-table-column>-->

                            <el-table-column label="Edit" :resizable="true" width="40" align="center">
                                <template slot="header">
                                    <el-popover placement="top" trigger="hover" content="Edit Row">
                                        <i slot="reference" class="el-icon-edit" style="color:#606266;margin-left:2px;"></i>
                                    </el-popover>
                                </template>

                                <template slot-scope="scope">
                                    <div v-if="scope.row.predicate=='CH'">
                                        <el-button class="amosmergeiconrmvpad" :id="`amos_amos_table_ch_merge_duplicate_rows_${pa.$index}_${scope.$index}`" @click="()=>{mergeduplicaterows(pa.row, scope.row),clickevant('amos_amos_table_ch_merge_duplicate_rows_',`amos_amos_table_ch_merge_duplicate_rows_${pa.$index}_${scope.$index}`)}" :disabled="scope.row.disabledmergerowbtn">
                                            <div class="amosmergeicondiv">
                                                <i class="amosmergeicon1">&#10247;</i>
                                                <i class="amosmergeicon2">}</i>
                                                <i class="amosmergeicon3">&#10242;</i>
                                            </div>
                                        </el-button>
                                    </div>

                                    <div v-if="scope.row.predicate=='Pre'">
                                        <el-checkbox :disabled="!editpermission" :id="`amos_amos_table_ch_row_edit_check_${pa.$index}_${scope.$index}`" v-model="scope.row.edit" @change="()=>{editchanged(pa.row, scope.row, scope.$index, 'edit'),clickevant('amos_amos_table_ch_row_edit_check_',`amos_amos_table_ch_row_edit_check_${pa.$index}_${scope.$index}`)}"> </el-checkbox>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="RFI" :resizable="true" width="40" align="center">
                                <template slot-scope="scope">
                                    <el-button title="RFI"
                                        :id="`amos_amos_table_pre_openRFI_${pa.$index}_${scope.$index}`"   
                                         @click="()=>{clickevant('amos_amos_table_pre_openRFI_',`amos_amos_table_pre_openRFI_${pa.$index}_${scope.$index}`,()=>{openRFI(scope.row)},'Go to RFI')}"
                                         style="padding: 8px 2px; margin-bottom: 16px;"
                                         type="primary"
                                         :disabled="!editpermission"
                                         v-if="scope.row.predicate=='Pre'">
                                         RFI
                                        </el-button>
                                        <!-- :disabled="!editpermission" -->
                                </template>
                            </el-table-column>
                           <!-- </div> -->
                            <!--<el-table-column label="Action" width="100" align="right">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.predicate=='Pre'">
                                        <el-button-group>
                                            <el-button> </el-button>
                                        </el-button-group>
                                    </div>
                                </template>
                            </el-table-column>-->
                            </template>
                        </el-table>
                    </template>
                </el-table-column>

                <el-table-column label="File Name"
                                 prop="filename"
                                 :resizable="true">
                    <template slot-scope="scope">
                         <!--scope.row.createdbyloginname-->
                         <div>
                            <span>
                                Added by : {{ scope.row.editedbyloginname }}  File Name : {{ scope.row.filename }}
                            </span>                            
                         </div>
                    </template>
                </el-table-column>

                <el-table-column  width="300">
                    <template slot-scope="scope">
                        <div class="right">
                            <el-dropdown :hide-on-click="false" @command="selectaction($event,scope.row)">
                                <span class="el-dropdown-link">
                                    <i class="el-icon-more"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <!-- <el-dropdown-item command="a">Action 1</el-dropdown-item>
                                    <el-dropdown-item command="a">Action 2</el-dropdown-item> -->
                                    <!-- <el-dropdown-item command="other">
                                                <el-dropdown :hide-on-click="false">
                                                    <span class="el-dropdown-link">
                                                     Column's
                                                    </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item >   <el-button type="text"   :disabled="!editpermission" @click="clickallcolumn(scope.row)" >All</el-button> </el-dropdown-item>
                                                        <div v-for="ssa in scope.row.maxcolumn" :key="ssa">
                                                            <div v-if="scope.row.checkarray != undefined">
                                                           <el-dropdown-item command="a">Col {{ ssa }}    
                                                            <el-checkbox 
                                                                  @change="detailform.projectamoses = JSON.parse(JSON.stringify(detailform.projectamoses))"
                                                                    v-model="scope.row.checkarray['col'+ssa]">
                                                                </el-checkbox>
                                                            </el-dropdown-item>
                                                        </div>
                                                        </div>
                                                    </el-dropdown-menu>
                                                    </el-dropdown>
                                    </el-dropdown-item> -->
                                    <el-dropdown-item command="excel" >Show Excel Only</el-dropdown-item>
                                    <el-dropdown-item command="amos" >Show AMOS Only</el-dropdown-item>
                                    <el-dropdown-item command="both" divided>Show All</el-dropdown-item>
                                </el-dropdown-menu>
                                </el-dropdown>
                        </div>
                        <div class="right">
                          <el-pagination layout="prev, pager, next"
                            small
                            :id="`amos_amos_table_pagination_${scope.$index}`"
                            style="float: right;"
                           @current-change="($event)=>{clickevant(`amos_amos_table_pagination_`,`amos_amos_table_pagination_${scope.$index}`),pageCurrentChange($event,scope.row)} "
                            :current-page.sync="scope.row.page"
                           :page-size="scope.row.pagesize"
                           :total=scope.row.total>
                        </el-pagination>
                    </div>
                    </template>
                </el-table-column>
            </el-table>
        <!-- </el-row> -->
        <el-dialog v-if="projectbqcode" title="Project BQCode"
                   :visible.sync="projectbqcode"
                   width="90%">
            <projectbqcode :projectsectionid.sync="detailform.section.id"
                           :bqcodelibraryid.sync="bqcodelibraryid"
                           :bqcodelibrary="false"
                           @updateprojectbqcode="updateprojectbqcode"
                           @closedialogue="closedialogue">
            </projectbqcode>
        </el-dialog>

        <el-dialog v-if="amtemplateedit"
                   title="Automated Measurement Template Library"
                   :visible.sync="amtemplateedit"
                   width="90%">
            <amtemplateedit :amtemplateid.sync="amtemplateid"
                            :fromamos="true"
                            @closeamtemplatedialogue="closeamtemplatedialogue">
            </amtemplateedit>
        </el-dialog>

        <el-dialog title="Import Schedules Changes"
                   :visible.sync="confirmimportdialog"
                   width="30%">
            <div class="bold" v-if="importcheck.missing.length > 0">These Schedules are Missing</div>
            <div class="blue" v-for="item in importcheck.missing" :key="item">{{item}}</div>
            <div class="bold" v-if="importcheck.todelete.length > 0">These Schedules will be deleted</div>
            <div class="red" v-for="item in importcheck.todelete" :key="item">{{item}}</div>
            <div class="bold" v-if="importcheck.toupdate.length > 0">These Schedules will be updated</div>
            <div v-for="item in importcheck.toupdate" :key="item">{{item}}</div>
            <div class="bold" v-if="importcheck.toadd.length > 0">These Schedules will be Added</div>
            <div class="green" v-for="item in importcheck.toadd" :key="item">{{item}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button v-if="importcheck.missing.length == 0" @click="confirmimportclose(true)">Confirm</el-button>
                <el-button @click="confirmimportclose(false)">Cancel</el-button>
            </span>
        </el-dialog>

        <customdialog v-if="validationerror" @warnerror="warnerror" @hyperlink="moveusinghyperlink" :projectsection="detailform.section" @movespecificpage="movecheckingpage" :err="errorobject"></customdialog>
       <custominputdialog v-if="boxopen" :boxdata="boxdata" @handleSelectBQCodeall="handleSelectBQCodeall" @save="save" @refresh="refresh" ></custominputdialog>
        <el-dialog title="Add New or Replace Schedule"
                   :visible="scheduledialog"
                   :show-close="false"
                   width="70%">
            <div>
                <el-radio v-model="replaceoraddradio" :label="true" @change="radiochanged">Replace</el-radio>
                <el-radio v-model="replaceoraddradio" :label="false" @change="radiochanged">Add New</el-radio>
            </div>
            <br />
            <div v-if="replaceoraddradio">
                <el-select v-model="detailform.projectamoses.length == 1 ? detailform.replaceid = detailform.projectamoses[0].id : detailform.replaceid"
                           placeholder="Select File"
                           class="fullwidth">
                    <el-option v-for="item in detailform.projectamoses"
                               :key="item.id"
                               :label="item.filename"
                               :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="deleteAttachment()">Cancel</el-button>
                <el-button :disabled="isOkButtonDisabled" type="primary" @click="scheduledialog = false">Ok</el-button>
            </span>
        </el-dialog>
        <!-- :close-on-click-modal="false" -->
         <el-dialog
      v-show="set_C_H"
      title=""
      :visible.sync="set_C_H"
      width="90%"
      :lock-scroll="false"
      :show-close="false"
      :close-on-click-modal="false"
      class="titlefontchange changedialgheader-multiple-section chngdialgpadding"
    >
      <template>
          <el-row class="headerstyle">
            <el-col :span="12" > Select Column Header(CH) </el-col>
            <el-col :span="12">
                <div class="right">
                    <!-- <el-button  :disabled="!editpermission" slot="" class="marginleft4px" @click="edit_CH_click(true)"  icon="el-icon-refresh" type="primary" ></el-button> -->
                        <el-button type="primary" @click="setamosheader(true)">Ok</el-button>
                        <el-button  @click="setamosheader(false)">Close</el-button>
                </div>                                          
            </el-col>   
        </el-row>
          <el-row v-if="detailform.importedamosfile" >
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-row>
                        (REGEX for Project Part)
                    </el-row>
                    <hr class="spaceBetween" />
                    <el-row>
                        <el-col :span="2">
                            Include 
                        </el-col>
                        <el-col :span="19">
                <el-input
                    type="textarea"
                    autosize
                    :readonly="true"
                    placeholder="Include"
                    v-model="detailform.RDCHview.IncludePart.regularexpression">
                    </el-input>
                </el-col>
                </el-row>
                    <div class="spaceBetween" />
                    <el-row>
                        <el-col :span="2">
                            Exclude 
                        </el-col>
                        <el-col :span="19">
                    <el-input
                    type="textarea"
                    autosize
                    :readonly="true"
                    placeholder="Exclude"
                    v-model="detailform.RDCHview.ExcludePart.regularexpression">
                    </el-input>
                  </el-col>
                </el-row>
                </el-col>
                 
                <el-col :span="12">
                    <el-row>
                        (REGEX for All Projects)
                    </el-row>
                    <hr class="spaceBetween" />
                    <el-row>
                        <el-col :span="2">
                            Include
                        </el-col>
                        <el-col :span="19">
                <el-input
                    type="textarea"
                    autosize
                    placeholder="Include"
                    :readonly="true"
                    v-model="detailform.RDCHview.IncludeDefault.regularexpression">
                    </el-input>
                </el-col>
                </el-row>
                    <div class="spaceBetween" />
                    <el-row>
                        <el-col :span="2">
                            Exclude
                        </el-col>
                        <el-col :span="19">
                    <el-input
                    type="textarea"
                    autosize
                    placeholder="Exclude"
                    :readonly="true"
                    v-model="detailform.RDCHview.ExcludeDefault.regularexpression">
                    </el-input>
                  </el-col>
                </el-row>
                </el-col>

                <!-- <el-col :span="12">
                    <el-input
                    type="textarea"
                    autosize
                    placeholder="Please input"
                    v-model="detailform.RDCHview.IncludeDefault.regularexpression">
                    </el-input>
                    <div class="spaceBetween" />
                    <el-input
                    type="textarea"
                    autosize
                    placeholder="Please input"
                    v-model="detailform.RDCHview.ExcludeDefault.regularexpression">
                    </el-input>
                </el-col> -->
            </el-row>
            <hr class="spaceBetween" />
            <!-- <table class="el-table el-table--border">
              <tr >
                  <th v-for="cl in maxcolumn" :key="cl">{{ "col"+cl }}</th>
              </tr>
              <tr v-for="(clcol,ind) in detailform.importedamosfile.details.length" :key="ind">
                <td v-for="cl in maxcolumn" :key="cl" v-if="detailform.importedamosfile.details[clcol] " >
                    <div v-if=" detailform.importedamosfile.details[clcol]['col'+cl]">
                        {{detailform.importedamosfile.details[clcol]['col'+cl]}}
                    </div>   
                </td>
              </tr>
           </table> -->
            <el-table
              :data="detailform.importedamosfile.details"
              class="tableBox fullwidth"
              size="mini"
              :border="true"
              height="450px"
              @cell-click="tablecellclick"
              :cell-class-name="tablecellstyle"
            >
            <!-- <el-table-column   label="Edit" width="50"  >
                <template slot-scope="scope">
                    <el-checkbox @change="changepredicate(scope.row)" v-model="scope.row.isch"></el-checkbox>
                </template>
            </el-table-column> -->
            <el-table-column prop="rowno"  label="Row No" width="50"  ></el-table-column>
            <!-- <div v-for="cl in 20" :key="cl">
                <div v-if="cl > 0 && maxcolumn >= cl" >
                    <el-table-column  :prop="'col'+cl"  :label="'col'+cl"   :width="columnwidth(detailform.importedamosfile,cl)"></el-table-column>
                </div>
            </div> -->
            <el-table-column v-if="maxcolumn >= 1" prop="col1"  label="col1"   :width="columnwidth(detailform.importedamosfile,1)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 2" prop="col2"  label="col2"   :width="columnwidth(detailform.importedamosfile,2)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 3" prop="col3"  label="col3"   :width="columnwidth(detailform.importedamosfile,3)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 4" prop="col4"  label="col4"   :width="columnwidth(detailform.importedamosfile,4)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 5" prop="col5"  label="col5"   :width="columnwidth(detailform.importedamosfile,5)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 6" prop="col6"  label="col6"   :width="columnwidth(detailform.importedamosfile,6)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 7" prop="col7"  label="col7"   :width="columnwidth(detailform.importedamosfile,7)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 8" prop="col8"  label="col8"   :width="columnwidth(detailform.importedamosfile,8)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 9" prop="col9"  label="col9"   :width="columnwidth(detailform.importedamosfile,9)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 10" prop="col10" label="col10"  :width="columnwidth(detailform.importedamosfile,10)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 11" prop="col11" label="col11"  :width="columnwidth(detailform.importedamosfile,11)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 12" prop="col12" label="col12"  :width="columnwidth(detailform.importedamosfile,12)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 13" prop="col13" label="col13"  :width="columnwidth(detailform.importedamosfile,13)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 14" prop="col14" label="col14"  :width="columnwidth(detailform.importedamosfile,14)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 15" prop="col15" label="col15"  :width="columnwidth(detailform.importedamosfile,15)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 16" prop="col16" label="col16"  :width="columnwidth(detailform.importedamosfile,16)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 17" prop="col17" label="col17"  :width="columnwidth(detailform.importedamosfile,17)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 18" prop="col18" label="col18"  :width="columnwidth(detailform.importedamosfile,18)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 19" prop="col19" label="col19"  :width="columnwidth(detailform.importedamosfile,19)"></el-table-column>
            <el-table-column v-if="maxcolumn >= 20" prop="col20" label="col20"  :width="columnwidth(detailform.importedamosfile,20)"></el-table-column>
        </el-table>
          </el-row>  
      </template>
      </el-dialog>
     <!-- <el-dialog
      v-show="edit_CH"
      title=""
      :visible.sync="edit_CH"
      width="35%"
      :lock-scroll="false"
      :show-close="false"
      class="titlefontchange changedialgheader-multiple-section chngdialgpadding"
    >
      <template>
        <el-row class="headerstyle">
            <el-col :span="17" >Manage column Headers</el-col>
            <el-col :span="7">
                <div class="right">
                        <el-button type="success" @click="edit_CH_click(false)">Save</el-button>
                        <el-button  @click="edit_CH = false">Close</el-button>
                </div>                                          
            </el-col>
            </el-row>
            <el-table
              :data="CH_Array"
              class="tableBox fullwidth"
              size="mini"
              :border="true"
              height="350px"
            >
            <el-table-column  prop="headers" label="Headers"  width="100"></el-table-column>
            <el-table-column  prop="headers" label="Delete"  width="50">
                <template slot-scope="scope">
                    <el-button type="danger" calss="icon-btn-width" icon="el-icon-delete" @click="delete_CH(scope.$index)"></el-button>
                </template>
            </el-table-column>
        </el-table>
      </template>
      </el-dialog> -->
      <!-- <el-dialog v-if="intimechecklist" 
                   :visible.sync="intimechecklist"
                   :modal-append-to-body="false"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :append-to-body="true"
                   width="400px"
                   ref="whiteBackground"
                   id="whiteBackground"
                    draggable="true"
                    class=" draggable titlefontchange changedialgheader-multiple-section chngdialgpadding">
            <intimechecklist :ismsg="true" @messageclose="messageclose" :pageaction="pageaction"></intimechecklist>
        </el-dialog> -->

        <div v-if="visiblemedia">
            <displaydropboxmedia :isvisible="visiblemedia" :filehistory="filehistory" @dialogclosed="handledialogclose">
            </displaydropboxmedia>
        </div>
       
        <CustomPageDraggable style="width: 640px;height:550px;" :isvisible="intimechecklistrfi">
                            <i @click="intimechecklistrfi = false" class="el-icon-close right"></i>
            <rfientryform :ispopup="true" @closerfi="intimechecklistrfi = false" :projecttemplatedetailid="null" :projectamosdetailid="projectamosdetailid"></rfientryform>
        </CustomPageDraggable> 
     <CustomPageDraggable :isvisible="intimechecklist" class="chklisectionpopupstyle">
            <intimechecklist :ismsg="true" @messageclose="intimechecklist = false" :pageaction="pageaction"></intimechecklist>
        </CustomPageDraggable>
        <CustomPageDraggable :isvisible="amosallocationshow" class="amosallocationpopupstyle">
            <amosallocation @setrowstyle="setrowstyle" :projectamos="detailform" :amosrow="amosselectedrow" @closeallocation="amosallocationclose" ></amosallocation>
        </CustomPageDraggable>
    </div>
</template>

<script>

    import projectselectpart from './projectselectpart.vue'
    import projectbqcode from './projectbqcode.vue'
    import amtemplateedit from './amtemplateedit.vue'
    import customdialogbox from './customdialogbox.vue'
    import custominputdialog from './custominputdialog.vue'
    import * as XLSX from "xlsx/xlsx.mjs"
    import intimechecklist from './checklistsection.vue'
    import CustomPageDraggable from './CustomPageDraggable.vue'
    import rfientryform from './rfientryform.vue'
    import amosallocation from './amosallocation.vue'
    import displaydropboxmedia from './displaydropboxmedia.vue';

    export default {
        data() {
            return {
                rowstyle:{},
                amosselectedrow:null,
                amosallocationshow:false,
                expandedrows: [],
				mycustomheight: 0,
                projectamoscount: 0,
                displayimportedtab: [],
                viewmode: false,
                viewlist: [{active: false, name: 'Vertical View' }, {active: true, name: 'Horizontal View' }],
                showamosid: null,
                projectamosdetailid:0,
                intimechecklistrfi:false,
                columnwrappers:[],
                set_C_H:false,
                pageaction:{},
                intimechecklist:false,
                maxcolumn:0,
                isimport:false,
                amossaving:false,
                searchastspeother:"",
                searchbqcode:"",
                pagesize:40,
                asteriskhomearray:[{key:0,text:''}],
                specificationhomearray:[{key:0,text:''}],
                endnoteshomearray: [{ key: 0, text: '' }],
                homelable:"Home",
                endlable:"End",
                detail: false,
                user: this.$store.state.user,
                attachments: [],
                loading: false,
                sectionid: 0,
                detailform: {projectamoses:[],  replace: false },
                //predicates: ["", "SN", "CH", "Pre", "Edit"],
                predicates: [
                    { 'label': '', 'value': '' },
                    { 'label': 'SH', 'value': 'SN' },
                    { 'label': 'CH', 'value': 'CH' },
                    { 'label': 'SAM', 'value': 'Pre' },
                    { 'label': 'Edit', 'value': 'Edit' }],
                columns: [],
                height: window.innerHeight,                
                rowheight: '',
                projectbqcode: false,
                confirmimportdialog: false,
                importcheck: {
                    missing: [],
                    toadd: [],
                    todelete: [],
                    toupdate: []
                },
                validationerror: false,
                boxopen:true,
                message: [],
                boxdata:{
                    changed:false,
                    sectionid:0,
                    data:{},
                    message: [],
                    type: "",
                    visible: false,
                    sourcereq: "",
                    sourcewarn: "",
                    visibleCHSettingBox: false
                },
                errorobject: {
                    message: [],
                    type: "",
                    visible: false,
                    sourcereq: "",
                    sourcewarn: ""
                },
                scheduledialog: false,
                arrch: [],
                warncheck: false,
                amtemplateedit: false,
                replaceoraddradio: false,
                directionids: [
                    "amos_amos_table_amt",
                    "amos_amos_table_asterisk",
                    "amos_amos_table_asterisk_add_text",
                    "amos_amos_table_asterisk_edit",
                    "amos_amos_table_bq_code",
                    "amos_amos_table_end_notes",
                    "amos_amos_table_end_notes_edit",
                    "amos_amos_table_nrmsort",
                    "amos_amos_table_predicate",
                    "amos_amos_table_qty",
                    "amos_amos_table_specification",
                    "amos_amos_table_specification_add_text",
                    "amos_amos_table_specification_edit",
                ],
                rowhightlightids: [
                    "amos_amos_table_ch_specification",
                    "amos_amos_table_ch_asterisk",
                    "amos_amos_table_specification",
                    "amos_amos_table_asterisk",
                    "amos_amos_table_specification_edit",
                    "amos_amos_table_for_full_row_hightlight"
                ],
                saveInProgress: false,
                showExcel: false,
                showAMOS: false,
                showBoth: true,
                savebuttonkey: 0,
                saveButtonTimeout: null,
                filehistory: {},
                visiblemedia: false,
                amosFilters: {
                    searchBQCode: "",
                    searchAstSpeOther: ""
                },
                amosFiltersEnabled: true,
                amosDetailsFilteredIds: [],
                columnwidthsarr: {},
                openheader: false,
                sectionheaders: []
            };
        },
        inject: {
            clickevant:{from:"clickevant",default:Function}
        },
        methods: {
            setscroll(row){
                var dd = document.querySelector("#detailtable .el-table__body-wrapper");
                if(dd != null){
                    if(row.pagescroll == undefined){
                        row.pagescroll = [];
                    } 
                    row.pagescroll[row.currentpage] = dd.scrollTop;
                }
            },
            setpagesize(row,array){
                var pages = Math.ceil(row.total / row.pagesize);
                   for (let index = 1; index <= pages; index++) {
                       var endrows = (row.pagesize * index)-1;
                       const element = array[endrows];
                        if(element && element.predicate == "CH"){
                            if(row.pagesize > 1){
                                row.pagesize -= 1;
                                this.setpagesize(row,array);
                            }
                        }
                   }
            },
            toggleShow(option) {
                /*
                //show only for excel
                if (option === 'excel' || option === 'both') 
                {
                    this.showExcel = true
                } 
                else 
                {
                    this.showExcel = false;
                }

                //show only for amos
                if (option === 'amos' || option === 'both') 
                {
                    this.showAMOS = true;
                }
                else 
                {
                    this.showAMOS = false;
                }

                //show amos and excel both
                if (option === 'both')
                {
                    this.showBoth = true;
                }
                else 
                {
                    this.showBoth = false;
                }
                */

                this.showExcel = option === 'excel' || option === 'both';
                this.showAMOS = option === 'amos' || option === 'both';
                this.showBoth = option === 'both';
            },
            setscrollevent(id){
                this.$nextTick(() => {
                    var elOffset = document.getElementById(id);
                    if(elOffset != null){
                        elOffset.scrollIntoView();
                    }
                });
            },
            setchbottom(name,pa,newindex,pad){
                    this.$nextTick(() => {
                    var id = null;
                    var rowindex = pa.details.findIndex(p => p.id == pad.id);
                    if(rowindex+1 < pa.details.length){
                        for (var index = rowindex+1; index < pa.details.length; index++) {
                            const element = pa.details[index];
                            if(element.predicate == "CH"){
                                id = element.id;
                                break;
                            }
                        } 
                       var page =  this.findPageNumber(index,pa.pagesize,pa.details.length);
                       if(pa.page != page){
                        this.$set(pa,"page",page);
                        this.setscroll(pa);
                        this.$set(pa,"currentpage",page);
                        this.detailform.projectamoses = JSON.parse(JSON.stringify(this.detailform.projectamoses));
                       }
                        if(id != null){
                            this.setscrollevent(name+id);
                        }
                    }
                });
            },
            setchtop(name,pa,newindex,pad){
                this.$nextTick(() => {
                    var id = null;
                    var rowindex = pa.details.findIndex(p => p.id == pad.id);
                    if(rowindex-1 >= 0){
                        for (var index = rowindex-1; index >= 0; index--) {
                            const element = pa.details[index];
                            if(element.predicate == "CH"){
                                id = element.id;
                                break;
                            }
                        }  
                        var page =  this.findPageNumber(index,pa.pagesize,pa.details.length);
                       if(pa.page != page){
                        this.$set(pa,"page",page)
                        this.setscroll(pa);
                        this.$set(pa,"currentpage",page);
                        this.detailform.projectamoses = JSON.parse(JSON.stringify(this.detailform.projectamoses));
                       }
                        if(id != null){
                            this.setscrollevent(name+id);
                        }
                    }
                  
                });
            },
            getamoscellstyle(event){
                // if(event.column.label == "BQ_Code" || 
                //    event.column.label == "Qty" || 
                //    event.column.label == "Specification" || 
                //    event.column.label == "Asterisk" || 
                //    event.column.label == "End Notes"){
                //     return {'background-color': '#edfaff'}
                //    }

                if (event.row.rowstyle) {
                   if(event.column.label == "BQ_Code" && event.row.rowstyle.bqcode == true){
                    return {'background-color': '#edfaff'}
                   }
                   if(event.column.label == "Qty" && event.row.rowstyle.qty == true){
                    return {'background-color': '#edfaff'}
                   }
                   if(event.column.label == "Specification" && event.row.rowstyle.specification == true){
                    return {'background-color': '#edfaff'}
                   }
                   if(event.column.label == "Asterisk" && event.row.rowstyle.asterisk == true){
                    return {'background-color': '#edfaff'}
                   }
                   if(event.column.label == "End Notes" && event.row.rowstyle.endnotes == true){
                    return {'background-color': '#edfaff'}
                   }
                }

            },
            getamosrowstyle(event){
                if(this.rowstyle.id != null && event.row.id == this.rowstyle.id)
                {
                    return { 'height': this.rowheight,  'background-color': '#edfaff'}
                }else{
                    return { 'height': this.rowheight};
                }
            },
            setrowstyle(data){
                this.rowstyle = data;
            },
            setamosallocation(){
                if(this.detailform.projectamoses.length > 0){
                this.showloading();
                this.$http.post('amos/setamosallocation',this.detailform)
                    .then(response => {
                      var json = response.data;
                        // json.projectamoses = json.projectamoses;
                        // this.projectamoscount = json.projectamosesview.length;
                        // json.projectamosesview = null;                        
                        //change lstasterisk and lstspecification
                        for (var pa of json.projectamoses) {
                            var dd = this.columnwrappers.find(x => x.id == pa.id);
                            if(dd != undefined){
                                pa.columnwrapper = dd.array;
                            }
                            // var asteriskchange = false;
                            // var specifichange = false;
                            // var endnoteschange = false;
                            // var qtychange = false;
                            var chstyle = {};
                            for (var pad of pa.details) {
                                pad.amtemplateid ??= null;
                                pad.asterisk ??= null;
                                pad.specification ??= null;
                                pad.endnotes ??= null;
                                pad.lstasterisk ??= [];
                                pad.lstspecification ??= [];
                                pad.lstendnotes ??= [];
                                pad.asteriskhome ??= "";
                                pad.specificationhome ??= "";
                                pad.endnoteshome ??= "";
                                pad.nrmsortingref ??= null;
                                
                                if (pad.predicate == "CH") {
                                    if(pad.rowstyle.qty == true){
                                        this.headerchanged(pa, pad, 0, 'qty',false)
                                    }

                                    chstyle = pad.rowstyle;

                                    if (!pad.asterisk && !pad.specification) {
                                        pad.disabledmergerowbtn = true;
                                    }

                                    if (pad.asterisk) {
                                        pad.lstasterisk = [];
                                        var arra = pad.asterisk.split("{");
                                        for (var a = 1; a < arra.length; a++) {
                                            var ast = arra[a].split("}");
                                            if(ast[0] == 0){

                                                if(ast[1] == 'undefined'){
                                                    pad.asteriskhome = "";
                                                }else{
                                                    pad.asteriskhome = ast[1];
                                                }
                                            }else{
                                                if(pad.asteriskhome == undefined){
                                                    pad.asteriskhome = "";
                                                }
                                                pad.lstasterisk.push({ key: ast[0], value: ("0" + ast[0]).substr(("0" + ast[0]).length - 2), text: ast[1] });
                                            }

                                        }
                                        this.headerchanged(pa, pad, 0, 'asterisk',false)
                                    }
                                    else {
                                        pad.lstasterisk = [];
                                         pad.asteriskhome = "";
                                    }
                                    if (pad.specification) {
                                        pad.lstspecification = [];
                                        var arrb = pad.specification.split("{");
                                        for (var b = 1; b < arrb.length; b++) {
                                            var spe = arrb[b].split("}");
                                            if(spe[0] == 0){
                                                if(spe[1] == 'undefined'){
                                                    pad.specificationhome = "";
                                                }else{
                                                    pad.specificationhome = spe[1];
                                                }
                                            }else{
                                                if(pad.specificationhome == undefined){
                                                    pad.specificationhome = "";
                                                }
                                            pad.lstspecification.push({ key: spe[0], value: ("0" + spe[0]).substr(("0" + spe[0]).length - 2), text: spe[1] });

                                            }
                                        }
                                        this.headerchanged(pa, pad, 0, 'specification',false)
                                    }
                                    else {
                                        pad.lstspecification = [];
                                        pad.specificationhome = "";
                                    }
                                    if (pad.endnotes) {
                                        pad.lstendnotes = [];
                                        var arra = pad.endnotes.split("{");
                                        for (var a = 1; a < arra.length; a++) {
                                            var ast = arra[a].split("}");
                                            if (ast[0] == 0) {

                                                if (ast[1] == 'undefined') {
                                                    pad.endnoteshome = "";
                                                } else {
                                                    pad.endnoteshome = ast[1];
                                                }
                                            } else {
                                                if (pad.endnoteshome == undefined) {
                                                    pad.endnoteshome = "";
                                                }
                                                pad.lstendnotes.push({ key: ast[0], value: ("0" + ast[0]).substr(("0" + ast[0]).length - 2), text: ast[1] });
                                            }

                                        }
                                        this.headerchanged(pa, pad, 0, 'endnotes', false)
                                    }
                                    else {
                                        pad.lstendnotes = [];
                                        pad.endnoteshome = "";
                                    }

                                    if (pad.amtemplateid != null) {
                                        let amtobjdata = pad.amtemplate;
                                        pad.amtoptions = [{ id: amtobjdata.id, amtemplatename: amtobjdata.amtemplatename }];

                                        pad.nrmsortoptions = [];
                                        pad.bqcodeoptions = [];
                                        // this.focusSearchAmt(pad);
                                        // this.amtchanged(pad, pad.amtemplateid, false, null, null);
                                    }
                                    else {
                                        pad.amtoptions = [];
                                        pad.nrmsortoptions = [];
                                        pad.bqcodeoptions = [];
                                    }

                                    if (pad.nrmsortingref == undefined) {
                                        pad.nrmsortingref = null;
                                    }
                                } 
                                else {
                                    for (let i = 1; i <= 20; i++) {
                                        if (!pad.hasOwnProperty('col' + i) || pad['col' + i] == undefined) {
                                            pad['col' + i] = "";
                                    }
                                    if(pad.predicate == "Pre" && chstyle.qty != undefined){  
                                        pad.rowstyle.qty = chstyle.qty;
                                        pad.rowstyle.specification = chstyle.specification;   
                                        pad.rowstyle.asterisk = chstyle.asterisk;   
                                        pad.rowstyle.endnotes = chstyle.endnotes;
                                    }
                                }
    
                                    // pad.asterisk ??= null;
                                    // pad.specification ??= null;
                                    // pad.endnotes ??= null;
                                    // pad.lstasterisk ??= [];
                                    // pad.lstspecification ??= [];
                                    // pad.asteriskhome ??= "";
                                    // pad.specificationhome ??= "";

                                    pad.amtoptions = [];
                                    pad.nrmsortoptions = [];
                                    pad.bqcodeoptions = [];
                                }
                                //pad.lstasterisk = pad.asterisk ? pad.asterisk.split('+') : [];
                                //pad.lstspecification = pad.specification ? pad.specification.split('+') : [];

                            }
                        }
                        if (json.section && !json.section.status) {
                            json.section.status = '';
                        }

                        json.projectamoses.forEach((pa) => {
                            var found = false;
                            var column = 0;
                            pa.details.forEach((pad) => {
                                for (var i = 1; i <= 20; i++) {
                                    var value = pad["col" + i];
                                    if (value) {
                                        found = true;
                                        if (found && i > column) {
                                            column = i;
                                        }
                                    }
                                }
                            });

                            pa.maxcolumn = column;
                            var columns = [];
                            for (var i = 1; i <= column; i++) {
                                columns.push({ key: i, value: ("0" + i).substr(("0" + i).length - 2), text:"" });
                            }

                            pa.details.forEach((pad) => {
                                pad.qtycolumns = JSON.parse(JSON.stringify(columns));
                                pad.astcolumns = JSON.parse(JSON.stringify(columns));
                                pad.specolumns = JSON.parse(JSON.stringify(columns));
                                pad.endnotescolumns = JSON.parse(JSON.stringify(columns));
                            });
                        });
                        this.displayimportedtab = [];
                        this.viewmode = false;
                        this.showamosid = null;
                        json.projectamoses.forEach(x => {
                            let newfile = {
                                id : x.id,
                                filename: x.filename,
                                editedbyloginname: x.editedbyloginname
                            };
                            this.displayimportedtab.push(newfile);
                        });
                        this.detailform = json;
                       if(this.searchbqcode != "" || this.searchastspeother != ""){
                        this.detailform.projectamoses.forEach((x)=>{
                                var dt = this.getdetails(x.details,x);
                                if(dt.length != 0){
                                        this.$refs.pa.toggleRowExpansion(x);
                                }
                        })
                       }
                       this.$message({
                            showClose: true,
                            message: "Allocation completed successfully!", type: "success"
                        });
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    })
                }else{
                    this.$message({
                            showClose: true,
                            message: "Nothing to Allocate"
                        });
                }
            },
            amosallocationopen(row){
                this.amosallocationshow = true;
                this.amosselectedrow = row;
            },
            amosallocationclose(){
                this.amosselectedrow = null;
                this.amosallocationshow = false;
                this.rowstyle = {};
            },
            getheight() {
                if (this.viewmode) {
                    this.mycustomheight = (document.documentElement.clientHeight - 240);
                } else {
                    let sh = 27 * this.projectamoscount;
                    let minusheight = 175 + sh;
                    let newheight = (document.documentElement.clientHeight - minusheight);
                    this.mycustomheight = newheight / this.expandedrows.length;
                }
            },
            checkrdmatch(data,dr){
                return this.$training.RG_Direction_Match(data,dr.inc_pattern,dr.exc_pattern,dr.format_pattern)
            },
            setoptionindexs(uniqueid,paindex,deindex){
                const option = {};
                this.$set(option,"uniqueid",uniqueid)
                this.$set(option,"fileindx",paindex)
                this.$set(option,"dataindex",deindex)
                return option;
            },
            findPageNumber(index, pagesize, total) {
                var currentPage = Math.ceil((index + 1) / pagesize);
                var totalPages = Math.ceil(total / pagesize);
                
                if (currentPage > totalPages) {
                    currentPage = totalPages;
                }
                
                return currentPage;
            },
            expandchange: function (row, expandedRows) {
                if (row.total <= 18) {
                    this.mycustomheight = null;
                } else {
                    if (this.viewmode) {
                        this.mycustomheight = (document.documentElement.clientHeight - 240);
                    } else {
                        let sh = 27 * this.projectamoscount;
                        let minusheight = 175 + sh;
                        let newheight = (document.documentElement.clientHeight - minusheight);
                        this.mycustomheight = newheight / expandedRows.length;
                    }
                }

                var expanded = expandedRows.map((v) => { return v.id });
                this.expandedrows = expanded;
            },
            changedisplaymode: function (event) {
                this.showamosid = null;
                this.expandedrows = [];
            },
            expandfilebytab: function (row) {
                this.expandedrows = [];
                let t = row.id;
                this.showamosid = t;
                this.mycustomheight = (document.documentElement.clientHeight - 240);
                this.expandedrows.push(t);
            },            
              getformat(dr){
                    const data = {};
                    var inc_pattern = null;
                    var exc_pattern = null;
                    var format_pattern = null;
                    if(dr.includerule != null && dr.includerule != ""){
                        inc_pattern = new RegExp(dr.includerule,"i");
                    }
                    if(dr.excluderule != null && dr.excluderule != ""){
                        exc_pattern = new RegExp(dr.excluderule,"i");
                    }
                    if(dr.formatrule != null && dr.formatrule != ""){
                        format_pattern = new RegExp(dr.formatrule,"i");
                    }
                    data.format_pattern = format_pattern;
                    data.inc_pattern = inc_pattern;
                    data.exc_pattern = exc_pattern;
                    return data;
              },

              getCellValueAndRegexRules(data, cellvalue) {
                if (data.includerule != null && data.includerule != "") {
                    data.inc_pattern = new RegExp(data.includerule, "i");
                }

                if (data.excluderule != null && data.excluderule != "") {
                    data.exc_pattern = new RegExp(data.excluderule, "i");
                }

                if (data.formatrule != null && data.formatrule != "") {
                    data.format_pattern = new RegExp(data.formatrule, "i");
                }

                data.enteredvalue = cellvalue;

                return data;
            },

              getPropertyByString(obj, propString) {
                const propArray = propString.split('.');
                let currentObj = obj;

                for (let i = 0; i < propArray.length; i++) {
                    const prop = propArray[i];
                    currentObj = currentObj[prop];

                    if (currentObj === undefined) {
                    return undefined;
                    }
                }

                return currentObj;
            },
            validatedirectionsingle(data){
                let $table = this.$refs.pa;
                var isexpand = this.expandedrows.find(x => x == this.detailform.projectamoses[data.fileindx].id);
                if($table != undefined && isexpand == undefined){
                    this.expandedrows = [];
                    $table.toggleRowExpansion(this.detailform.projectamoses[data.fileindx]);
                    if(this.viewmode) {
                        this.showamosid = this.detailform.projectamoses[data.fileindx].id;
                    }
                }
                var page =  this.findPageNumber(data.dataindex,this.detailform.projectamoses[data.fileindx].pagesize,this.detailform.projectamoses[data.fileindx].details.length);
                if(this.detailform.projectamoses[data.fileindx].page != page){
                    this.detailform.projectamoses[data.fileindx].page = page; 
                    this.setscroll(this.detailform.projectamoses[data.fileindx]);
                    this.detailform.projectamoses[data.fileindx].currentpage = page;
                    this.detailform.projectamoses = JSON.parse(JSON.stringify(this.detailform.projectamoses));
                }
                var newcheckval = this.getPropertyByString(this.detailform.projectamoses[data.fileindx].details[data.dataindex],data.checkstr);
                if(newcheckval != undefined){
                    data.value = newcheckval;
                }
            },
            getdirectionoption(FormData){
                const option = JSON.parse(JSON.stringify({
                          visible: true,
                          sectionname:"",
                          isedit:false,
                          id:FormData.id,
                          boxtype:"Small 2 Part Pop up",
                          content:FormData.message,
                          mediacontent:FormData.mediacontent,
                          link:FormData.link,
                          offercloseoption:FormData.offercloseoption,
                          heading:FormData.directionname,
                          directiondegree:FormData.directiondegree,
                          direction:FormData,
                          usedata:true,

                          enteredvalue: FormData.enteredvalue,
                          celltype: FormData.celltype,
                          objectpath: FormData.objectpath,
                          checkvalue: FormData.checkvalue,
                          operation: FormData.operation,
                         
                      }));
                        return option;
            },
            setpageandexpand(index, dataindex) {
                let $table = this.$refs.pa;
                var isfind = this.expandedrows.find(x => x == this.detailform.projectamoses[index].id);
                if (isfind == undefined) {
                    if (this.viewmode === true) {
                        this.showamosid = this.detailform.projectamoses[index].id;
                        this.$forceUpdate();
                    }
                        this.$nextTick(() => {
                            this.expandedrows = [];
                            $table.toggleRowExpansion(this.detailform.projectamoses[index]);
                        });
                    }
                    this.detailform.projectamoses[index].page = this.findPageNumber(dataindex, this.detailform.projectamoses[index].pagesize, this.detailform.projectamoses[index].details.length);
                    this.setscroll(this.detailform.projectamoses[index]);
                    this.detailform.projectamoses[index].currentpage = this.detailform.projectamoses[index].page;
            },

            multicelllocationrulesmatch(celldirections, direction, errors) {
                let count = 0;
                let chrowindex = null; let prerowindex = null; let editrowindex = null;
                for (let i = 0; i < celldirections.length; i++) {
                    let celldir = celldirections[i];
                    let celldirectiondata = null;
                    let cellvalue = null;
                    
                    if (celldir.cellname == "amt" && celldir.amtdr) {
                        chrowindex = celldir.amtdr.chrowindex;
                        celldirectiondata = celldir.amtdr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.amtemplate.amtemplatename;
                    }
                    else if (celldir.cellname == "nrm" && celldir.nrmdr) {
                        chrowindex = celldir.nrmdr.chrowindex;
                        celldirectiondata = celldir.nrmdr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.nrmsortingref;
                    }
                    else if (celldir.cellname == "bqcode" && celldir.bqcodedr) {
                        prerowindex = celldir.bqcodedr.prerowindex;
                        celldirectiondata = celldir.bqcodedr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.bqcodelibrary.bqcode;
                    }
                    else if (celldir.cellname == "qty" && celldir.qtydr) {
                        prerowindex = celldir.qtydr.prerowindex;
                        celldirectiondata = celldir.qtydr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.qty;
                    }
                    else if (celldir.cellname == "asterisk" && celldir.asteriskdr) {
                        prerowindex = celldir.asteriskdr.prerowindex;
                        celldirectiondata = celldir.asteriskdr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.asterisk;
                    }
                    else if (celldir.cellname == "specification" && celldir.specificationdr) {
                        prerowindex = celldir.specificationdr.prerowindex;
                        celldirectiondata = celldir.specificationdr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.specification;
                    }
                    else if (celldir.cellname == "endnotes" && celldir.endnotesdr) {
                        chrowindex = celldir.endnotesdr.chrowindex;
                        celldirectiondata = celldir.endnotesdr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.endnotes
                    }
                    else if (celldir.cellname == "asteriskedit" && celldir.asteriskeditdr) {
                        editrowindex = celldir.asteriskeditdr.editrowindex;
                        celldirectiondata = celldir.asteriskeditdr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.asterisk;
                    }
                    else if (celldir.cellname == "specificationedit" && celldir.specificationeditdr) {
                        editrowindex = celldir.specificationeditdr.editrowindex;
                        celldirectiondata = celldir.specificationeditdr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.specification;
                    }
                    else if (celldir.cellname == "endnotesedit" && celldir.endnoteseditdr) {
                        editrowindex = celldir.endnoteseditdr.editrowindex;
                        celldirectiondata = celldir.endnoteseditdr;
                        let datarow = celldir.predicaterow;
                        cellvalue = datarow.endnotes;
                    }

                    if (celldirectiondata) {
                        const  dr = this.getformat(celldirectiondata);
                        let ddata = this.getCellValueAndRegexRules(celldirectiondata, cellvalue);
                        
                        // may be remove this (cellvalue) condition because Amal want to check Extra spaces and Empty string(NULL).
                        if (cellvalue && this.$directionutility.directionvaluematch(ddata) == false) {
                            count++;
                        }
                    }
                }

                if (direction.directioncelldetails.length == count) {
                    errors.forEach(x => {
                        if (x.direction.chrowindex && x.direction.chrowindex == chrowindex) {
                            x.ismatchedrulecell = true;
                        }
                        if (x.direction.prerowindex && x.direction.prerowindex == prerowindex) {
                            x.ismatchedrulecell = true;
                        }
                        if (x.direction.editrowindex && x.direction.editrowindex == editrowindex) {
                            x.ismatchedrulecell = true;
                        }
                    });
                    return true;
                }
                else {
                    return false;
                }
            },

            getcellmatcheddirection(direction, directionidvalue) {
                let matcheddirection = direction.directioncelldetails.find(x => x.pagecell.cellname == directionidvalue);
                let dir = JSON.parse(JSON.stringify(direction));
                if (matcheddirection) {
                    dir.includerule = matcheddirection.includerule;
                    dir.excluderule = matcheddirection.excluderule;
                    dir.formatrule = matcheddirection.formatrule;
                    dir.pagecell = matcheddirection.pagecell;
                    dir.pagecellid = matcheddirection.pagecellid;

                    dir.checkvalue = matcheddirection.checkvalue;
                    dir.operation = matcheddirection.operation;
                    dir.celltype = matcheddirection.pagecell.celltype;
                    dir.objectpath = matcheddirection.pagecell.objectpath;
                    
                    return dir;
                }
            },

            validatedirection(directions,callback){
               var errors = [];
               var isexpand = false;
               var uniqueid = 1;
               var pageindex = 0;
               for (let i = 0; i < directions.length; i++) {
                const direction = directions[i];

                var amtdr = this.getcellmatcheddirection(direction, this.directionids[0]);
                var asteriskdr = this.getcellmatcheddirection(direction, this.directionids[1]);
                var asteriskeditdr = this.getcellmatcheddirection(direction, this.directionids[3]);
                var bqcodedr = this.getcellmatcheddirection(direction, this.directionids[4]);
                var endnotesdr = this.getcellmatcheddirection(direction, this.directionids[5]);
                var endnoteseditdr = this.getcellmatcheddirection(direction, this.directionids[6]);
                var nrmdr = this.getcellmatcheddirection(direction, this.directionids[7]);
                var qtydr = this.getcellmatcheddirection(direction, this.directionids[9]);
                var specificationdr = this.getcellmatcheddirection(direction, this.directionids[10]);
                var specificationeditdr = this.getcellmatcheddirection(direction, this.directionids[12]);
                    let chrowindex = 0; let prerowindex = 0; let editrowindex = 0;
                    for (let paindex = 0; paindex < this.detailform.projectamoses.length; paindex++) {
                        const pa = this.detailform.projectamoses[paindex];
                        pageindex = 0;
                        let setupelementid = 0;
                        if(this.viewmode == false) {
                            setupelementid = paindex;
                        }
                        if(pa.details && pa.details.length > 0){
                            let chrowforcellcheck = null;
                            let prerowforcellcheck = null;
                            let editrowforcellcheck = null;

                            for (let deindex = 0; deindex < pa.details.length; deindex++) {
                                const de = pa.details[deindex];
                                if(pageindex > pa.pagesize){
                                    pageindex = 1;
                                 }

                                if(de.predicate == "CH") {
                                    if (amtdr || nrmdr || endnotesdr) {
                                        chrowforcellcheck = de;
                                        chrowindex++;
                                    }

                                    if (amtdr) {
                                        amtdr.chrowindex = chrowindex;
                                    }
                                    if (nrmdr) {
                                        nrmdr.chrowindex = chrowindex;
                                    }
                                    if (endnotesdr) {
                                        endnotesdr.chrowindex = chrowindex;
                                    }
                                } else if (de.predicate == "Pre") {
                                    if (bqcodedr || asteriskdr || specificationdr || qtydr) {
                                        prerowforcellcheck = de;
                                        prerowindex++;
                                    }

                                    if (bqcodedr) {
                                        bqcodedr.prerowindex = prerowindex;
                                    }
                                    if (asteriskdr) {
                                        asteriskdr.prerowindex = prerowindex;
                                    }
                                    if (specificationdr) {
                                        specificationdr.prerowindex = prerowindex;
                                    }
                                    if (qtydr) {
                                        qtydr.prerowindex = prerowindex;
                                    }
                                } else if (de.predicate == "Edit"){
                                    if (asteriskeditdr || specificationeditdr || endnoteseditdr) {
                                        editrowforcellcheck = de;
                                        editrowindex++;
                                    }

                                    if (asteriskeditdr) {
                                        asteriskeditdr.editrowindex = editrowindex;
                                    }
                                    if (specificationeditdr) {
                                        specificationeditdr.editrowindex = editrowindex;
                                    }
                                    if (endnoteseditdr) {
                                        endnoteseditdr.editrowindex = editrowindex;
                                    }
                                 }

                                //  if (direction.directioncelldetails.length > 0 && de.predicate == "SN") {
                                //     let colsnameobjects = 
                                //         [{ name: "amtemplate.amtemplatename", amtdr: amtdr}, { name: "asterisk", asteriskdr: asteriskdr}, { name: "asterisk", asteriskeditdr: asteriskeditdr }, 
                                //          { name: "bqcodelibrary.bqcode", bqcodedr: bqcodedr }, { name: "endnotes", endnotesdr: endnotesdr }, { name: "endnotes", endnoteseditdr: endnoteseditdr}, 
                                //          { name:"nrmsortingref", nrmdr: nrmdr }, { name: "qty", qtydr: qtydr }, { name: "specification", specificationdr: specificationdr}, { name: "specification", specificationeditdr: specificationeditdr }
                                //         ];

                                //     for (let k = 0; k < colsnameobjects.length; k++) {
                                //         let obj = colsnameobjects[k]; let count = 0;
                                        
                                //         const dr =  this.getformat(obj);
                                //         if (this.checkrdmatch(de[obj.name], dr) == false) {
                                //             count++;
                                //         }
                                //     }
                                //  }
                                if(de.predicate == "Edit"){
                                    let cellobjarray = [
                                        { cellname: "asteriskedit", asteriskeditdr: asteriskeditdr, predicaterow: editrowforcellcheck },
                                        { cellname: "specificationedit", specificationeditdr: specificationeditdr, predicaterow: editrowforcellcheck },
                                        { cellname: "endnotesedit", endnoteseditdr: endnoteseditdr, predicaterow: editrowforcellcheck },
                                    ];                                    
                                    let iscellrulesmatched = false;
                                        if(specificationeditdr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(specificationeditdr);
                                            let ddata = this.getCellValueAndRegexRules(specificationeditdr, de.specification);

                                            if(de.specification != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(specificationeditdr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr);
                                                Object.assign(option, allindex);
                                                option.checkstr = "specification"
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${specificationeditdr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if(isexpand == false){
                                                    this.setpageandexpand(paindex,deindex);
                                                    isexpand = true;                                                  
                                                }
                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false;
                                                } else {
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                        if(asteriskeditdr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(asteriskeditdr);
                                            let ddata = this.getCellValueAndRegexRules(asteriskeditdr, de.asterisk);

                                            if(de.asterisk != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(asteriskeditdr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr);
                                                Object.assign(option, allindex);
                                                option.checkstr = "asterisk"
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${asteriskeditdr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if (isexpand == false) {
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex, deindex);
                                                }
                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false;
                                                } else {
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                        if(endnoteseditdr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(endnoteseditdr);
                                            let ddata = this.getCellValueAndRegexRules(endnoteseditdr, de.endnotes);

                                            if(de.endnotes != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(endnoteseditdr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr);
                                                Object.assign(option, allindex);
                                                option.checkstr = "endnotes"
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}                                                
                                                this.$set(option,"elementid",`${endnoteseditdr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if (isexpand == false) {
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex, deindex);
                                                }
                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false;
                                                } else {
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                 }
                                 if(de.predicate != "CH" && de.predicate != "SN" && de.predicate != "Edit"){
                                    let cellobjarray = [
                                        { cellname: "amt", amtdr: amtdr, predicaterow: chrowforcellcheck },
                                        { cellname: "nrm", nrmdr: nrmdr, predicaterow: chrowforcellcheck },
                                        { cellname: "endnotes", endnotesdr: endnotesdr, predicaterow: chrowforcellcheck },
                                        { cellname: "bqcode", bqcodedr: bqcodedr, predicaterow: prerowforcellcheck },
                                        { cellname: "qty", qtydr: qtydr, predicaterow: prerowforcellcheck },
                                        { cellname: "specification", specificationdr: specificationdr, predicaterow: prerowforcellcheck },
                                        { cellname: "asterisk", asteriskdr: asteriskdr, predicaterow: prerowforcellcheck },
                                    ];
                                    let iscellrulesmatched = false;
                                    // iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);

                                    if (de.edit == true && pa.details[deindex + 1]) {
                                        if (pa.details[deindex + 1].predicate == "Edit") {
                                            let editedrow = pa.details[deindex + 1];
                                            cellobjarray[5].predicaterow = editedrow;
                                            cellobjarray[6].predicaterow = editedrow;
                                        }
                                    }
                                    
                                    if(bqcodedr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(bqcodedr);
                                            let ddata = this.getCellValueAndRegexRules(bqcodedr, de.bqcodelibrary.bqcode);

                                            if(de.bqcodelibrary && de.bqcodelibrary.bqcode != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(bqcodedr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr);
                                                Object.assign(option, allindex);
                                                option.checkstr = "bqcodelibrary.bqcode"
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${bqcodedr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if(isexpand == false){
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex,deindex);
                                                    }

                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false
                                                }else{
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                        if(qtydr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(qtydr);
                                            let ddata = this.getCellValueAndRegexRules(qtydr, de.qty);

                                            if(de.qty != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(qtydr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr);
                                                Object.assign(option, allindex);
                                                option.checkstr = "qty"
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${qtydr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if(isexpand == false){
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex,deindex);
                                                    }

                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false
                                                }else{
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                        if(specificationdr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(specificationdr);
                                            
                                            let specificationvalue = de.specification;

                                            if (de.edit == true && pa.details[deindex + 1]) {
                                                if (pa.details[deindex + 1].predicate == "Edit") {
                                                    specificationvalue = pa.details[deindex + 1].specification;
                                                }
                                            }

                                            let ddata = this.getCellValueAndRegexRules(specificationdr, specificationvalue);


                                            if(specificationvalue != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(specificationdr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr);
                                                Object.assign(option, allindex);
                                                option.checkstr = "specification"
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${specificationdr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if(isexpand == false){
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex,deindex);
                                                    }

                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false;
                                                }else{
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                        if(asteriskdr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(asteriskdr);

                                            let asteriskvalue = de.asterisk;

                                            if (de.edit == true && pa.details[deindex + 1]) {
                                                if (pa.details[deindex + 1].predicate == "Edit") {
                                                    asteriskvalue = pa.details[deindex + 1].asterisk;
                                                }
                                            }

                                            let ddata = this.getCellValueAndRegexRules(asteriskdr, asteriskvalue);

                                            if(asteriskvalue != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(asteriskdr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr);
                                                Object.assign(option, allindex);
                                                option.checkstr = "asterisk"
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${asteriskdr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if(isexpand == false){
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex,deindex);
                                                   }

                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false
                                                }else{
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                        
                                    }
                                    if(de.predicate == "CH"){
                                        let cellobjarray = [
                                            { cellname: "amt", amtdr: amtdr, predicaterow: chrowforcellcheck },
                                            { cellname: "nrm", nrmdr: nrmdr, predicaterow: chrowforcellcheck },
                                            { cellname: "endnotes", endnotesdr: endnotesdr, predicaterow: chrowforcellcheck }
                                        ];
                                        let iscellrulesmatched = false;

                                        if(amtdr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(amtdr);
                                            let ddata = this.getCellValueAndRegexRules(amtdr, de.amtemplate.amtemplatename);

                                            if(de.amtemplate && de.amtemplate.amtemplatename != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(amtdr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr); 
                                                Object.assign(option, allindex); 
                                                option.checkstr = "amtemplate.amtemplatename"
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${amtdr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if(isexpand == false){
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex,deindex);
                                                    }

                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false
                                                }else{
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                        if(nrmdr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(nrmdr);
                                            let ddata = this.getCellValueAndRegexRules(nrmdr, de.nrmsortingref);

                                            if(de.nrmsortingref != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(nrmdr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr); 
                                                Object.assign(option, allindex); 
                                                option.checkstr = "nrmsortingref";
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${nrmdr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if(isexpand == false){
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex,deindex);
                                                    }

                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false
                                                }else{
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                        if(endnotesdr != undefined){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            const  dr =  this.getformat(endnotesdr);
                                            let ddata = this.getCellValueAndRegexRules(endnotesdr, de.endnotes);

                                            if(de.endnotes != null && this.$directionutility.directionvaluematch(ddata) == false){
                                                const option = this.getdirectionoption(endnotesdr);
                                                const allindex =  this.setoptionindexs(uniqueid,paindex,deindex)
                                                Object.assign(option, dr); 
                                                Object.assign(option, allindex); 
                                                option.checkstr = "endnotes";
                                                option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
                                                this.$set(option,"elementid",`${endnotesdr.pagecell.cellname}_${setupelementid}_${pageindex}`);
                                                if(isexpand == false){
                                                    isexpand = true;
                                                    this.setpageandexpand(paindex,deindex);
                                                   }

                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false
                                                }else{
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                            }
                                        }
                                    }
                                    pageindex ++;
                                    uniqueid ++;
                            }
                        }
                    }
                }
                    errors = errors.filter(x => x.ismatchedrulecell == true);
                    if(errors.length > 0){
                        this.searchastspeother = "";
                        this.searchbqcode = "";
                        this.$store.state.directionsdialogue = errors;
                        this.$store.state.currentdirectionindex = 0;
                        this.$store.state.currentdirection = errors[0];
                    }else{
                        callback();
                    }
            },
            getsection(){
                // Get NRM section for each page
                if(false){
                    // return this.detailform.section;
                }else{
                    return "";
                }
            },
            cellchanged(cellaction,callback,cancall){                
            if(cancall){
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)} );
            }else{

                if (this.saveButtonTimeout) {
                    clearTimeout(this.saveButtonTimeout);
                }
                this.saveButtonTimeout = setTimeout(() => {
                    this.savebuttonkey++;
                }, 2000);

                this.canmatchcellid(callback,cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)});
            }
        },
            openRFI(row){
                if(this.intimechecklistrfi == false){
                    this.projectamosdetailid = row.id;
                    this.intimechecklistrfi = true;
                }
            },
            myresizelclick(column, event,row,index){
                this.$nextTick(()=>{
                    var num = [];
                    if(column.label.match("col")){
                        this.clickevant('amos_amos_table_columns_1to20_',`amos_amos_table_columns_1to20_${index}_${column.label}`)
                        num = column.label.match(/\d+/g)
                    if(row.columnwrapper[num[0]+ "iswrapper"]){
                        if(row.columnwrapper[num[0]+ "iswrapper"] == true){
                            row.columnwrapper[num[0]+ "iswrapper"] = false;
                            column.iswrapper = false;    
                        }else{
                            row.columnwrapper[num[0]+ "iswrapper"] = true;
                            column.iswrapper = true;
                        }
                    }else{
                        if(column.iswrapper){
                            column.iswrapper = false;
                            row.columnwrapper[num[0]+ "iswrapper"] = false;
                        }else{
                            row.columnwrapper[num[0]+ "iswrapper"] = true;
                            column.iswrapper = true;
                        }
                    }
                    row.details = JSON.parse(JSON.stringify(row.details));
                    }
                })
               
            },
            myresizel(newWidth, oldWidth, column, event){
                if(newWidth <= 50){
                    column.iswrapper = true;
                }else{
                    column.iswrapper = false;
                }
            },
            tablecellstyle(obj){
                if(obj && obj.column && obj.column.label){
                    if(obj.row[obj.column.label+'style']){
                        return obj.row[obj.column.label+'style'] + " row" +obj.row.rowno +'_'+obj.column.label+"_style";
                    }
                    obj.row[obj.column.label+'style'] = 'white_cell_style'; 
                }
                return 'white_cell_style '+ "row" +obj.row.rowno +'_'+obj.column.label+"_style";
            },
            tablecellclick(row, column, cell, event){
                if(this.detailform.importedamosfile.includearray == undefined){
                    this.detailform.importedamosfile.includearray = [];
                }
                var tmparray = [],tmpstr = "";
                tmparray = this.detailform.importedamosfile.includearray;
                tmpstr = this.detailform.RDCHview.IncludePart.regularexpression;
                var ismatch = tmparray.findIndex((x) => x.includes("^"+ row[column.property]+"$"));
                if(ismatch < 0 && row[column.property+'style'] == 'white_cell_style'){
                    if(tmpstr == null){
                        tmpstr = "";
                    } 
                    if(!tmpstr.includes(row[column.property])){
                        if(tmpstr != ""){
                            tmparray.push("|^"+ row[column.property]+"$");  
                            tmpstr = tmpstr + "|^"+ row[column.property]+"$";  
                        }else{
                            tmparray.push("^"+ row[column.property]+"$");
                            tmpstr = "^"+ row[column.property]+"$"; 
                        }
                    }
                    
                }
                if(ismatch >= 0 && row[column.property+'style'] == 'black_cell_style'){
                    var newname = tmparray[ismatch];
                    tmpstr = tmpstr.replace(newname,"");
                    tmparray.splice(ismatch, 1);
                    // if(tmpstr.charAt(0) == "|"){
                    //     tmpstr = tmpstr.slice(1);
                    // }
                }
                this.detailform.importedamosfile.includearray = tmparray;
                this.detailform.RDCHview.IncludePart.regularexpression = tmpstr;
                    this.detailform.importedamosfile.details.forEach(e => {
                           for (let index = 1; index <= this.maxcolumn; index++) {
                            if(e['col'+index] && e['col'+index] == row[column.property]){
                                let para = document.querySelector('.row'+e.rowno+"_"+'col'+index+"_style");
                                if(para.classList.contains('white_cell_style')){
                                    e['col'+index+'style'] = "black_cell_style";
                                    para.classList.replace('white_cell_style','black_cell_style')
                                }else{
                                    e['col'+index+'style'] = "white_cell_style";
                                    para.classList.replace('black_cell_style','white_cell_style')
                                }
                            }
                           }
                    });
            },
            // clickallcolumn(data){
            //         for (let index = 1; index <= data.maxcolumn; index++) {
            //             data.checkarray['col'+index] = true;
            //         }
            //     this.detailform.projectamoses = JSON.parse(JSON.stringify(this.detailform.projectamoses));
            // },
            selectaction(command,row){
               if(command != 'other'){
                    this.toggleShow(command);

                   this.$set(row,'command',command);
                //    this.detailform.projectamoses = JSON.parse(JSON.stringify(this.detailform.projectamoses));
               }
            },
            getpageactionmessage(page,action,pro){
                this.intimechecklist = false;
               let obj = {
                   elt:{formtype:pro.library,nrmelementid:pro.section.section},
                   pa:{pagename:page,actionname:action},
                   reminder:{projectsectionid:pro.section.id,pagename:page,actionname:action,userid:this.user.id}
               }
               this.post("ElementList/sendmessage",obj).then((res)=>{
                   var newdata = res.data;  
                   if(newdata == true){
                       this.pageaction = {pagename:page,actionname:action};
                       this.intimechecklist = true;
                   }
               });
           },
            // delete_CH(index){
            //    this.CH_Array.splice(index, 1);
            // },
            // edit_CH_click(data){
            //     if(data == true){
            //         this.post("amos/GetColumnHeaders",this.detailform).then((data)=>{
            //             this.CH_Array = data.data;
            //             this.edit_CH = true;
            //         });
            //     }else{
            //         this.detailform.achs = this.CH_Array;
            //         this.post("amos/SaveColumnHeaders",this.detailform).then((data)=>{
            //             this.$message({
            //             message: data.data,
            //             type: 'success'
            //             });
            //             this.edit_CH = false;
            //         });
            //     }
            // },
            // setheader(){

            // },
            // changepredicate(data){  
            //        if(data.isch)
            //        {
            //         data.predicate = new String();
            //         data.predicate = "CH";
            //        } else{
            //         data.predicate = null;
            //        }
            // },
            searchbqcodechange(data){
                if(data == "" || data == null){
                    // this.searchbqcode = ""
                    if(this.$store.state.billbqcode != ""){
                        this.$store.state.billbqcode = "";
                    }
                }
            },
             searchastspeotherchange(data){
                if(data == "" || data == null){
                    // this.searchastspeother = "";
                    if(this.$store.state.billasterisk != ""){
                        this.$store.state.billasterisk = "";
                    }
                    if(this.$store.state.billspecification != ""){
                        this.$store.state.billspecification = "";
                    }
                }
            },
            // Row Designations
            FunRD:function(row){
                if(row.details != undefined){
                var rows = [];
                row.details.forEach(d => {
                    if(d.predicate == "CH"){
                        rows.push({name:d.rowno ,value:d.rowno});
                    }
                });
                 this.$store.state.RDobj.rows = rows;
                }
                this.$store.state.RDobj.Criteria = "CH";
                this.$router.push("/rowdesignationreport");
            },

            bqcodeselection(row) {
                if (row.details != undefined) {
                    let itm = { projectamosid: row.id, rownumbers: [] };
                    row.details.forEach(i => {
                        if (i.predicate == "Pre") {
                            var text ="";
                            for (let index = 1; index <= 20; index++) {
                                const element = i["col"+index];
                                if(element != undefined){
                                    text += element;
                                }
                            }
                            itm.rownumbers.push({ name: i.rowno, value: i.rowno,searchtext:text,amosrow:i,columnwidths:row.columnwidths });
                        }
                    });
                    this.$store.state.RDobj.bqcodeselect = itm;
                }
                // let d = { 
                //     projectid: this.detailform.id,
                //     partid: this.detailform.projectpart.id,
                //     sectionid:this.sectionid
                // };
                // this.$bus.$emit('addproject', d);
                this.$router.push("/bqcodeselection");
            },

            mergeduplicaterows: function(pa, pad) {
                this.$confirm(
                    "You have chosen to conflate (merge) duplicated rows and subtotal the quantities based on CH allocations. Are you sure that you wish to proceed? ",
                    "Warning",
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning"
                    }
                )
                .then(() => {
                    window.setTimeout(() => {
                        var index = pa.details.findIndex(p => p.id == pad.id);
                        var asvalue = pad.asterisk + pad.specification;
                        var res = asvalue.match(/\{+(.*?)\}+/g).join("").split(/[\{\}]+/g);
                        var asci = res.splice(1, res.length-2);
                        asci.push("" + pad.qty);


                        var newdetails = []; var countrows = 0; var isanyrowduplicate = false;
                        for (var i = index + 1; i < pa.details.length; i++) {
                            var idetails = pa.details[i];
                            if (idetails.predicate == "Pre") {
                                var nd = newdetails.find((n, nindex) => {
                                    if (n.edit == true && idetails.edit == false) {
                                        var nr = newdetails[nindex + 1];
                                        if (nr.asterisk == idetails.asterisk && nr.specification == idetails.specification && nr.endnotes == idetails.endnotes) {
                                            n.qty += idetails.qty;
                                            n['col' + pad.qty] = n.qty + "";
                                            isanyrowduplicate = true;
                                            return n;
                                        }
                                    }
                                    else if (n.predicate == "Pre" && idetails.edit == false) {
                                        if (n.bqcodelibrary.bqcode == idetails.bqcodelibrary.bqcode && n.asterisk == idetails.asterisk && n.specification == idetails.specification && n.endnotes == idetails.endnotes) {
                                            n.qty += idetails.qty;
                                            n['col' + pad.qty] = n.qty + "";
                                            isanyrowduplicate = true;
                                            return n;
                                        }
                                    }
                                    else if (n.predicate == "Pre" && idetails.edit == true) {
                                        var nr = n.edit == true ? newdetails[nindex + 1] : n;
                                        if (nr.asterisk == pa.details[i + 1].asterisk && nr.specification == pa.details[i + 1].specification && nr.endnotes == pa.details[i + 1].endnotes) {
                                            n.qty += idetails.qty;
                                            n['col' + pad.qty] = n.qty + "";
                                            isanyrowduplicate = true;
                                            if (n.edit == true) {
                                                return n;
                                            } else if (n.edit == false) {
                                                n.edit = true;
                                                newdetails.splice(nindex + 1, 0, pa.details[i + 1]);
                                                return n;
                                            }
                                        }
                                    }
                                    });

                                if (nd == undefined) {
                                    newdetails.push(idetails);
                                    if (pa.details[i+1] != undefined && pa.details[i+1].predicate == "Edit") {
                                        newdetails.push(pa.details[i+1]);
                                    }
                                }
                                countrows++;
                            }
                             else if (idetails.predicate == "Edit") {
                                countrows++;
                            }
                             else {
                                break;
                            }
                        }
                        if (isanyrowduplicate == true) {
                            newdetails.forEach(res => {
                                if (res.predicate == "Pre") {
                                    for (var i = 1; i <= 20; i++) {
                                        var asc = asci.find((a) => {
                                            a = parseInt(a);
                                            if(i == a){
                                                return true;
                                            }
                                        });
                                        if(asc == undefined){
                                            res['col' + i] = "";
                                        }
                                    }
                                }
                            });
                            var startindex = index + 1;
                            pa.details.splice(startindex, countrows, ...newdetails);
                            pa.details.forEach((e, rno) => {
                                if (e.rowno == undefined) {
                                    e.data.rowno = rno + 1;
                                } else {
                                    e.rowno = rno + 1;
                                }
                            });
                        }
                    }, 100);
                })
            },

            //open bqcode dialogbox
            getsectionbqcode:function( pa, pad, index){
                 index = pa.details.findIndex(p=>p.id == pad.id);
                 this.boxdata.changed = this.detailform.changed
                this.boxdata.sectionid = this.detailform.section.id
               this.boxdata.data.library = this.detailform.library
                  this.boxdata.data.pa = pa;
                  this.boxdata.data.pad = pad;
                  this.boxdata.data.index = index;
                  this.boxdata.visible = true
            },
            //Methods for first page
            pageCurrentChange: function (val, row) {
                this.removeBackgroundColor();
                row.canscroll = true;
                this.setscroll(row);
                // row.columnwidths = new Array();
                row.page = val;
                row.currentpage = val;
                row.details =  row.details.filter((p)=>{
                    return p;
                })
            },           

            projectsectionchange: function (Projectobj) {
                // this.clearFiltersValue();
                if(this.sectionid != 0){
                    this.amosFilters.searchBQCode = "";
                    this.amosFilters.searchAstSpeOther = "";
                    this.searchastspeother = "";
                    this.searchbqcode = "";
                }
                this.sectionid = Projectobj.sectionid;
                this.detailform = Projectobj.project;
                if(this.sectionid){
                    this.getpageactionmessage("AMOS","open",this.detailform);
                }
                this.refresh();
            },
            refresh: function () {
                if (this.detailform.id && this.sectionid) {
                    this.getProject();
                    this.boxopen = true;
                }
                else {
                    this.detail = false;
                }
            },
            //  scheduleheight: function(row, getprojectamoses){
            //     var height = 0;
            //     if (getprojectamoses.length == 1) {
            //         if (row.details.length < 10) {
            //             var rdl = (420 / row.details.length) - 8;
            //             this.rowheight = rdl + 'px';
            //             return  420;
            //         } else {
            //             this.rowheight = '';
            //             return  420;
            //         }
            //     } else if (row.details.length <= 5) {
            //         this.rowheight = '';
            //         return  (row.details.length * 41) + 50;
            //     } else if (row.details.length < 10) {
            //         return  400;
            //     }
            //     else {
            //         this.rowheight = '';
            //         return  290;
            //     }
            // },
            scheduleheight: function(row, getprojectamoses){
                var height = 0;
                //rows height set to 18
                 if (row.total <= 18) {
                        var rdl = (596 / row.total)-18;
                        this.rowheight = null;
                        return null;
                    } else {

                        // this.rowheight = null;
                        //  var rdl = (400 / row.details.length) - 8;
                        // this.rowheight = rdl + 'px';
                        return  576;
                    }
                    //row height set to 10
                    // // if (row.details.length <= 10) {
                    // //     var rdl = (400 / row.details.length) - 8;
                    // //     this.rowheight = null;
                    // //     return null;
                    // // } else {

                    //     // this.rowheight = null;
                    //     //  var rdl = (400 / row.details.length) - 8;
                    //     // this.rowheight = rdl + 'px';
                    //     return  430;}
            },
            //Detail form events start from here

            changedescription(event) {
                if (event != null && event != "") {
                    var exist = this.sectionheaders.find(x => x.id == event);
                    if(exist != null) {
                        this.detailform.section.description = exist.description;
                    }
                } else {
                    this.detailform.section.headerlibraryid = null;
                    this.detailform.section.description = null;
                }
            },

            getProject: function () {
                var data = {
                    id: this.detailform.id, section: { id: this.sectionid },
                    projectpart:this.detailform.projectpart
                };
                // this.loading = true;
                this.showloading();
                this.$http.post('amos/getamos', data)
                    .then(response => {
                        this.hideloading();
                        var json = response.data;
                        json.projectamoses = json.projectamosesview;
                        this.openheader = json.section.description != null && json.section.description.trim() != '' ? true : false;
                        this.sectionheaders = json.section.sectionheaders;
                        this.projectamoscount = json.projectamosesview.length;
                        json.projectamosesview = null;                      
                        
                        let currentch = null;
                        //change lstasterisk and lstspecification
                        for (var pa of json.projectamoses) {
                            var dd = this.columnwrappers.find(x => x.id == pa.id);
                            if(dd != undefined){
                                pa.columnwrapper = dd.array;
                            }
                            for (var pad of pa.details) {
                                pad.amtemplateid ??= null;
                                pad.asterisk ??= null;
                                pad.specification ??= null;
                                pad.endnotes ??= null;
                                pad.lstasterisk ??= [];
                                pad.lstspecification ??= [];
                                pad.lstendnotes ??= [];
                                pad.asteriskhome ??= "";
                                pad.specificationhome ??= "";
                                pad.endnoteshome ??= "";
                                pad.nrmsortingref ??= null;
                                
                                if (pad.predicate == "CH") {
                                    currentch = pad;
                                    if (!pad.asterisk && !pad.specification) {
                                        pad.disabledmergerowbtn = true;
                                    }

                                    if (pad.asterisk) {
                                        pad.lstasterisk = [];
                                        var arra = pad.asterisk.split("{");
                                        for (var a = 1; a < arra.length; a++) {
                                            var ast = arra[a].split("}");
                                            if(ast[0] == 0){

                                                if(ast[1] == 'undefined'){
                                                    pad.asteriskhome = "";
                                                }else{
                                                    pad.asteriskhome = ast[1];
                                                }
                                            }else{
                                                if(pad.asteriskhome == undefined){
                                                    pad.asteriskhome = "";
                                                }
                                                pad.lstasterisk.push({ key: ast[0], value: ("0" + ast[0]).substr(("0" + ast[0]).length - 2), text: ast[1] });
                                            }

                                        }
                                    }
                                    else {
                                        pad.lstasterisk = [];
                                         pad.asteriskhome = "";
                                    }
                                    if (pad.specification) {
                                        pad.lstspecification = [];
                                        var arrb = pad.specification.split("{");
                                        for (var b = 1; b < arrb.length; b++) {
                                            var spe = arrb[b].split("}");
                                            if(spe[0] == 0){
                                                if(spe[1] == 'undefined'){
                                                    pad.specificationhome = "";
                                                }else{
                                                    pad.specificationhome = spe[1];
                                                }
                                            }else{
                                                if(pad.specificationhome == undefined){
                                                    pad.specificationhome = "";
                                                }
                                            pad.lstspecification.push({ key: spe[0], value: ("0" + spe[0]).substr(("0" + spe[0]).length - 2), text: spe[1] });

                                            }
                                        }
                                    }
                                    else {
                                        pad.lstspecification = [];
                                        pad.specificationhome = "";
                                    }

                                    if (pad.endnotes) {
                                        pad.lstendnotes = [];
                                        var arra = pad.endnotes.split("{");
                                        for (var a = 1; a < arra.length; a++) {
                                            var ast = arra[a].split("}");
                                            if(ast[0] == 0){

                                                if(ast[1] == 'undefined'){
                                                    pad.endnoteshome = "";
                                                }else{
                                                    pad.endnoteshome = ast[1];
                                                }
                                            }else{
                                                if(pad.endnoteshome == undefined){
                                                    pad.endnoteshome = "";
                                                }
                                                pad.lstendnotes.push({ key: ast[0], value: ("0" + ast[0]).substr(("0" + ast[0]).length - 2), text: ast[1] });
                                            }

                                        }
                                    }
                                    else {
                                        pad.lstendnotes = [];
                                         pad.endnoteshome = "";
                                    }

                                    if (pad.amtemplateid != null) {
                                        let amtobjdata = pad.amtemplate;
                                        pad.amtoptions = [{ id: amtobjdata.id, amtemplatename: amtobjdata.amtemplatename }];

                                        pad.nrmsortoptions = [];
                                        pad.bqcodeoptions = [];
                                        // this.focusSearchAmt(pad);
                                        // this.amtchanged(pad, pad.amtemplateid, false, null, null);
                                    }
                                    else {
                                        pad.amtoptions = [];
                                        pad.nrmsortoptions = [];
                                        pad.bqcodeoptions = [];
                                    }

                                    if (pad.nrmsortingref == undefined) {
                                        pad.nrmsortingref = null;
                                    }
                                } 
                                else {
                                    if(pad.edit == true) {
                                        pad.asterisk = this.autogetdatausingch(currentch.lstasterisk, currentch.asteriskhome, pad);
                                        pad.specification = this.autogetdatausingch(currentch.lstspecification, currentch.specificationhome, pad);
                                        pad.endnotes = this.autogetdatausingch(currentch.lstendnotes, currentch.endnoteshome, pad);
                                    }
                                    for (let i = 1; i <= 20; i++) {
                                        if (!pad.hasOwnProperty('col' + i) || pad['col' + i] == undefined) {
                                            pad['col' + i] = "";
                                    }
                                    }
    
                                    // pad.asterisk ??= null;
                                    // pad.specification ??= null;
                                    // pad.endnotes ??= null;
                                    // pad.lstasterisk ??= [];
                                    // pad.lstspecification ??= [];
                                    // pad.asteriskhome ??= "";
                                    // pad.specificationhome ??= "";

                                    pad.amtoptions = [];
                                    pad.nrmsortoptions = [];
                                    pad.bqcodeoptions = [];
                                }
                                //pad.lstasterisk = pad.asterisk ? pad.asterisk.split('+') : [];
                                //pad.lstspecification = pad.specification ? pad.specification.split('+') : [];
                                
                            }
                        }
                        if (json.section && !json.section.status) {
                            json.section.status = '';
                        }   
                        
                        json.projectamoses.forEach((pa) => {
                            var found = false;
                            var column = 0;
                            var colswidtharr = {};
                            pa.columnwidths = {};

                            pa.details.forEach((pad) => {
                                for (var i = 1; i <= 20; i++) {
                                    var value = pad["col" + i];
                                    if (value) {
                                        found = true;
                                        if (found && i > column) {
                                            column = i;
                                        }
                                    }

                                    this.setamosdetailcolswidth(pad, i, colswidtharr, pa);
                                }
                            });

                            pa.maxcolumn = column;
                            var columns = [];
                            for (var i = 1; i <= column; i++) {
                                columns.push({ key: i, value: ("0" + i).substr(("0" + i).length - 2), text:"" });
                            }

                            pa.details.forEach((pad) => {
                                pad.qtycolumns = JSON.parse(JSON.stringify(columns));
                                pad.astcolumns = JSON.parse(JSON.stringify(columns));
                                pad.specolumns = JSON.parse(JSON.stringify(columns));
                                pad.endnotescolumns = JSON.parse(JSON.stringify(columns));
                            });
                        });
                        this.displayimportedtab = [];
                        this.viewmode = false;
                        this.showamosid = null;
                        json.projectamoses.forEach(x => {
                            let newfile = {
                                id : x.id,
                                filename: x.filename,
                                editedbyloginname: x.editedbyloginname
                            };
                            this.displayimportedtab.push(newfile);
                        });
                        this.detailform = json;
                       if(this.searchbqcode != "" || this.searchastspeother != ""){
                        this.detailform.projectamoses.forEach((x)=>{
                                var dt = this.getdetails(x.details,x);
                                if(dt.length != 0){
                                        this.$refs.pa.toggleRowExpansion(x);
                                }
                        })
                       }
                        this.attachments = [];

                        this.detail = true;

                        // this.loading = false;
                    }).catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err)

                        // this.loading = false;
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
            },

            autogetdatausingch(selectedcolumn, home, row) {
                let str = "";
                var newarray = [];
                if (home != '') {
                    newarray.push(home);
                }

                if (selectedcolumn.length >= 1) {
                    var last = selectedcolumn[selectedcolumn.length - 1];
                    for (let ss of selectedcolumn) {
                        newarray.push(row["col" + ss.key]);
                        if (ss.text == "" && last.key != ss.key) {
                            newarray.push("; ");
                        } else {
                            newarray.push(ss.text);
                        }
                    }
                }
                
                str = newarray.join("");
                return str;
            },

            //for updating nrmsort and bqcode
            focusSearchAmt: function (row) {
                var query = '';
                var data = { section: this.detailform.section.section, library: this.detailform.library, amtemplatename: query };
                this.$http.post('amt/SearchForAutoComplete', data)
                    .then(response => {
                        row.amtoptions = JSON.parse(JSON.stringify(response.data));
                        var addnew = { id: null, amtemplatename: '' };
                        row.amtoptions.push(addnew);
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },

            remoteSearchAmt: function (query, row) {
                if (query == null) {
                    query = '';
                }
                var data = { section: this.detailform.section.section, library: this.detailform.library, amtemplatename: query };
                this.$http.post('amt/SearchForAutoComplete', data)
                    .then(response => {
                        row.amtoptions = JSON.parse(JSON.stringify(response.data));
                        var addnew = { id: null, amtemplatename: '' };
                        row.amtoptions.push(addnew);
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },


            amtchanged: function (row, value, changed, pa, rowindex) {
                if (value != '' && value != null) {
                    var data = { id: value, section: this.detailform.section.section, library: this.detailform.library };
                    this.$http.post("amt/getNrmSortBQCode", data)
                        .then(response => {
                            for (var bqcode of response.data.bqcodelibraries) {
                                bqcode.fromamt = true;
                            }
                            for (var bcc of response.data.billcompilecodes) {
                                bcc.fromamt = true;
                            }
                            var newbcc = []
                            if (response.data.billcompilecodes.length > 0 || response.data.billcompilecodes2.length > 0) {
                                var ids = new Set(response.data.billcompilecodes.map(d => d.id));
                                newbcc = [...response.data.billcompilecodes, ...response.data.billcompilecodes2.filter(d => !ids.has(d.id))];
                            }
                            row.bqcodeoptions = response.data.bqcodelibraries;
                            row.nrmsortoptions = newbcc;
                            if (changed) {
                                row.amtemplate = row.amtoptions.find(x => x.id == value);

                                if (response.data.billcompilecodes.length == 1) {
                                    row.nrmsortingref = response.data.billcompilecodes[0].description;
                                }

                                if (response.data.bqcodelibraries.length == 1 && pa) {
                                    rowindex = pa.details.findIndex(p=>p.id == row.id);

                                    let currentrowdata = { 
                                        data: { pa: pa, pad: row, index: rowindex }
                                    }

                                    let bqcodelibrary = response.data.bqcodelibraries[0];

                                    let amtbqcode = {
                                        id: bqcodelibrary.id,  
                                        bqcodelibrary: bqcodelibrary,
                                        unit: bqcodelibrary.unit
                                    }

                                    this.handleSelectBQCodeall(currentrowdata, amtbqcode, true);
                                }

                                this.changed(null, row);
                            }
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        })
                }
                if (value == '' || value == null) {
                    row.amtemplateid = null;
                    row.amtemplate = {};
                }
            },

            querySearchBQCode: function (query, done, pa, pad, index) {
                 index = pa.details.findIndex(p=>p.id == pad.id);
                this.$http.post('bqcodelibrary/search?library=' + this.detailform.library + '&q=' + query, {})
                    .then(response => {
                        var newBQ = JSON.parse(response.data)
                        var bqcodes = [];
                        if (query == "") {
                            if (pad.predicate == 'Pre') {
                                for (var i = index - 1; i >= 0; i--) {
                                    var pad1 = pa.details[i];
                                    var pre = pad1.predicate || "";
                                    if (pre == "CH") {
                                        if (pad1.bqcodeoptions.length > 0) {
                                            var ids = new Set(pad1.bqcodeoptions.map(d => d.id));
                                            bqcodes = [...pad1.bqcodeoptions, ...newBQ.filter(d => !ids.has(d.id))];
                                        }
                                        else {
                                            bqcodes = newBQ;
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                        else {
                            bqcodes = newBQ;
                        }
                        done(bqcodes);
                    })
                    .catch(err => {
                        var bqcodes = [];
                        done(bqcodes);
                        this.$bus.$emit('error', err);
                    })
            },
            bqcodeblur: function (item, event) {
                if (!item.bqcodelibrary.bqcode) {
                    item.bqcodelibraryid = 0;
                    this.changed(null, item);
                }
            },

            handleSelectBQCodeall: function (paramboxdata, newBQCode, isamtbqcode) {
                let ischrowcopytoall = false;

                if (paramboxdata.data.previousCHRow) {
                    this.copyCHToAllNextRows(paramboxdata.data.pad, paramboxdata.data.previousCHRow);
                    if (paramboxdata.data.previousCHRow.ischrowcopytoall) {
                        ischrowcopytoall = paramboxdata.data.previousCHRow.ischrowcopytoall;
                    }
                }

                let newprojectbqcode = null;
                const boxdata = JSON.parse(JSON.stringify(paramboxdata));
                let obj = { bqcodelibraryid: newBQCode.id, projectsectionid: this.detailform.section.id };
                this.getprojectBQCode(obj).then((rest) => {
                    newprojectbqcode = rest;
                    // if (boxdata.data.previousCHRow) {
                    //     this.copyCHToAllNextRows(boxdata.data.pad, boxdata.data.previousCHRow);
                    //     if (boxdata.data.previousCHRow.ischrowcopytoall) {
                    //         ischrowcopytoall = boxdata.data.previousCHRow.ischrowcopytoall;
                    //     }

                        /*
                        if (boxdata.data.currentCHRow.isAMTTicked) {
                            boxdata.data.pad.amtemplateid = boxdata.data.currentCHRow.amtemplateid;
                            boxdata.data.pad.amtemplate = boxdata.data.currentCHRow.amtemplate;
                            boxdata.data.pad.amtoptions = boxdata.data.currentCHRow.amtoptions;
                            boxdata.data.pad.bqcodeoptions = boxdata.data.currentCHRow.bqcodeoptions;
                        }
    
                        if (boxdata.data.currentCHRow.isNRMSortTicked) {
                            boxdata.data.pad.nrmsortingref = boxdata.data.currentCHRow.nrmsortingref;
                            boxdata.data.pad.nrmsortoptions = boxdata.data.currentCHRow.nrmsortoptions;
                        }
    
                        // if (boxdata.data.currentCHRow.isSpecificationTicked) {
                        //     boxdata.data.pad.bqcodelibraryid = boxdata.data.currentCHRow.bqcodelibraryid;
                        // }
    
                        if (boxdata.data.currentCHRow.isSpecificationTicked) {
                            boxdata.data.pad.lstspecification = boxdata.data.currentCHRow.lstspecification;
    
                            boxdata.data.pad.specification = "";
                            boxdata.data.pad.lstspecification.forEach((v) => {
                                boxdata.data.pad.specification += "\{" + v.key + "\}" + v.text;
                            });
                        }
    
                        if (boxdata.data.currentCHRow.isAsteriskTicked) {
                            boxdata.data.pad.lstasterisk = boxdata.data.currentCHRow.lstasterisk;
                            
                            boxdata.data.pad.asterisk = "";
                            boxdata.data.pad.lstasterisk.forEach((v) => {
                                boxdata.data.pad.asterisk += "\{" + v.key + "\}" + v.text;
                            });
                        }
    
                        if (boxdata.data.currentCHRow.isEndNotesTicked) {
                            boxdata.data.pad.endnotes = boxdata.data.currentCHRow.endnotes;
                        }
    
                        if (boxdata.data.currentCHRow.ischrowcopytoall) {
                            boxdata.data.pad.ischrowcopytoall = boxdata.data.currentCHRow.ischrowcopytoall;
                        }*/
                    // }

                    var pa = this.detailform.projectamoses.find(p => p.id == boxdata.data.pa.id);
                    var bqcodelibrary = newBQCode.bqcodelibrary;
                    var matchchrow = pa.details[boxdata.data.index];
                    var qtycoll = -1;
                    for (let index = 1; index <= 20; index++) {
                        const cell = matchchrow['col' + index]
                        if (cell) {
                            var newcell = cell.toLowerCase();
                            if (newBQCode.unit == "m" && newcell == "length total") {
                                qtycoll = index;
                            }
                            if (newBQCode.unit == "m2" && newcell == "area total") {
                                qtycoll = index;
                            }
                            if ((newBQCode.unit == "nr" || newBQCode.unit == "item") && (newcell == "measurement" || newcell == "count")) {
                                qtycoll = index;
                            }
                        }
                    }

                    if (qtycoll != -1) {
                        this.$set(pa.details[boxdata.data.index], "qty", qtycoll);
                        // pa.details[boxdata.data.index].qty = qtycoll; 
                        boxdata.data.pad.qty = qtycoll;
                    }


                    for (var i = boxdata.data.index + 1; i < pa.details.length; i++) {
                        var dit = pa.details[i];
                        var pre = dit.predicate || "";

                        if (pre == "SN" && ischrowcopytoall) {
                            continue;
                        } else if (pre == "CH" && ischrowcopytoall) {
                            this.copyCHToAllNextRows(dit, boxdata.data.previousCHRow);
                            continue;
                        }

                        if (pre == "Pre") {
                            // if (dit.bqcodelibrary.bqcode == "") {
                            // if (isamtbqcode || !ischrowcopytoall) {
                            if (newBQCode && newBQCode.id) {
                                
                                for (let key in bqcodelibrary) {
                                    dit.bqcodelibrary[key] = bqcodelibrary[key];
                                    // }
                                }
                                dit.bqcodelibraryid = newBQCode.id;
                                dit.unit = newBQCode.unit;
                                dit.projectbqcode = newprojectbqcode;
                            }
                                // if(qtycoll != -1){
                            //     dit.qty = dit['col'+qtycoll];
                            // }
                            if (boxdata.data.previousCHRow && boxdata.data.previousCHRow.isQtyTicked) {
                                if (!isNaN(dit["col" + boxdata.data.pad.qty])) {
                                    dit.qty = parseFloat(dit["col" + boxdata.data.pad.qty]);
                                    if (isNaN(dit.qty))
                                        dit.qty = 0;
                                } else {
                                    dit.qty = 0;
                                }
                            }

                            // if ((boxdata.data.previousCHRow) && (boxdata.data.previousCHRow.isSpecificationTicked ||
                            //     boxdata.data.previousCHRow.isAsteriskTicked || boxdata.data.previousCHRow.isEndNotesTicked )) {

                            var ar = [], lastast = null;
                            if (boxdata.data.previousCHRow && boxdata.data.previousCHRow.isSpecificationTicked) {
                                lastast = boxdata.data.pad.lstspecification[boxdata.data.pad.lstspecification.length - 1];

                                if (boxdata.data.pad.specificationhome != "") {
                                    ar.push(boxdata.data.pad.specificationhome)
                                }

                                for (var ast of boxdata.data.pad.lstspecification) {
                                    var padcol = dit["col" + ast.key];
                                    var textvalue = ast.text.toString();
                                    if (textvalue == "" && lastast.key != ast.key) {
                                        padcol = padcol + "; "
                                    }
                                    var pushvalue = padcol + textvalue;
                                    ar.push(pushvalue);
                                }
                                dit.specification = ar.join("");
                            }

                            if (boxdata.data.previousCHRow && boxdata.data.previousCHRow.isAsteriskTicked) {
                                ar = [];
                                lastast = boxdata.data.pad.lstasterisk[boxdata.data.pad.lstasterisk.length - 1];

                                if (boxdata.data.pad.asteriskhome != "") {
                                    ar.push(boxdata.data.pad.asteriskhome)
                                }

                                for (var ast of boxdata.data.pad.lstasterisk) {
                                    var padcol = dit["col" + ast.key];
                                    var textvalue = ast.text.toString();
                                    if (textvalue == "" && lastast.key != ast.key) {
                                        padcol = padcol + "; "
                                    }
                                    var pushvalue = padcol + textvalue;
                                    ar.push(pushvalue);
                                }
                                dit.asterisk = ar.join("");
                            }

                            if (boxdata.data.previousCHRow && boxdata.data.previousCHRow.isEndNotesTicked) {
                                // dit.endnotes = boxdata.data.pad.endnotes;
                                ar = [];
                                lastast = boxdata.data.pad.lstendnotes[boxdata.data.pad.lstendnotes.length - 1];

                                if (boxdata.data.pad.endnoteshome != "") {
                                    ar.push(boxdata.data.pad.endnoteshome)
                                }

                                for (var ast of boxdata.data.pad.lstendnotes) {
                                    var padcol = dit["col" + ast.key];
                                    var textvalue = ast.text.toString();
                                    if (textvalue == "" && lastast.key != ast.key) {
                                        padcol = padcol + "; "
                                    }
                                    var pushvalue = padcol + textvalue;
                                    ar.push(pushvalue);
                                }
                                dit.endnotes = ar.join("");
                            }
                            // }

                            this.changed(null, dit);
                            // }
                        } else if (pre == "Edit") {
                            continue;
                        }
                        else {
                            break;
                        }
                    }
                });
            },
            
            handleSelectBQCode(item, value) {
                item.bqcodelibraryid = value.id;
                item.bqcodelibrary = value;
                item.unit = value.unit;
                this.changed(null, item);
                let obj = { bqcodelibraryid: item.bqcodelibraryid, projectsectionid: this.detailform.section.id };
                this.getprojectBQCode(obj).then((data) => {
                    item.projectbqcode = data;
                });
            },

            getprojectBQCode(event) {
                return this.$http.post("project/getprojectbqcode", event)
                    .then(response => {
                        let json = JSON.parse(response.data);
                        return json;
                    }).catch((err) => {
                        this.$bus.$emit('error', err);
                    });
            },

            handlechangeBQCode(data){
                data.rowstyle.bqcode = false;
            },

            predicatechanged: function (pa, pad, index, column) {
                index = pa.details.findIndex(p => p.id == pad.id);
                if (column == 'predicate' && pad.predicate == 'CH') {
                    delete pad.qty;
                    pad.asterisk = null;
                    pad.lstasterisk = [];
                    pad.specification = null;
                    pad.lstspecification = [];
                    pad.endnotes = null;
                    pad.lstendnotes = [];
                    pad.amtoptions = [];
                    pad.nrmsortoptions = [];
                    pad.bqcodeoptions = [];
                    pad.nrmsortingref = null;
                    pad.amtemplateid = null;
                }

                if (column == 'predicate' && pad.predicate == 'Pre') {
                    pad.nrmsortingref = null;
                    pad.amtemplateid = null;

                    for (var i = index - 1; i >= 0; i--) {
                        var pad1 = pa.details[i];
                        var pre = pad1.predicate || "";

                        if (pre == "CH") {
                            var q = pad1.qty;
                            pad.qty = parseFloat(pad["col" + q]);
                            if (isNaN(pad.qty))
                                pad.qty = 0;

                            if (pad1.lstasterisk.length >= 1) {
                                var a = pad1.lstasterisk;
                                var lastast = a[a.length - 1];
                                var as = [];

                                for (var ast of a) {
                                    as.push(pad["col" + ast.key]);
                                    if (ast.text == "" && lastast.key != ast.key)
                                        as.push("; ");
                                    else
                                        as.push(ast.text);
                                }
                                pad.asterisk = as.join("");
                            }
                            else {
                                pad.asterisk = null;
                            }

                            if (pad1.lstspecification.length >= 1) {
                                var s = pad1.lstspecification;
                                var lastsp = s[s.length - 1];
                                var sp = [];

                                for (var spe of s) {
                                    sp.push(pad["col" + spe.key]);
                                    if (spe.text == "" && lastsp.key != spe.key)
                                        sp.push("; ");
                                    else
                                        sp.push(spe.text);
                                }
                                pad.specification = sp.join("");
                            }
                            else {
                                pad.specification = null;
                            }

                            if (pad1.lstendnotes.length >= 1) {
                                var e = pad1.lstendnotes;
                                var lastendnote = e[e.length - 1];
                                var endn = [];

                                for (var notes of e) {
                                    endn.push(pad["col" + notes.key]);
                                    if (notes.text == "" && lastendnote.key != notes.key)
                                        endn.push("; ");
                                    else
                                        endn.push(notes.text);
                                }
                                pad.endnotes = endn.join("");
                            }
                            else {
                                pad.endnotes = null;
                            }
                            // pad.endnotes = pad1.endnotes;
                            break;
                        }
                    }
                }

                if (column == "predicate" && pad.predicate == "") {
                    delete pad.qty;
                    pad.asterisk = null;
                    pad.lstasterisk = [];
                    pad.specification = null;
                    pad.lstspecification = [];
                    pad.lstendnotes = [];
                    pad.endnotes = null;
                    //pad.bqcodelibrary = null;
                    //pad.bqcodelibraryid = 0;
                    //pad.unit = null;
                    pad.omitfromlibrary = false;
                    pad.edit = false;
                    pad.nrmsortingref = null;
                    //pad.template = null;
                    //pad.templateid = 0;
                    pad.amtemplateid = null;
                }
            },

            headerchanged: function (pa, pad, index, column, changestyle) {
                index = pa.details.findIndex(p => p.id == pad.id);

                if (column == "qty" || column == "asterisk" || column == "specification" || column == "endnotes") {
                    var chvalue = [];
                    if (pad.qty)
                        chvalue.push(pad.qty);
                    if (pad.lstasterisk.length > 0) {
                        pad.lstasterisk.forEach((ast) => { chvalue.push(ast.key) });
                    }
                    if (pad.lstspecification.length > 0) {
                        pad.lstspecification.forEach((spe) => { chvalue.push(spe.key) });
                    }
                    if (pad.lstendnotes.length > 0) {
                        pad.lstendnotes.forEach((spe) => { chvalue.push(spe.key) });
                    }
                    for (var i = 0; i < chvalue.length; ++i) {
                        for (var j = i + 1; j < chvalue.length; ++j) {
                            if (chvalue[i].toString() === chvalue[j].toString()) {
                                if (column == "qty")
                                    pad.qty = 0;
                                if (column == "asterisk")
                                    pad.lstasterisk.pop();
                                if (column == "specification")
                                    pad.lstspecification.pop();
                                if (column == "endnotes")
                                    pad.lstendnotes.pop();
                                this.$alert(" Selected Column Header " + chvalue[i].toString() + " Not Allowed more than once", "Warning", { type: 'warning' });
                                //return;
                            }
                        }
                    }
                }

                //if (column == "astseparator" || column == "speseparator") {
                //    pad = pa.details[index];
                //}
                //var seprow = pa.details[index - 1];
                //var separotor = "";
                //if (column == "astseparator" || column == "asterisk") {
                //    separotor = seprow.asterisk;
                //    if (separotor == "" || separotor == null)
                //        separotor = "; ";
                //}
                //else if (column == "speseparator" || column == "specification") {
                //    separotor = seprow.specification;
                //    if (separotor == "" || separotor == null)
                //        separotor = "; ";
                //}
                //if (column == "astseparator")
                //    column = "asterisk";
                //else if (column == "speseparator")
                //    column = "specification";

                var arcols = [];
                if (column == 'asterisk') {
                    //pad.asterisk = pad.lstasterisk.join("+");
                    pad.asterisk = "";
                    if (pad.asteriskhome != "") {
                        pad.asterisk += "\{" + 0 + "\}" + pad.asteriskhome;
                    }
                    pad.lstasterisk.forEach((v) => {
                        pad.asterisk += "\{" + v.key + "\}" + v.text;
                        //pad.asterisk += "" + v.key + "" + v.text;
                    });
                    arcols = pad.lstasterisk;
                }

                if (column == 'specification') {
                    pad.specification = "";
                    if (pad.specificationhome != "") {
                        pad.specification += "\{" + 0 + "\}" + pad.specificationhome;
                    }
                    pad.lstspecification.forEach((v) => {
                        pad.specification += "\{" + v.key + "\}" + v.text;
                    });
                    arcols = pad.lstspecification;
                }
                if (column == 'endnotes') {
                    pad.endnotes = "";
                    if (pad.endnoteshome != "") {
                        pad.endnotes += "\{" + 0 + "\}" + pad.endnoteshome;
                    }
                    pad.lstendnotes.forEach((v) => {
                        pad.endnotes += "\{" + v.key + "\}" + v.text;
                    });
                    arcols = pad.lstendnotes;
                }

                var lastast = arcols[arcols.length - 1];

                //index must be column header type predicate
                //loop until predicate is null or Edt

                for (var i = index + 1; i < pa.details.length; i++) {
                    var pad1 = pa.details[i];
                    var pre = pad1.predicate || "";

                    if (pre == "Pre") {
                        if (column == "qty") {
                            if (!isNaN(pad1["col" + pad.qty])) {
                                pad1.qty = parseFloat(pad1["col" + pad.qty]);
                                if (isNaN(pad1.qty))
                                    pad1.qty = 0;
                            } else {
                                pad1.qty = 0;
                            }
                        }
                        if (column == "asterisk" || column == "specification" || column == "endnotes") {
                            if (changestyle && column == "asterisk") {
                                pad1.rowstyle.asterisk = false;
                            }
                            if (changestyle && column == "specification") {
                                pad1.rowstyle.specification = false;
                            }
                            if (changestyle && column == "endnotes") {
                                pad1.rowstyle.endnotes = false;
                            }
                            var ar = [];
                            if (pad.asteriskhome != "" && column == "asterisk") {
                                ar.push(pad.asteriskhome)
                            }
                            if (pad.specificationhome != "" && column == "specification") {
                                ar.push(pad.specificationhome)
                            }
                            if (pad.endnoteshome != "" && column == "endnotes") {
                                ar.push(pad.endnoteshome)
                            }
                            for (var ast of arcols) {
                                var padcol = pad1["col" + ast.key];
                                var textvalue = ast.text.toString();
                                if (textvalue == "" && lastast.key != ast.key) {
                                    padcol = padcol + "; "
                                }
                                var pushvalue = padcol + textvalue;
                                ar.push(pushvalue);
                                //ar.push(pad1["col" + ast.key]);
                            }

                            pad1[column] = ar.join("");
                        }
                        // if (column == "endnotes") {
                        //     pad1[column] = pad[column];
                        // }
                    } else if (pre == "Edit") {
                        continue;
                    }
                    else {
                        break;
                    }
                }

                if (pad.asterisk || pad.specification) {
                    pad.disabledmergerowbtn = false;
                } else {
                    pad.disabledmergerowbtn = true;
                }
            },

            removetag: function (value, pa, pad, index, column) {
                index = pa.details.findIndex(p => p.id == pad.id);
                value.text = "";
                if (pad.lstasterisk.length == 0 || pad.lstspecification.length == 0 || pad.lstendnotes.length == 0) {
                    if (column == "asterisk" && pad.lstasterisk.length == 0) {
                        pad.asteriskhome = "";
                        pad[column] = null;
                    }
                    else if (column == "specification" && pad.lstspecification.length == 0) {
                        pad.specificationhome = "";
                        pad[column] = null;
                    }
                    else if (column == "endnotes" && pad.lstendnotes.length == 0) {
                        pad.endnoteshome = "";
                        pad[column] = null;
                    }
                    for (var i = index + 1; i < pa.details.length; i++) {
                        var pad1 = pa.details[i];
                        var pre = pad1.predicate || "";

                        if (pre == "Pre" || pre == "Edt") {
                            if (column == "asterisk" && pad.lstasterisk.length == 0) {
                                pad1[column] = null;
                            }
                            else if (column == "specification" && pad.lstspecification.length == 0) {
                                pad1[column] = null;
                            }
                            else if (column == "endnotes" && pad.lstendnotes.length == 0) {
                                pad1[column] = null;
                            }
                        }
                        else {
                            break;
                        }
                    }
                }
            },

            confirmimportclose: function (value) {
                this.confirmimportdialog = false;
                if (value) {
                    this.importconfirmed = true;
                    this.save();
                }
            },

            confirmimport: function () {

                this.$http.post('amos/ImportAmosCheck', this.detailform)
                    .then(response => {
                        var json = JSON.parse(response.data);

                        if (json.toadd.length > 0 || json.todelete.length > 0 || json.toupdate.length > 0) {
                            this.importcheck = json;
                            this.confirmimportdialog = true;
                            this.importconfirmed = false;
                             this.hideloading();
                            // this.loading = false;
                            this.amossaving = false;
                            
                            this.savebuttonkey++;
                        }
                        else { //for schedule import no warning.
                            this.importconfirmed = true;
                            this.amossaving = false;
                            this.save();
                        }

                    }).catch(err => {
                        this.amossaving = false;
                        this.savebuttonkey++;
                        
                         if(err.response.data != null){
                             var amoserr = [];
                               amoserr.push(err.response.data);
                             this.errorobject = {
                            message: amoserr, type: "required", visible: true, sourcereq: "" };
                        this.validationerror = true;
                         }else{
                             this.$bus.$emit('error', err)
                         }
                        this.importconfirmed = false;
                          this.hideloading();
                        //   this.loading = false;
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                        //this.$alert(err.data, err.response.status + ' ' + err.response.statusText);
                    });
            },

            canedit: function (warn, callback) {
                if (this.detailform) {
                    if (this.user.role == "B" && this.user.sections.findIndex((x) => x.section == this.detailform.section.section) == -1) {
                        if (warn) {
                            this.$alert("This section is not in your allowed editing list, you wil not be able to save changes").then(callback);
                        }
                        return false;
                    }
                    return true;
                }
                return false;
            },

            viewheader() {
                this.openheader = true;
            },

            getfilehistory() {
                this.showloading();
                let data = {
                    id : this.detailform.id,
                    projectname : this.detailform.projectname,
                    projectpart : this.detailform.projectpart,
                    section : this.detailform.section
                };
                let url = "amos/getamosfilehistory";
                this.$http.post(url, data)
                    .then(response => {
                        this.filehistory = response.data;
                        this.visiblemedia = true;
                        this.hideloading();
                    }).catch(err => {
                        this.hideloading();
                        this.$bus.$emit("error", err);
                    });
            },

            handledialogclose() {
                this.visiblemedia = false;
            },

            save: function (event) {
                // if (this.detailform.replace) {
                //     this.ImportAmosReplace();
                // }


                if(this.isimport)
                {
                    this.savebuttonkey++;
                    return;
                }
                var isvalidcheck = this.validlengthonsave(this.detailform.projectamoses);
                if (isvalidcheck) {
                    this.savebuttonkey++;
                    return;
                }

                if(!this.amossaving){
                    this.amossaving = true;
                //   this.loading = true;
                //   this.showloading();
                if (!this.canedit(true)) {
                    this.amossaving = false;
                    this.savebuttonkey++;
                    return;
                }

                if (!this.importconfirmed && this.attachments.length > 0 && this.detailform.replace == true && this.detailform.replaceid > 0) {
                    this.confirmimport();
                    //  this.hideloading();
                    //  this.loading = false;
                    this.amossaving = false;
                    // this.savebuttonkey++;
                    return;
                }

                if (!this.detailform.replaceid) {
                    this.detailform.replace = false;
                }
                this.detailform.projectamoses.forEach(e => {
                    var isfind = this.columnwrappers.find(x => x.id == e.id);
                    if(isfind == undefined && e.columnwrapper != undefined){
                        this.columnwrappers.push({id:e.id,array:e.columnwrapper})
                    }
                });
                // this.autodetectcolumnsfromexcel();
                //   this.loading = true;
                this.getpageactionmessage("AMOS","Save",this.detailform);
                  this.showloading();
                this.saving = true;
                this.$bus.$emit('savescroll');
                // if (this.saveInProgress) {
                //     return;
                // }


                // Set save in progress flag to true
                this.saveInProgress = true;
                this.$http.post('amos/SaveAmos', this.detailform)
                    .then(response => {
                        this.saveInProgress = false;
                        this.amossaving = false;
                        this.hideloading();
                        this.saving = false;
                        // this.loading = false;
                        this.$message({
                            showClose: true,
                            message: response.data, type: "success"
                        });
                        this.detail = false;
                        //this.$refs.detailform.resetFields();
                        this.refresh();
                        this.importconfirmed = false;
                        this.attachments = [];

                        this.savebuttonkey++;
                        clearTimeout(this.saveButtonTimeout);

                        //reset autosave timings
                        window.clearInterval(this.interval);
                        this.interval = window.setInterval(this.autosave, 10 * 60000);

                    }).catch(err => {
                        this.amossaving = false;
                        this.saveInProgress = false;
                        if(err.response.data != null){
                             var amoserr = [];
                               amoserr.push(err.response.data);
                             this.errorobject = {
                            message: amoserr, type: "required", visible: true, sourcereq: "" };
                        this.validationerror = true;
                         }else{
                             this.$bus.$emit('error', err)
                         }
                        this.hideloading();
                        // this.loading = false;
                        this.saving = false;
                        // this.$bus.$emit('error', err)
                        this.importconfirmed = false;
                        
                        this.savebuttonkey++;
                        clearTimeout(this.saveButtonTimeout);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                        //this.$alert(err.data, err.response.status + ' ' + err.response.statusText);
                    });
                }
            },

            savetoproject: function (validate) {
                //  if(!this.amossaving){
                //     this.amossaving = true;
                var isvalidcheck = this.validlengthonsave(this.detailform.projectamoses);
                if (isvalidcheck) {
                    return;
                }

                if (!this.canedit(true)) {
                    return;
                }

                var save = () => {
                    this.saving = true;
                   
                    this.detailform.projectamoses.forEach(e => {
                        var isfind = this.columnwrappers.find(x => x.id == e.id);
                        if(isfind == undefined && e.columnwrapper != undefined){
                            this.columnwrappers.push({id:e.id,array:e.columnwrapper})
                        }
                    });
                    this.getpageactionmessage("AMOS","Save to Project",this.detailform);
                    this.$bus.$emit('savescroll');
                    // this.loading = true;
                    this.showloading();
                    this.$http.post('amos/SaveAmosToProject', this.detailform)
                        .then(response => {
                            this.hideloading();
                            this.amossaving = false;
                            this.saving = false;
                            // this.loading = false;
                            this.warncheck = false;
                            this.validationerror = false;
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            this.detail = false;
                            this.refresh();
                            this.importconfirmed = false;
                            this.attachments = [];
                            //reset autosave timings
                            window.clearInterval(this.interval);
                            this.interval = window.setInterval(this.autosave, 10 * 60000);
                        })
                        .catch(err => {
                            this.amossaving = false;
                            this.hideloading();
                            this.saving = false;
                            // this.loading = false;
                            this.warncheck = false;
                            this.$bus.$emit('error', err)
                            this.importconfirmed = false;
                        });
                }
                if (validate) {
                    this.validateamos(save)
                    .then((res) => {
                            console.log(res);  // "Validation successful"
                        })
                        .catch((error) => {
                            console.error(error.message);  // Handle errors or warnings
                        });
                }
                else {
                    save();
                }
            //   }
            },
            validdatalengthpri(td1,t1,obj,bq){
                var errormsg = [];
                if((td1.predicate == "Pre" && td1.edit == false) || td1.predicate == "Edit"){
                     var other = "";
                     var specification = "";
                     var work = "";
                     var level1 = "";
                     var level2 = "";
                     var level3 = "";
                     var asterisk = "";
                     let newbqcode;
                     if(bq != undefined){
                        newbqcode = bq; 
                     }else{
                         newbqcode = td1.projectbqcode; 
                     }
                     if(newbqcode == null){
                        newbqcode = {};
                     }
                      other = td1.endnotes
                specification =  td1.specification;
                asterisk =  td1.asterisk;
                work =  newbqcode.work
                level1 =  newbqcode.level1
                 level2 =  newbqcode.level2
                  level3 =  newbqcode.level3
                  if(asterisk == null){
                      asterisk = ""
                  }
                  if(other == null){
                      other = ""
                  }
                  if(specification == null){
                      specification= ""
                }
                  if(work == null){
                      work = ""
                }
                  if(level1 == null){
                      level1 = ""
                  }
                  if(level2 == null){
                      level2 = ""
                  }
                  if(level3 == null){
                      level3 = ""
                  }

                  if(asterisk != null && asterisk != ""){
                       var asterisksEntries = asterisk.split('^');
                        // var desc = td1.bqcodelibrary.work + td1.bqcodelibrary.level1 + td1.bqcodelibrary.level2 + td1.bqcodelibrary.level3;
                        // var asterisks = desc.split('*');
                        asterisksEntries.forEach(as => {
                            if(work != null && work.includes("*"))
                                work = work.replace("*", as);
                            else if (level1 != null && level1.includes("*"))
                                level1 = level1.replace("*", as);
                            else if (level2 != null && level2.includes("*"))
                                level2 = level2.replace("*", as);
                            else if (level3 != null && level3.includes("*"))
                               level3 = level3.replace("*", as);
                        });



                  }
                  if(specification != null){
                        switch (newbqcode.specification) {
                            case 0:
                                work = work + specification;
                                break;
                            case 1:
                                level1 = level1 + specification;
                                break;
                            case 2:
                                 level2 = level2 + specification;
                                break;
                            case 3:
                                level3 = level3 + specification;
                                break;
                            }
                   }else{
                      specification = "";
                   }
                   if(other != null && other != ""){
                       if(level3 != null && level3 != ""){
                           level3 = level3 + other;
                       }
                      else if(level2 != null && level2 != ""){
                             level2 = level2 + other;
                       }else{
                            level1 = level1 + other;
                       }
                   }else{
                       other = "";
                   }

                //     if(asterisk.length > 255){
                //     //   var newother =  other.substring(0, other.length/4) + '  </br>  ' + other.substring(other.length/4,other.length/2) + ' </br>  '+ other.substring(other.length/2,other.length)

                //      errormsg.push('Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ asterisk +'  </br> exceed 255 characters limitation. Please, correct the Asterisk or specification or BQ_code within library')
                //   }
                  if(other.length > 400){
                    //   var newother =  other.substring(0, other.length/4) + '  </br>  ' + other.substring(other.length/4,other.length/2) + ' </br>  '+ other.substring(other.length/2,other.length)
                     var newobj = JSON.parse(JSON.stringify(obj));
                     newobj.msg = '<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ newbqcode.bqcode +','+'  </br> exceed 400 characters limitation. Please, correct the Asterisk or specification or BQ_code within library</p>'; 
                     errormsg.push(newobj);
                  }
                   if(work.length > 400){
                        //  var newother =  work.substring(0, work.length/4) + '  </br>  ' + work.substring(work.length/4,work.length/2) + ' </br>  '+ work.substring(work.length/2,work.length)

                     var newobj = JSON.parse(JSON.stringify(obj));
                     newobj.msg = '<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ newbqcode.bqcode +','+ '  </br> exceed 400 characters limitation. Please, correct the Asterisk or specification or BQ_code within library</p>';
                     errormsg.push(newobj)
                  }
                  if(level1.length > 400){
                    var newobj = JSON.parse(JSON.stringify(obj));
                    newobj.msg = '<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ newbqcode.bqcode +',' +'</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or BQ_code within library</p>';
                     errormsg.push(newobj)
                    //  errormsg.push()
                  }
                   if(level2.length > 400){
                    var newobj = JSON.parse(JSON.stringify(obj));
                    newobj.msg = '<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ newbqcode.bqcode +',' +'</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or BQ_code within library</p>';
                     errormsg.push(newobj)
                    //  errormsg.push()
                  }
                   if(level3.length > 400){
                    var newobj = JSON.parse(JSON.stringify(obj));
                    newobj.msg = '<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ newbqcode.bqcode +',' +'</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or  BQ_code within library</p>';
                     errormsg.push(newobj)
                    //  errormsg.push()
                  }
                }
                // var tdasterisk = td1.asterisk ? td1.asterisk : "";
                // var tdspecification = td1.specification ? td1.specification : "";
                // if(tdasterisk.length + tdspecification.length > 400){

                //     var newobj = JSON.parse(JSON.stringify(obj));
                //     newobj.msg = "<p style='word-wrap: break-word;'>Your data within 'Specification + Asterisk' column of row-"+td1.rowno +" in "+ t1.filename +" file </br> exceed 400 characters limitation. Please, correct the Specification or Asterisk.</p>";
                //     errormsg.push(newobj)
                //     // errormsg.push()
                //     //  errormsg.push('<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ work  +'  </br> exceed 255 characters limitation. Please, correct the Asterisk or specification or BQ_code within library</p>')
                // }
                return errormsg;
            },
    // validdatalength(data){
    //     var errormsg = [];

    //     data.forEach(t1 => {
    //             t1.details.forEach(td1 => {
    //                 if(td1.predicate == "Pre"){
    //                  var other = "";
    //                  var specification = "";
    //                  var work = "";
    //                  var level1 = "";
    //                  var level2 = "";
    //                  var level3 = "";
    //                  var asterisk = "";
    //                  if(td1.bqcodelibrary == null){
    //                   td1.bqcodelibrary = {};
    //               }
    //                   other = td1.other
    //             specification =  td1.specification;
    //             asterisk =  td1.asterisk;
    //             work =  td1.bqcodelibrary.work
    //             level1 =  td1.bqcodelibrary.level1
    //              level2 =  td1.bqcodelibrary.level2
    //               level3 =  td1.bqcodelibrary.level3
    //               if(asterisk == null){
    //                   asterisk = ""
    //               }
    //               if(other == null){
    //                   other = ""
    //               }
    //               if(specification == null){
    //                   specification= ""
    //             }
    //               if(work == null){
    //                   work = ""
    //             }
    //               if(level1 == null){
    //                   level1 = ""
    //               }
    //               if(level2 == null){
    //                   level2 = ""
    //               }
    //               if(level3 == null){
    //                   level3 = ""
    //               }

    //               if(asterisk != null && asterisk != ""){
    //                    var asterisksEntries = asterisk.split('^');
    //                     // var desc = td1.bqcodelibrary.work + td1.bqcodelibrary.level1 + td1.bqcodelibrary.level2 + td1.bqcodelibrary.level3;
    //                     // var asterisks = desc.split('*');
    //                     asterisksEntries.forEach(as => {
    //                         if(work != null && work.includes("*"))
    //                             work = work.replace("*", as);
    //                         else if (level1 != null && level1.includes("*"))
    //                             level1 = level1.replace("*", as);
    //                         else if (level2 != null && level2.includes("*"))
    //                             level2 = level2.replace("*", as);
    //                         else if (level3 != null && level3.includes("*"))
    //                            level3 = level3.replace("*", as);
    //                     });



    //               }
    //               if(specification != null){
    //                     switch (td1.bqcodelibrary.specification) {
    //                         case 0:
    //                             work = work + specification;
    //                             break;
    //                         case 1:
    //                             level1 = level1 + specification;
    //                             break;
    //                         case 2:
    //                              level2 = level2 + specification;
    //                             break;
    //                         case 3:
    //                             level3 = level3 + specification;
    //                             break;
    //                         }
    //                }else{
    //                   specification = "";
    //                }
    //                if(other != null && other != ""){
    //                    if(level3 != null && level3 != ""){
    //                        level3 = level3 + other;
    //                    }
    //                   else if(level2 != null && level2 != ""){
    //                          level2 = level2 + other;
    //                    }else{
    //                         level1 = level1 + other;
    //                    }
    //                }else{
    //                    other = "";
    //                }

    //             //     if(asterisk.length > 255){
    //             //     //   var newother =  other.substring(0, other.length/4) + '  </br>  ' + other.substring(other.length/4,other.length/2) + ' </br>  '+ other.substring(other.length/2,other.length)

    //             //      errormsg.push('Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ asterisk +'  </br> exceed 255 characters limitation. Please, correct the Asterisk or specification or BQ_code within library')
    //             //   }
    //               if(other.length > 400){
    //                 //   var newother =  other.substring(0, other.length/4) + '  </br>  ' + other.substring(other.length/4,other.length/2) + ' </br>  '+ other.substring(other.length/2,other.length)

    //                  errormsg.push('<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ other +'  </br> exceed 400 characters limitation. Please, correct the Asterisk or specification or BQ_code within library</p>')
    //               }
    //                if(work.length > 400){
    //                     //  var newother =  work.substring(0, work.length/4) + '  </br>  ' + work.substring(work.length/4,work.length/2) + ' </br>  '+ work.substring(work.length/2,work.length)


    //                  errormsg.push('<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ work  +'  </br> exceed 400 characters limitation. Please, correct the Asterisk or specification or BQ_code within library</p>')
    //               }
    //               if(level1.length > 400){
    //                  errormsg.push('<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ level1 +'</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or BQ_code within library</p>')
    //               }
    //                if(level2.length > 400){
    //                  errormsg.push('<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ level2 +'</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or BQ_code within library</p>')
    //               }
    //                if(level3.length > 400){
    //                  errormsg.push('<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ level3 +'</br> exceed 400 characters limitation. Please, correct the  Asterisk or specification or  BQ_code within library</p>')
    //               }
    //             }
    //             var tdasterisk = td1.asterisk ? td1.asterisk : "";
    //             var tdspecification = td1.specification ? td1.specification : "";
    //             if(tdasterisk.length + tdspecification.length > 400){
    //                 errormsg.push("<p style='word-wrap: break-word;'>Your data within 'Specification + Asterisk' column of row-"+td1.rowno +" in "+ t1.filename +" file </br> exceed 400 characters limitation. Please, correct the Specification or Asterisk.</p>")
    //                 //  errormsg.push('<p style="word-wrap: break-word;">Your data within '+ t1.filename +','+td1.rowno +', '+ td1.bqcodelibrary.bqcode +','+ work  +'  </br> exceed 255 characters limitation. Please, correct the Asterisk or specification or BQ_code within library</p>')
    //             }
    //             });
    //     });

    //     return errormsg;
    // },
            warnerror: function (warncheck) {
                this.warncheck = warncheck;
                if (this.warncheck) {
                    this.savetoproject(false);
                }
            },

            moveusinghyperlink(event, ids) {
                ids.forEach(id => {
                    const element = document.querySelector(`#${id.rowcellid}`);
                    if (element) {
                        const tableRow = element.closest('.el-table__row');
                        if (tableRow) {
                            tableRow.style.backgroundColor = '';
                        }
                    }
                });

                let $table = this.$refs.pa;
                let isexpand = this.expandedrows.find(x => x == this.detailform.projectamoses[event.fileindex].id);
                if ($table != undefined && isexpand == undefined) {
                    this.expandedrows = [];
                    $table.toggleRowExpansion(this.detailform.projectamoses[event.fileindex]);
                    if (this.viewmode) {
                        this.showamosid = this.detailform.projectamoses[event.fileindex].id;
                    }
                }

                if (this.detailform.projectamoses[event.fileindex].page != event.page) {
                    this.detailform.projectamoses[event.fileindex].page = event.page;
                    this.detailform.projectamoses[event.fileindex].currentpage = event.page;
                    this.detailform.projectamoses = JSON.parse(JSON.stringify(this.detailform.projectamoses));
                }

                const rowElement = document.querySelector(`#${event.rowcellid}`);
                if (rowElement) {
                    const tableRow = rowElement.closest('.el-table__row');
                    if (tableRow) {
                        tableRow.style.backgroundColor = 'rgb(255, 214, 214)';
                        const scrollableContainer = tableRow.closest('.el-table__body-wrapper');
                        if (scrollableContainer) {
                            const rowRect = tableRow.getBoundingClientRect();
                            const containerRect = scrollableContainer.getBoundingClientRect();
                            const offsetTop = rowRect.top - containerRect.top;
                            const containerScrollTop = scrollableContainer.scrollTop;
                            const targetScrollTop = containerScrollTop + offsetTop - (containerRect.height / 2) + (rowRect.height / 2);
                            scrollableContainer.scrollTo({
                                top: targetScrollTop,
                                behavior: 'smooth'
                            });
                        }
                    }
                }
            },

            getprerows(startIndex, details) {
                let ret = [];
                if (details[startIndex].predicate !== "CH") {
                    return ret;
                }

                let endIndex = -1;
                for (let i = startIndex + 1; i < details.length; i++) {
                    if (details[i].predicate === "CH") {
                        endIndex = i;
                        break;
                    }
                }

                if (endIndex === -1) {
                    endIndex = details.length;
                }

                for (let i = startIndex + 1; i < endIndex; i++) {
                    if (details[i].predicate === "Pre") {
                        ret.push(details[i]);
                    }
                }

                return ret;
            },

            concatebqcode(row) {
                var str = "".concat(row.work, row.level1, row.level2, row.level3);
                let length = str.split('*').length - 1;
                return length;
            },

            removeBackgroundColor() {
                const tableRows = this.$refs.detailtable.$el.querySelectorAll('.el-table__row');
                tableRows.forEach(row => {
                    if (row.style.backgroundColor === 'rgb(255, 214, 214)') {
                        row.style.backgroundColor = ''; // Remove the background color
                    }
                });
            },

            async movecheckingpage(name) {
                let projectid = this.detailform.id;
                let partid = this.detailform.projectpart.id == null ? 0 : this.detailform.projectpart.id;
                let sectionid = this.detailform.section.id;

                let params = new URLSearchParams();
                params.append('projectid', projectid);
                params.append('partid', partid);
                params.append('sectionid', sectionid);
                let errormessage = "";

                if (name == 'checklistsection') {
                    var response = await this.$training.checksectionforchecklist();
                    let isexist = response.find(x => x.nrmelementid == this.detailform.section.section && x.formtype == this.detailform.library);
                    if (isexist == undefined) {
                        errormessage = "This NRM section is not available on the Checklist-Sections page.";
                    }
                }

                if (errormessage != "") {
                    this.$notify({
                        type: 'warning',
                        title: 'Invalid Data',
                        message: errormessage,
                        duration: 15000
                    });
                } else {
                    let url = `/${name}?${params.toString()}`;
                    window.open(url, '_blank');
                }
            },

            // addingextratag(str){

            // },

            validateamos(callback) {
                return new Promise((resolve, reject) => {
                // this.amossaving = false;
                if (this.detailform.projectamoses.length > 0) {
                    var reqmsg = [];
                    var warnmsg = [];
                    var nrm_mct = [];
                    var column_nrm_mct = [];
                    var ast_spe = [];
                    var column_predicate = [];
                    var wckey = 0;
                    let pageindex = 0;

                    //  var customdataarr = [];
                    //     customdataarr.push(...this.detailform.projectamoses);
                    //     reqmsg.push(...this.validdatalength(customdataarr));
                    //    var customdataarr = [];
                    this.detailform.projectamoses.forEach((pa, index) => {
                        pageindex = 0;
                        this.setpagesize(pa,pa.details)
                        for (var i = 0; i < pa.details.length; i++) {
                            var pad = pa.details[i];
                            let newpage = Math.floor(i / pa.pagesize) + 1;
                            if (pageindex > pa.pagesize) {
                                pageindex = 1;
                            }
                            if (pad.predicate == "CH") {
                                wckey++;
                                if (!pad.nrmsortingref) {
                                    reqmsg.push({
                                        page: newpage,
                                        fileindex: index,
                                        ishyperlink: true,
                                        rowcellid: `${this.directionids[7]}_${index}_${pageindex}`,
                                        msg: "Row No : " + pad.rowno + "  NRM Sort code is required",
                                    });
                                }
                                if (!pad.amtemplateid) {
                                    reqmsg.push({
                                        page: newpage,
                                        fileindex: index,
                                        ishyperlink: true,
                                        rowcellid: `${this.directionids[0]}_${index}_${pageindex}`,
                                        msg: "Row No : " + pad.rowno + "  AM_T Name is required",
                                    });
                                }

                                if (pad.nrmsortingref && pad.amtemplate.amtemplatename) {
                                    column_nrm_mct.push(pad.rowno);
                                    nrm_mct.push({ amosid: pa.id, text: pad.amtemplate.amtemplatename + " - " + pad.nrmsortingref });
                                }

                                if (!pad.qty) {
                                    reqmsg.push({
                                        page: newpage,
                                        fileindex: index,
                                        ishyperlink: true,
                                        rowcellid: `${this.directionids[9]}_${index}_${pageindex}`,
                                        msg: "Row No : " + pad.rowno + " Quantities are missing",
                                    });
                                }
                                if (!pad.asterisk) {
                                    let currentprerows = this.getprerows(i, pa.details);
                                    currentprerows.forEach(element => {
                                        let asteriskcount = this.concatebqcode(element.projectbqcode);
                                        if (!element.asterisk && asteriskcount > 0) {
                                            warnmsg.push({
                                                page: newpage,
                                                fileindex: index,
                                                ishyperlink: true,
                                                rowcellid: `${this.rowhightlightids[1]}_${index}_${pageindex}`,
                                                msg: "Row No : " + element.rowno + "  At least one Asterisk is usually required",
                                            });
                                        }
                                    });
                                }
                                if (!pad.specification) {
                                    warnmsg.push({
                                        page: newpage,
                                        fileindex: index,
                                        ishyperlink: true,
                                        rowcellid: `${this.rowhightlightids[1]}_${index}_${pageindex}`,
                                        msg: "Row No : " + pad.rowno + "  At least one specification reference is usually required",
                                    });
                                }
                            }

                            if (pad.predicate == "Pre") {
                                if(pad.bqcodelibrary != undefined){
                                    var obj = {page: newpage,
                                            fileindex: index,
                                            ishyperlink: true,
                                            rowcellid: `${this.directionids[1]}_${index}_${pageindex}`
                                        };
                                    var msg = this.validdatalengthpri(pad,pa,obj);
                                    reqmsg.push(...msg);
                                }
                                if (pad.omitfromlibrary == false) {
                                    if (!pad.bqcodelibrary.bqcode) {
                                        reqmsg.push({
                                            page: newpage,
                                            fileindex: index,
                                            ishyperlink: true,
                                            rowcellid: `${this.directionids[4]}_${index}_${pageindex}`,
                                            msg: `Row No : ${pad.rowno} BQ_Code is required`,
                                        });
                                    }
                                    else {
                                        //check number of asterisk match
                                        if (pad.asterisk && !pad.edit) {
                                            var asterisksEntries = pad.asterisk.split('^');
                                            let asteriskcount = this.concatebqcode(pad.projectbqcode);
                                            if (asterisksEntries.length != asteriskcount) {
                                                warnmsg.push({
                                                    page: newpage,
                                                    fileindex: index,
                                                    ishyperlink: true,
                                                    rowcellid: `${this.rowhightlightids[5]}_${index}_${pageindex}`,
                                                    msg: "Row No : " + pad.rowno + " The number of BQ_Code asterisks does not appear to match with the Asterisks items added",
                                                });
                                            }
                                        }
                                    }
                                    if (!pad.qty) {
                                        reqmsg.push({
                                            page: newpage,
                                            fileindex: index,
                                            ishyperlink: true,
                                            rowcellid: `${this.directionids[9]}_${index}_${pageindex}`,
                                            msg: "Row No : " + pad.rowno + " Quantities are missing",
                                        });
                                    }
                                    if (!pad.edit) {
                                        if (!pad.asterisk) {
                                            if (pad.projectbqcode.bqcode) {
                                                let asteriskcount = this.concatebqcode(pad.projectbqcode);
                                                if (asteriskcount > 0) {
                                                    warnmsg.push({
                                                        page: newpage,
                                                        fileindex: index,
                                                        ishyperlink: true,
                                                        rowcellid: `${this.rowhightlightids[3]}_${index}_${pageindex}`,
                                                        msg: "Row No : " + pad.rowno + " Asterisk data for this BQ_Code is usually required",
                                                    });
                                                }
                                            }
                                        }
                                        if (!pad.specification) {
                                            warnmsg.push({
                                                page: newpage,
                                                fileindex: index,
                                                ishyperlink: true,
                                                rowcellid: `${this.rowhightlightids[2]}_${index}_${pageindex}`,
                                                msg: "Row No : " + pad.rowno + " Specification data for this BQ_Code is usually required",
                                            });
                                        }

                                        if (pad.asterisk && pad.specification) {
                                            column_predicate.push(pad.rowno);
                                            ast_spe.push({ key: wckey, value: pad.asterisk + "-" + pad.specification });
                                        }
                                    }
                                }
                            }

                            if (pad.predicate == "Edit") {
                                //check number of asterisk match
                                var temppad = pa.details[i - 1];
                                if (temppad.bqcodelibrary.bqcode && pad.asterisk) {
                                    var obj = {
                                        page: newpage,
                                        fileindex: index,
                                        ishyperlink: true,
                                        rowcellid: `${this.directionids[3]}_${index}_${pageindex}`
                                    };
                                    var msg = this.validdatalengthpri(pad,pa,obj,temppad.projectbqcode);
                                    reqmsg.push(...msg);
                                    let asterisksEntries = pad.asterisk.split('^');
                                    let asteriskcount = this.concatebqcode(temppad.projectbqcode);
                                    if (asterisksEntries.length != asteriskcount) {
                                        warnmsg.push({
                                            page: newpage,
                                            fileindex: index,
                                            ishyperlink: true,
                                            rowcellid: `${this.directionids[3]}_${index}_${pageindex}`,
                                            msg: "Edit Row No : " + pad.rowno + " The number of BQ_Code asterisks does not appear to match with the Asterisks items added",
                                        });
                                    }
                                }
                                if (!pad.asterisk) {
                                    warnmsg.push({
                                        page: newpage,
                                        fileindex: index,
                                        ishyperlink: true,
                                        rowcellid: `${this.directionids[3]}_${index}_${pageindex}`,
                                        msg: "Edit Row No : " + pad.rowno + " Asterisk data for this BQ_Code is usually required",
                                    });
                                }
                                if (!pad.specification) {
                                    warnmsg.push({
                                        page: newpage,
                                        fileindex: index,
                                        ishyperlink: true,
                                        rowcellid: `${this.rowhightlightids[4]}_${index}_${pageindex}`,
                                        msg: "Edit Row No : " + pad.rowno + " Specification data for this BQ_Code is usually required",
                                    });
                                }

                                if (pad.asterisk && pad.specification) {
                                    column_predicate.push(pad.rowno);
                                    ast_spe.push({ key: wckey, value: pad.asterisk + "-" + pad.specification });
                                }
                            }
                            pageindex++;
                        }

                        // for (var i = 0; i < ast_spe.length; i++) {
                        //     var a = ast_spe[i];
                        //     for (var j = i + 1; j < ast_spe.length; j++) {
                        //         var b = ast_spe[j];
                        //         if (a.key == b.key && a.value == b.value) {
                        //             warnmsg.push("Work Classification of Row No : " + column_predicate[i] + " and " + column_predicate[j] + " are duplicate " + a.value);
                        //         }
                        //     }
                        // }
                        // ast_spe = [];

                        //if (msg.length >= 1 && msglen != msg.length) {
                        //    if (msglen == 0)
                        //        msg.unshift("File Name : " + pa.filename);
                        //    else
                        //        msg.splice(msglen, 0, "File Name : " + pa.filename);
                        //    msglen = msg.length;
                        //}
                    });
                    // for (var i = 0; i < nrm_mct.length; i++) {
                    //     var a = nrm_mct[i];
                    //     for (var j = i + 1; j < nrm_mct.length; j++) {
                    //         var b = nrm_mct[j];
                    //         if (a.text == b.text) {
                    //             reqmsg.push("Row No : " + column_nrm_mct[i] + " and " + column_nrm_mct[j] + " are duplicate " + a.text);
                    //         }
                    //     }
                    // }
                    let headerbutton = true;
                    let data = {
                        id: this.detailform.section.id
                    };

                    this.$training.verifycheckingpage(data).then(isverify => {

                        if (!isverify.rwc || !isverify.cqc || !isverify.signoffsection || !isverify.checklistsection) {
                            headerbutton = false; // This method is created to check buttons located in the header of a custom dialog box.
                        }

                        if (reqmsg.length > 0) {
                            this.errorobject = {
                                message: reqmsg, type: "required", objecttype: 'array_of_objects', visible: true, sourcereq: "You cannot save this data set to the project page (and bill these activities)\x3Cbr /> until you have properly completed the AMOS page by entering the correct<br /> data(as set out below):"
                            };
                            this.validationerror = true;
                        }

                        else {

                            if (warnmsg.length > 0 || !headerbutton) {
                                this.errorobject = {
                                    message: warnmsg, type: "warning", objecttype: 'array_of_objects', visible: true, sourcewarn: "The data set you are trying to save to the project page appears to be incomplete.<br /> Please check the entries listed below.When you are satisfied you can use the<br /> 'Save  to Project' or tick the 'User Has Checked' box to bill the activities"
                                };
                                this.validationerror = true;
                            }

                            else {
                                callback();
                                resolve("Validation successful");
                            }
                        }
                    });
                }
                else {
                    var style = '<style> .el-message-box {width:80%;} </style>'
                    this.$alert("No AMOS data Available" + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                    reject(new Error("No AMOS data available"));
                }
               });
            },

            //closedialogue: function () {
            //    this.projectbqcode = false;
            //    //if (this.detailform.changed) {
            //    //    this.save();
            //    //}
            //    //else {
            //    //    this.refresh();
            //    //}
            //},

            addAttachment(file, fileList) {
                if (file.file.size > 10000 * 1024) {
                    this.$alert("File size must be less than 10 mb", "File Size exceeds the limit");
                    return;
                }
                this.getpageactionmessage("AMOS","import",this.detailform);  
                this.attachments = [file.file];
                var ext = file.file.name.substr(file.file.name.length - 4).toLowerCase();
                if (ext == "xlsx") {
                    var projectschedule = { projectid: this.detailform.id, xml: "" };
                    this.detailform.projectschedule = projectschedule;
                    var fileReader = new FileReader();
                    projectschedule.name = file.file.name;
                    fileReader.onload = function (e) {
                        var arrayBuffer = fileReader.result;
                        //projectschedule.xlsx = new Uint8Array(arrayBuffer);
                        //projectschedule.xlsx = new Int8Array(arrayBuffer);
                        projectschedule.xlsx = arrayBuffer;

                    }
                    //fileReader.readAsArrayBuffer(this.attachments[0]);
                    //fileReader.readAsText(this.attachments[0]);
                    //fileReader.readAsBinaryString(this.attachments[0]);
                    fileReader.readAsDataURL(this.attachments[0]);
                    this.detailform.projectxml = null;
                }
                this.changed(null, null);

                if (this.detailform.projectamoses.length > 0) {
                    //this.detailform.replaceid = null;
                    var namematch = false;
                    this.detailform.projectamoses.forEach((pa) => {
                        if (pa.filename == this.detailform.projectschedule.name) {
                            namematch = true;
                        }
                    });
                    if (namematch) {
                        this.attachments = [];
                        this.detailform.projectxml = null;
                        this.detailform.projectschedule = null;
                        this.$alert("File name already exists, please import file with different name or another file.", "Warning", { type: 'warning' });
                        return;
                    }
                    // else {
                    //     this.scheduledialog = true;
                    // }
                }
                // this.showloading();
                this.$set(this,'isimport',true);
                this.importAmosFromXLSX(file, fileList);
            },

            deleteAttachment() {
                 this.$set(this,'isimport',false);
                this.attachments = [];
                this.detailform.projectxml = null;
                this.detailform.projectschedule = null;
                this.changed(null, null);

                this.scheduledialog = false;
                this.detailform.replace = false;
                this.detailform.replaceid = 0;
                this.replaceoraddradio = false;
            },

            autosave: function () {
                //auto save every 10 minutes
                if (this.$route.path != "/projectpage") {
                    window.clearInterval(this.interval);
                    this.interval = null;
                    return;
                }
                if (this.detailform.changed) {
                    if (!this.canedit(false)) {
                        this.save();
                    }
                }
            },

            changed: function (template, row) {
                if (row != null) {
                    row.edited = true;
                }
                this.detailform.changed = true;
            },

            editchanged: function (pa, pad, index, column) {
                index = pa.details.findIndex(p => p.id == pad.id);
                if (pad.edit == true && pa.details[index + 1] && pa.details[index + 1].predicate == "Edit") {
                    return;
                }
                if (!pad.edit && pad.predicate == "Pre" && column == "edit") {
                    for (var i = index - 1; i >= 0; i--) {
                        var pad1 = pa.details[i];
                        var pre = pad1.predicate || "";

                        if (pre == "CH") {
                            var q = pad1.qty;
                            pad.qty = parseFloat(pad["col" + q]);
                            if (isNaN(pad.qty))
                                pad.qty = 0;

                            if (pad1.lstasterisk.length >= 1) {
                                var a = pad1.lstasterisk;
                                var lastast = a[a.length - 1];
                                var as = [];

                                if (pad1.asteriskhome != '') {
                                    as.push(pad1.asteriskhome);
                                }
                                for (var ast of a) {
                                    as.push(pad["col" + ast.key]);
                                    if (ast.text == "" && lastast.key != ast.key)
                                        as.push("; ");
                                    else
                                        as.push(ast.text);
                                }
                                pad.asterisk = as.join("");
                            }
                            else {
                                pad.asterisk = null;
                            }

                            if (pad1.lstspecification.length >= 1) {
                                var s = pad1.lstspecification;
                                var lastsp = s[s.length - 1];
                                var sp = [];
                                if (pad1.specificationhome != '') {
                                    sp.push(pad1.specificationhome);
                                }
                                for (var spe of s) {
                                    sp.push(pad["col" + spe.key]);
                                    if (spe.text == "" && lastsp.key != spe.key)
                                        sp.push("; ");
                                    else
                                        sp.push(spe.text);
                                }
                                pad.specification = sp.join("");
                            }
                            else {
                                pad.specification = null;
                            }

                            if (pad1.lstendnotes.length >= 1) {
                                let e = pad1.lstendnotes;
                                var lastendnote = e[e.length - 1];
                                var endn = [];

                                if (pad1.endnoteshome != '') {
                                    endn.push(pad1.endnoteshome);
                                }

                                for (var ed of e) {
                                    endn.push(pad["col" + ed.key]);
                                    if (ed.text == "" && lastendnote.key != ed.key)
                                        endn.push("; ");
                                    else
                                        endn.push(ed.text);
                                }
                                pad.endnotes = endn.join("");
                            }
                            else {
                                pad.endnotes = null;
                            }
                            // pad.endnotes = pad1.endnotes;
                            break;
                        }
                    }
                    pa.details.splice(index + 1, 1);
                }
                else {
                    var add = {
                        predicate: "Edit",
                        rowno: pad.rowno,
                        asterisk: pad.asterisk,
                        specification: pad.specification,
                        endnotes: pad.endnotes
                    };
                    pa.details.splice(index + 1, 0, add);
                }
            },

            beforewindowunload: function (e) {
                //Prevent accidently close/reload window
                if (this.detailform.changed) {
                    //return window.confirm('Do you really want to leave? you have unsaved changes!');
                    return "Do you really want to leave ? you have unsaved changes!"
                }
            },

            closedialogue: function () {
                this.projectbqcode = false;
                this.projecttemplate = false;
                if (this.detailform.changed) {
                    this.save();
                }
                else {
                    this.refresh();
                }
            },

            updateprojectbqcode(event) {
                let obj = { bqcodelibraryid: event, projectsectionid: this.detailform.section.id };
                this.getprojectBQCode(obj).then((rest) => {
                    let newprojectbqcode = rest;
                    this.detailform.projectamoses.forEach((pa, index) => {
                        for (var i = 0; i < pa.details.length; i++) {
                            let element = pa.details[i];
                            if (newprojectbqcode != null && newprojectbqcode.bqcodelibraryid) {
                                if (element.bqcodelibraryid == newprojectbqcode.bqcodelibraryid && this.detailform.section.id == newprojectbqcode.projectsectionid) {
                                    element.projectbqcode = newprojectbqcode;
                                }
                            }
                        }
                    });
                });
            },

            closeamtemplatedialogue: function () {
                this.amtemplateedit = false;
                //this.refresh();
            },

            handleResize: function (event) {
                this.height = window.innerHeight;
            },

            appendTip: function (h, { column, $index }) {
                // Function(h, { column, $index })
                var content = "Edit Row";
                //if (column.property == "rowno") {
                //    content = "Row No";
                //}
                //else if (column.label == "RD") {
                //    content = "Row Designation";
                //}
                //else {
                //    return column.label;
                //}

                return h("span", [
                    column.label,
                    h(
                        "el-popover",
                        {
                            props: {
                                placement: "top",
                                // title: "hello",
                                // width: "200",
                                trigger: "hover",
                                content: content
                            }
                        },
                        [
                            h(
                                "i",
                                {
                                    slot: "reference",
                                    class: "el-icon-edit",
                                    style: "color:#606266;margin-left:2px;"
                                },
                                ""
                            )
                        ]
                    )
                ]);
            },
            appendTips: function (h, { column, $index }) {
                // Function(h, { column, $index })
                var content = ""
                if (column.property == "rowno") {
                    content = "Row No";
                }
                else if (column.label == "RD") {
                    content = "Row Designation";
                }
                else if (column.label == "AM_T") {
                    content = "Automated Measurement Template";
                }
                else {
                    return column.label;
                }

                return h(
                    "el-popover",
                    {
                        props: {
                            placement: "top",
                            // title: "hello",
                            // width: "200",
                            trigger: "hover",
                        }
                    },
                    [
                        content,
                        h(
                            "span",
                            {
                                slot: "reference",
                            },
                            column.label
                        )
                    ]
                );
            },
            columnwidth: function (pa, column) {
                if(pa.columnwidths == undefined){
                    pa.columnwidths = new Array();
                }
                if(pa.columnwrapper == undefined){
                    pa.columnwrapper = new Array();
                }
                if(pa.columnwrapper[column+"iswrapper"]){
                    return 30;
                }
                if(pa.columnwidths[column+'width'] != undefined){
                    return pa.columnwidths[column+'width'];
                }

                /*
                var col = column.toString();
                var maxwidth = 0;
                
                for (var i = 0; i < pa.details.length; i++) {
                    var row = pa.details[i];
                    var value = row["col" + col];
                    if (value && value.length != 0) {
                        if (value.length > maxwidth && value.length <= 40) {
                            maxwidth = value.length;
                            var str = value;
                        }
                        if (value.length > 40) {
                            maxwidth = 40;
                            var str = value.slice(0, 40);
                            break;
                        }
                    }
                }
                if (maxwidth != 0) {
                    var size = this.measureTextWidth(str);
                    pa.columnwidths[column+'width'] = size + 10; 
                    return pa.columnwidths[column+'width'];
                }
                else
                pa.columnwidths[column+'width'] = 0;
                    return pa.columnwidths[column+'width'];
                    */
            },
            importAmosFromXLSX: function(file, fileList) {
                this.$nextTick(()=>{
                     this.loading = true;
                 })
                var amfile = file.file;
                var projectamos = {details:[]};
                var err = [];

                    const reader = new FileReader();

                    reader.onload = (e) => {
                        /* Parse data */
                        const bstr = e.target.result;
                        const wb = XLSX.read(bstr, { type: 'binary' });
                        /* Get first worksheet */
                        const wsname = wb.SheetNames[0];
                        const ws = wb.Sheets[wsname];
                        /* Convert array of arrays */
                        const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });
                        const exldata = data.filter(n => {
                                var t = n.filter(c => {
                                    if (c && c.toString().trim().length > 0) {
                                        return c;
                                    }
                                });
                                return t.length;
                            }
                        );

                        
                        if (exldata.length >= 2) {
                            var countcolumn = 0; var colfound = false;
                            var maxcolumn = 0; 
                            for (var row = 0; row < exldata.length; row++) {
                                var pad = {}
                                pad.rowno = row + 1;
                                const objrow = exldata[row];
                                if (objrow.length >= 21) {
                                    colfound = false;
                                    err.push("More than 20 columns not accepted");
                                    break;
                                }
                                var found = false;
                                   
                                objrow.forEach((column, colindex) => {
                                    colindex = colindex + 1;
                                    pad["col" + colindex] = column.toString();

                                    var value = pad["col" + colindex];

                                    if (value && value.toString().trim()) {
                                        value = value + "";
                                        found = true;
                                        if (found && colindex > maxcolumn) {
                                            maxcolumn = colindex;
                                        }
                                        if (value.length <= 390) {
                                            for (let k = 0; k < value.length; k++) {
                                                if (value.charCodeAt(k) > 127) {
                                                    err.push(`Invalid input value <span style='color:red;'>${value[k]}</span>
                                                                for Row No: ${pad.rowno} Special character at ${k + 1} <br/>`);
                                                }
                                            }
                                        } else {
                                            err.push(`Data too long for column ${colindex} at row ${pad.rowno}`);
                                        }

                                        if (objrow.length > countcolumn) {
                                            countcolumn = objrow.length;
                                            colfound = countcolumn <= 2 ? true : false;
                                        }
                                    }
                                });
                                

                                // for (let column = 0; column < objrow.length; column++) {
                                //     pad["col" + (column + 1)] = objrow[column].toString();

                                //     var value = pad["col" + (column + 1)];

                                //     if (value && value.toString().trim()) {
                                //         value = value + "";
                                //         if (value.length <= 255) {
                                //             for (let k = 0; k < value.length; k++) {
                                //                 if (value.charCodeAt(k) > 127) {
                                //                     err.push(`Invalid input value <span style='color:red;'>${value[k]}</span>
                                //                                 for Row No: ${pad.rowno} Special character at ${k + 1} <br/>`);
                                //                 }
                                //             }
                                //         } else {
                                //             err.push(`Data too long for column ${column + 1} at row ${pad.rowno}`);
                                //         }

                                //         if (objrow.length > countcolumn) {
                                //             countcolumn = objrow.length;
                                //             colfound = countcolumn <= 2 ? true : false;
                                //         }
                                //     }
                                // }
                                if (objrow.length == 1) {
                                    pad.predicate = "SN";
                                }
                                projectamos.details.push(pad);
                            }
                            if (colfound && countcolumn <= 2) {
                                err.unshift("Minimum 3 Columns required");
                            }
                        } else {
                            err.push("Minimum 2 rows required");
                        }
                        if (err.length > 0) {
                            // this.hideloading();
                            this.$nextTick(()=>{
                                    this.loading = false;
                                })
                            err.unshift(`<b>For Information:-<b/> <br/>  <i>* The file to be imported must have at least 2 rows and 3 columns.<br/>
                                        * The number of columns in the imported file should not exceed 20.<br/> * Can't replace file with same name.<br/>
                                        </i> <br/>`);
                                        // * No column should contain more than 390 characters.<br/>* Duplicate rows are not allowed.
                            this.errorobject = { message: err, type: "required", visible: true };
                            this.validationerror = true;
                            this.deleteAttachment();
                        } else {
                                if(this.detailform.RDCHview.ExcludeDefault.regularexpression
                                   || this.detailform.RDCHview.ExcludePart.regularexpression
                                   || this.detailform.RDCHview.IncludeDefault.regularexpression
                                   || this.detailform.RDCHview.IncludePart.regularexpression ){

                                    this.maxcolumn = maxcolumn;
                                    this.detailform.importedamosfile = projectamos;
                                    this.checkamosheader()

                                   }else{
                                    this.set_C_H = true;  
                                    this.maxcolumn = maxcolumn;
                                    this.detailform.importedamosfile = projectamos;
                                    this.$nextTick(()=>{
                                           this.loading = false;
                                       })
                                   }
                            //  this.checkamosheader()


                            // if(this.detailform.achs && this.detailform.achs.length <= 0){
                            //  this.set_C_H = true;  
                            //  this.maxcolumn = maxcolumn;
                            //  this.detailform.importedamosfile = projectamos;
                            //  this.hideloading();
                            // }else{
                            //     this.detailform.importedamosfile = projectamos;
                            //     this.maxcolumn = maxcolumn;
                            //     this.checkamosheader()
                            //     this.hideloading();
                            // }

                        }
                    }
                    reader.readAsBinaryString(amfile);
            },
            RG_Match(inc_pattern,exc_pattern,value){
                if(inc_pattern != null){
                    inc_pattern = new  RegExp(inc_pattern,"i");
                }
                if(exc_pattern != null){
                    exc_pattern = new  RegExp(exc_pattern,"i");
                }
                if(inc_pattern == null &&  exc_pattern != null){
                    return  !exc_pattern.test(value.trim()); //  Regex.IsMatch(value.Trim().ToLower(),exc_pattern);
                }
                if(inc_pattern != null &&  exc_pattern == null){
                    return  inc_pattern.test(value.trim()); //Regex.IsMatch(value.Trim().ToLower(),inc_pattern);
                }
                if(inc_pattern == null &&  exc_pattern == null){
                    return false;
                }
                if(inc_pattern != null &&  exc_pattern != null){
                    return  inc_pattern.test(value.trim()) && !exc_pattern.test(value.trim()) // && Regex.IsMatch(value.Trim().ToLower(),inc_pattern) && !Regex.IsMatch(value.Trim().ToLower(),exc_pattern);  
                }
                return false;
            },
            checkamosheader(){
                var include_project_Regex = null,
                    exclude_project_Regex = null,
                    include_default_Regex = null,
                    exclude_default_Regex = null;
                    // const re = /\s+/;
                if(this.detailform && this.detailform.RDCHview){
                    if(this.detailform.RDCHview.ExcludeDefault.regularexpression ){
                        exclude_default_Regex = this.detailform.RDCHview.ExcludeDefault.regularexpression;//.replace(re, "");
                    }
                    if(this.detailform.RDCHview.ExcludePart.regularexpression){
                        exclude_project_Regex = this.detailform.RDCHview.ExcludePart.regularexpression;//.replace(re, "");
                    }
                    if(this.detailform.RDCHview.IncludeDefault.regularexpression){
                        include_default_Regex = this.detailform.RDCHview.IncludeDefault.regularexpression;//.replace(re, "");
                    }
                    if(this.detailform.RDCHview.IncludePart.regularexpression){
                        include_project_Regex = this.detailform.RDCHview.IncludePart.regularexpression;//.replace(re, "");
                    }
                }

                    var ismatchheader = false;
                    this.detailform.importedamosfile.details.forEach(e => {
                            var istowmatch = 0;
                            for (let index = 1; index <= this.maxcolumn; index++) {
                                const element = e["col"+index];
                                var ismatch = false;
                                if (element && element.toString().trim()) {
                                    if( this.RG_Match(include_project_Regex,exclude_project_Regex,element))
                                    {
                                        e["col"+index+'style'] = 'black_cell_style'; 
                                        ismatch = true;
                                        istowmatch++;
                                    }
                                    else if (!ismatch && this.RG_Match(include_default_Regex,exclude_default_Regex,element) )
                                    {
                                        e["col"+index+'style'] = 'black_cell_style'; 
                                        istowmatch++;
                                    }
                                    // var isfind = this.binarySearch('headers',element,this.detailform.achs,false) //this.detailform.achs.find(x => x.headers == element);
                                    // if(isfind > -1)
                                    // {
                                    //     istowmatch++;
                                    // }
                                }   
                            }
                            if(istowmatch > 2){
                                e.predicate = "CH";
                                // e.isch = true;
                                ismatchheader = true;
                            }
                    });
                    this.set_C_H = true;
                    this.$nextTick(()=>{
                       this.loading = false;
                   })
            },
            setamosheader(data) {
                if(this.detailform.RDCHview.IncludePart.regularexpression != null){
                    if(this.detailform.RDCHview.IncludePart.regularexpression.charAt(0) == "|"){
                        this.detailform.RDCHview.IncludePart.regularexpression = this.detailform.RDCHview.IncludePart.regularexpression.slice(1);
                    }
                }
                // if (data) {
                //     var narray = [];
                //     this.detailform.importedamosfile.details.forEach(e => {
                //         if (e.isch) {
                //             for (let index = 0; index < this.maxcolumn; index++) {
                //                 const element = e["col" + index];
                //                 if (element &&  element.toString().trim()) {
                //                     var isfind = narray.find(x => x.headers == element);
                //                     if (!isfind) {
                //                         var obj = {
                //                             id: 0,
                //                             sectionid: 0,
                //                             library: "",
                //                             headers: element
                //                         }
                //                         narray.push(obj);
                //                     }
                //                 }

                //             }
                //         }
                //     });
                //     this.detailform.importedamosfile.ach = narray;
                // }
                this.getamosheader();
            },
            getamosheader: function() {
                this.$http.post("amos/setamoscolumnheaders", this.detailform)
                .then(response => {
                    this.set_C_H = false;  
                    this.detailform.importedamosfile = response.data;
                    // var err = [];
                    // this.detailform.importedamosfile.details.forEach(ch => {
                    //     Object.keys(ch).forEach(k => ch[k] = k.includes("col") && ch[k] === null ? "" : ch[k])
                    //     if (ch.predicate != "CH") {
                    //         var isch = false;6
                    //         if (ch.predicate == "Pre") {
                    //             Object.entries(ch).filter((x)=>{
                    //                 if (x[0].includes("col")) {
                    //                     if (!isNaN(parseFloat(x[1]))) {
                    //                         isch = true;
                    //                     }
                    //                 }
                    //             }).length <= 0
                    //         } else if (ch.predicate == "SN") {
                    //             isch = true;
                    //         }

                    //         var duplicates = this.detailform.importedamosfile.details.filter(iad =>
                    //             isch == true && iad.rowno != ch.rowno &&
                    //             iad.col1 == ch.col1 && iad.col2 == ch.col2 && iad.col3 == ch.col3 && iad.col4 == ch.col4 && iad.col5 == ch.col5 &&
                    //             iad.col6 == ch.col6 && iad.col7 == ch.col7 && iad.col8 == ch.col8 && iad.col9 == ch.col9 && iad.col10 == ch.col10 &&
                    //             iad.col11 == ch.col11 && iad.col12 == ch.col12 && iad.col13 == ch.col13 && iad.col14 == ch.col14 && iad.col15 == ch.col15 &&
                    //             iad.col16 == ch.col16 && iad.col17 == ch.col17 && iad.col18 == ch.col18 && iad.col19 == ch.col19 && iad.col20 == ch.col20
                    //         );

                    //         if (duplicates.length > 0) {
                    //             err.push("Row duplicates:-" + ch.rowno);
                    //         }
                    //     }
                    // });
                    // if (err.length > 0) {
                    //     err.unshift(`<b>For Information:-<b/> <br/>  <i>* The file to be imported must have at least 3 rows and 3 columns.<br/>
                    //                     * The number of columns in the imported file should not exceed 20.<br/> * Can't replace file with same name.<br/>
                    //                     * No column should contain more than 255 characters.<br/>* Duplicate rows are not allowed.</i> <br/><br/>`);
                    //     this.errorobject = { message: err, type: "required", visible: true };
                    //     this.validationerror = true;
                    //     this.deleteAttachment();
                    // } else
                    if (this.detailform.projectamoses.length > 0) {
                        this.detailform.replace == undefined ? this.detailform.replace = true : this.detailform;
                        this.replaceoraddradio = this.detailform.replace;
                        this.scheduledialog = true;

                    }
                     this.$set(this,'isimport',false);
                })
                .catch(err => {
                      this.$set(this,'isimport',false);
                    this.$bus.$emit('error', err);
                })
            },
            radiochanged: function(event) {
                this.detailform.replace = event;
            },
            
            validlengthonsave(allimporteddata) {
                var errmsg = [];
                let pageindex = 0;
                allimporteddata.forEach((pa, index) => {
                    pageindex = 0;
                    this.setpagesize(pa, pa.details);
                    for (var i = 0; i < pa.details.length; i++) {
                        let pad = pa.details[i];
                        let newpage = Math.floor(i / pa.pagesize) + 1;
                        if (pageindex > pa.pagesize) {
                            pageindex = 1;
                        }
                        if ((pad.predicate == "Pre" || pad.predicate == "Edit") && pad.edit != true) {
                            var specification = pad.specification ? pad.specification : "";
                            var asterisk = pad.asterisk ? pad.asterisk : "";
                            var endnotes = pad.endnotes ? pad.endnotes : "";

                            // if (specification.length + asterisk.length > 400) {
                            //     errmsg.push({
                            //         page: newpage,
                            //         fileindex: index,
                            //         ishyperlink: true,
                            //         rowcellid: `${this.rowhightlightids[3]}_${index}_${pageindex}`,
                            //         msg: "Your data within 'Specification + Asterisk' column of row-" + pad.rowno + " in " + pa.filename + " file </br> exceed 400 characters limitation. Please, correct the Specification or Asterisk.",
                            //     });
                            // }

                            if (endnotes.length > 255) {
                                errmsg.push({
                                    page: newpage,
                                    fileindex: index,
                                    ishyperlink: true,
                                    rowcellid: `${this.rowhightlightids[3]}_${index}_${pageindex}`,
                                    msg: "Your data within 'End Notes' column of row-" + pad.rowno + " in " + pa.filename + " file </br> exceed 255 characters limitation. Please, correct the End Notes.",
                                });
                            }

                            // if (specification.length  > 390) {
                            //     errmsg.push("<p style='word-wrap: break-word;'>Your data within 'Specification' column of row-"+pad.rowno +" in "+ pa.filename +" file </br> exceed 390 characters limitation. Please, correct the Specification.</p>")
                            // }
                            // if (asterisk.length > 255) {
                            //     errmsg.push("<p style='word-wrap: break-word;'>Your data within 'Asterisk' column of row-"+pad.rowno +" in "+ pa.filename +" file </br> exceed 255 characters limitation. Please, correct the Asterisk.</p>")
                            // }
                            // if (pad.asterisk && pad.asterisk.length > 255) {
                            //     errmsg.push(`Data too long for 'Asterisk' at row ${pad.rowno}`);
                            // }
                        }
                        pageindex++;
                    }
                });

                if (errmsg.length > 0) {
                    let headingsource = `<b>For Information:-<b/><br/><i>
                                            * The file to be imported must have at least 3 rows and 3 columns.<br/>
                                            * The number of columns in the imported file should not exceed 20.<br/> 
                                            * Can't replace file with same name.<br/> </i>`;
                    this.errorobject = { message: errmsg, type: "required", visible: true, objecttype: 'array_of_objects', sourcereq: headingsource };
                    this.validationerror = true;
                    return true;
                }
                return false;
            },

            copyCHToAllNextRows: function(currentCHRow, previousCHRow) {
                if (previousCHRow.isAMTTicked) {
                    currentCHRow.amtemplateid = previousCHRow.amtemplateid ? previousCHRow.amtemplateid : currentCHRow.amtemplateid;
                    currentCHRow.amtemplate = previousCHRow.amtemplate ? previousCHRow.amtemplate : currentCHRow.amtemplate;
                    currentCHRow.amtoptions = previousCHRow.amtoptions ? previousCHRow.amtoptions : currentCHRow.amtoptions;
                    currentCHRow.bqcodeoptions = previousCHRow.bqcodeoptions ? previousCHRow.bqcodeoptions : currentCHRow.bqcodeoptions;
                }

                if (previousCHRow.isNRMSortTicked) {
                    currentCHRow.nrmsortingref = previousCHRow.nrmsortingref ? previousCHRow.nrmsortingref : currentCHRow.nrmsortingref;
                    currentCHRow.nrmsortoptions = previousCHRow.nrmsortoptions ? previousCHRow.nrmsortoptions : currentCHRow.nrmsortoptions;
                }

                if (previousCHRow.isQtyTicked) {
                    currentCHRow.qty = previousCHRow.qty ? previousCHRow.qty : currentCHRow.qty;
                }
                
                if (previousCHRow.isSpecificationTicked) {
                    currentCHRow.lstspecification = previousCHRow.lstspecification ? previousCHRow.lstspecification : currentCHRow.lstspecification;

                    currentCHRow.specification = "";
                    if (previousCHRow.specificationhome && previousCHRow.specificationhome != "") {
                        currentCHRow.specificationhome = previousCHRow.specificationhome;
                        currentCHRow.specification += "\{" + 0 + "\}" + previousCHRow.specificationhome;
                    }

                    currentCHRow.lstspecification.forEach((v) => {
                        currentCHRow.specification += "\{" + v.key + "\}" + v.text;
                    });
                }

                if (previousCHRow.isAsteriskTicked) {
                    currentCHRow.lstasterisk = previousCHRow.lstasterisk ? previousCHRow.lstasterisk : currentCHRow.lstasterisk;
                    
                    currentCHRow.asterisk = "";
                    if (previousCHRow.asteriskhome && previousCHRow.asteriskhome != "") {
                        currentCHRow.asteriskhome = previousCHRow.asteriskhome;
                        currentCHRow.asterisk += "\{" + 0 + "\}" + previousCHRow.asteriskhome;
                    }

                    currentCHRow.lstasterisk.forEach((v) => {
                        currentCHRow.asterisk += "\{" + v.key + "\}" + v.text;
                    });
                }

                if (previousCHRow.isEndNotesTicked) {
                    // currentCHRow.endnotes = previousCHRow.endnotes ? previousCHRow.endnotes : currentCHRow.endnotes;
                    currentCHRow.lstendnotes = previousCHRow.lstendnotes ? previousCHRow.lstendnotes : currentCHRow.lstendnotes;
                    
                    currentCHRow.endnotes = "";
                    if (previousCHRow.endnoteshome && previousCHRow.endnoteshome != "") {
                        currentCHRow.endnoteshome = previousCHRow.endnoteshome;
                        currentCHRow.asterisk += "\{" + 0 + "\}" + previousCHRow.endnoteshome;
                    }

                    currentCHRow.lstendnotes.forEach((v) => {
                        currentCHRow.endnotes += "\{" + v.key + "\}" + v.text;
                    });
                }
            },

            openCHSettingBoxDialgoue: function(pa, pad, index) {
                index = pa.details.findIndex(p=>p.id == pad.id);
                this.boxdata.changed = this.detailform.changed;
                this.boxdata.sectionid = this.detailform.section.id;
                this.boxdata.data.library = this.detailform.library;
                this.boxdata.data.pa = pa;
                this.boxdata.data.pad = pad;
                this.boxdata.data.index = index;
                this.boxdata.visible = false;

                this.boxdata.visibleCHSettingBox = true;
            },

            handleFilterChanges() {
                this.amosDetailsFilteredIds = [];
                this.searchbqcode = this.amosFilters.searchBQCode;
                this.searchastspeother = this.amosFilters.searchAstSpeOther;

                this.amosFiltersEnabled = true;
            },

            clearFiltersValue() {
                this.amosFilters.searchBQCode = "";
                this.amosFilters.searchAstSpeOther = "";
                this.searchbqcode = "";
                this.searchastspeother = "";
            },

            BQAstSpeEndInputChanges(row) {
                if (!this.amosDetailsFilteredIds.includes(row.id)) {
                    this.amosDetailsFilteredIds.push(row.id)
                }

                if (this.amosFiltersEnabled == true) {
                    this.amosFiltersEnabled = false;
                }
            },

            setamosdetailcolswidth(row, colnumber, colswidtharr, pa) {
                var str = "";

                if (!colswidtharr[colnumber + 'width']) {
                    colswidtharr[colnumber + 'width'] = 0;
                } else if (colswidtharr[colnumber + 'width'] == 40) {
                    return;
                }
                
                var value = row["col" + colnumber];
                if (value && value.length != 0) {
                    if (value.length > colswidtharr[colnumber + 'width'] && value.length <= 40) {
                        colswidtharr[colnumber + 'width'] = value.length;
                        str = value;
                    }
                    if (value.length > 40) {
                        colswidtharr[colnumber + 'width'] = 40;
                        str = value.slice(0, 40);
                    }
                }

                if (str) {
                    if (colswidtharr[colnumber + 'width'] != 0) {
                        var size = this.measureTextWidth(str);
                        pa.columnwidths[colnumber+'width'] = size + 10;
                        this.columnwidthsarr[colnumber+'width'] = size + 10;
                    } else {
                        pa.columnwidths[colnumber+'width'] = 0;
                        this.columnwidthsarr[colnumber+'width'] = 0;
                    }
                }
            },


            ImportAmosReplace() {
                var importedpa = this.detailform.importedamosfile;
                var impad = importedpa.details;
                importedpa.id = this.detailform.replaceid;
                impad.forEach(x => {
                    x.projectamosid = this.detailform.replaceid;
                });

                var dbpa = this.detailform.projectamoses.find(x => x.id == this.detailform.replaceid);
                var dbpad = dbpa.details;

                var dbrow = 0, imrow = 0;
                var dbindex = 0, rowindex = 0;
                var ranges = [];

                for (dbrow = 0; dbrow < dbpad.length; dbrow++) {
                    var dbsnn = dbpad[dbrow];
                    
                    if (dbsnn.predicate == "CH") {
                        var dbr = this.GetScheduleRange(dbpad, dbsnn.col1, dbindex);
                        dbindex = dbr.lastrow;

                        for (imrow = 0; imrow < impad.length; imrow++) {
                            var imsnn = impad[imrow];
                            
                            if (imsnn.col1 == dbsnn.col1 && imsnn.predicate == "CH")
                            {
                                rowindex = imrow;
                                var impr = this.GetScheduleRange(impad, imsnn.col1, rowindex);

                                console.time('this.checkrows');
                                var ischeck = this.checkrows(impr,impad,dbr,dbpad);
                                console.timeEnd('this.checkrows');

                                if (ischeck == true)
                                {
                                    var isrange =  ranges.find(x => x.startrow == impr.startrow && x.lastrow == impr.lastrow);
                                    if(isrange == null)
                                    {
                                        ranges.push(impr);
                                        rowindex = impr.lastrow;

                                        console.time('this.updateamostemplate');
                                        this.updateamostemplate(impad, impr, dbpad, dbr);
                                        console.timeEnd('this.updateamostemplate');

                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            },

            GetScheduleRange(pad, sn, startindex) {
                var r = { startrow: 0, lastrow: 0}
                var i = 0;
                var matchcolumn = false;
                for (i = startindex; i < pad.length; i++)
                {
                    if (pad[i].predicate == "CH")
                    {
                        if (pad[i].col1 == sn && matchcolumn == false)
                        {
                            matchcolumn = true;
                            r.startrow = i;
                        }
                        else if (r.startrow >= 0 && i > 0)
                        {
                            r.lastrow = i - 1;
                            break;
                        }
                    }
                }
                if (!(r.lastrow > 0) && r.startrow >= 0)
                {
                    r.lastrow = i - 1;
                }
                return r;
            },

            checkrows(impr, impad, dbr, dbpad) {
                var isrowmatch = false;
                for (var d = dbr.startrow; d <= dbr.lastrow; d++)
                {
                    var ddata = dbpad[d];
                    if(ddata.predicate != "CH" && ddata.predicate != "SN")
                    {
                    var istruecoll = false;
                        for (var i = impr.startrow; i <= impr.lastrow; i++)
                        {
                            var idata = impad[i];
                            var imrowcheck = true;
                            if(idata.predicate != "CH" && idata.predicate != "SN")
                            {
                                var y = 1;
                                const maxCols = 20;

                                for (const prop in idata) {
                                    if (idata.hasOwnProperty(prop)) {
                                        let imvalue = idata[prop] ? idata[prop].toString().trim() : "";
                                        let dbvalue = ddata[prop] ? ddata[prop].toString().trim() : "";

                                        // Check if the property name matches "col" + y and y <= 20
                                        if (prop === "col" + y && y <= maxCols) {
                                            // Check for non-empty values and if dbvalue is different from ddata.qty
                                            if (imvalue && dbvalue && dbvalue !== ddata.qty.toString()) {
                                                // Check if imvalue is different from dbvalue
                                                if (imvalue !== dbvalue) {
                                                    imrowcheck = false;
                                                    break;
                                                }
                                            }
                                            y++;
                                        }
                                    }
                                }
                                if(imrowcheck == true)
                                {
                                    istruecoll = true;
                                        break;
                                }
                            }
                        }
                        if(istruecoll == true)
                        {
                            isrowmatch = true;
                            break;
                        }
                    }
                }
                return isrowmatch;
            },

            updateamostemplate(impad, impr, dbpad, dbr) {
                // Assume first row as column header
                impad[impr.startrow].predicate = dbpad[dbr.startrow].predicate;
                impad[impr.startrow].id = dbpad[dbr.startrow].id;
                impad[impr.startrow].nrmsortingref = dbpad[dbr.startrow].nrmsortingref;
                impad[impr.startrow].amtemplateid = dbpad[dbr.startrow].amtemplateid;
                impad[impr.startrow].qty = dbpad[dbr.startrow].qty;
                impad[impr.startrow].asterisk = dbpad[dbr.startrow].asterisk;
                impad[impr.startrow].specification = dbpad[dbr.startrow].specification;
                impad[impr.startrow].endnotes = dbpad[dbr.startrow].endnotes;

                let qty = impad[impr.startrow].qty;

                // Parse asterisk, specification, and endnotes
                function parseStringArray(str, delimiter) {
                    const result = [];
                    const arr = str?.split("{");
                    for (let i = 1; i < arr?.length; i++) {
                        const pair = arr[i].split("}");
                        const key = parseInt(pair[0], 10);
                        result.push({ key, value: pair[1] });
                    }
                    return result;
                }

                const ast = parseStringArray(impad[impr.startrow].asterisk, "{");
                const spe = parseStringArray(impad[impr.startrow].specification, "{");
                const newendnote = parseStringArray(impad[impr.startrow].endnotes, "{");

                for (let i = impr.startrow + 1; i <= impr.lastrow; i++) {
                    impad[i].endnotes = impad[impr.startrow].endnotes;

                    let value = "";
                    let astspecstring = "";
                    if (qty !== 0) {
                        value = impad[i][`col${qty}`]?.toString();
                    }
                    impad[i].qty = 0;

                    const parsedNumber = parseFloat(value);
                    if (!isNaN(parsedNumber)) {
                        impad[i].qty = parsedNumber;
                    } else if (value) {
                        const n = parseFloat(value);
                        if (!isNaN(n)) {
                            impad[i].qty = n;
                        }
                    }

                    // Handle asterisk
                    if (ast?.length > 0) {
                        const lastast = ast[ast.length - 1];
                        const asterisk = [];
                        for (let j = 0; j < ast.length; j++) {
                            const no = ast[j].key;
                            astspecstring += `${no}, `;
                            if (no !== 0) {
                                asterisk.push(impad[i][`col${no}`]?.toString());
                                asterisk.push(ast[j].value === "" && lastast.key !== ast[j].key ? "; " : ast[j].value);
                            } else if (ast[j].key === 0 && ast[j].value !== "undefined") {
                                asterisk.push(ast[j].value);
                            }
                        }
                        impad[i].asterisk = asterisk.join("");
                        if (impad[i].asterisk.length > 400) {
                            astspecstring = astspecstring.slice(0, -2);
                            msg += `If you replace the 'imported file' with 'current selected file', <b>your data will exceed the limit of 255 characters within row no - <span style='color: red;'>${impad[i].rowno}</span> of the asterisk column.</b><br>
                                    Please unselect some columns from selected <b>columns ${astspecstring}</b> in 'CH' row of asterisk column or reduce some data from <b>columns ${astspecstring.replace(", ", " OR ")}</b> in 'imported file'.<br><br>`;
                        }
                    }

                    // Handle endnotes
                    if (newendnote?.length > 0) {
                        astspecstring = "";
                        const lastendnote = newendnote[newendnote.length - 1];
                        const endnotes = [];
                        for (let j = 0; j < newendnote.length; j++) {
                            const no = newendnote[j].key;
                            astspecstring += `${no}, `;
                            if (no !== 0) {
                                endnotes.push(impad[i][`col${no}`]?.toString());
                                endnotes.push(newendnote[j].value === "" && lastendnote.key !== newendnote[j].key ? "; " : newendnote[j].value);
                            } else if (newendnote[j].key === 0 && newendnote[j].value !== "undefined") {
                                endnotes.push(newendnote[j].value);
                            }
                        }
                        impad[i].endnotes = endnotes.join("");
                        if (impad[i].endnotes.length > 255) {
                            astspecstring = astspecstring.slice(0, -2);
                            msg += `If you replace the 'imported file' with 'current selected file', <b>your data will exceed the limit of 255 characters within row no - <span style='color: red;'>${impad[i].rowno}</span> of the End notes column.</b><br>
                                    Please unselect some columns from selected <b>columns ${astspecstring}</b> in 'CH' row of End notes column or reduce some data from <b>columns ${astspecstring.replace(", ", " OR ")}</b> in 'imported file'.<br><br>`;
                        }
                    }

                    // Handle specification
                    if (spe?.length > 0) {
                        astspecstring = "";
                        const lastspe = spe[spe.length - 1];
                        const specifications = [];
                        for (let k = 0; k < spe.length; k++) {
                            const no = spe[k].key;
                            astspecstring += `${no}, `;
                            if (no !== 0) {
                                specifications.push(impad[i][`col${no}`]?.toString());
                                specifications.push(spe[k].value === "" && lastspe.key !== spe[k].key ? "; " : spe[k].value);
                            } else if (spe[k].key === 0 && spe[k].value !== "undefined") {
                                specifications.push(spe[k].value);
                            }
                        }
                        impad[i].specification = specifications.join("");
                        if (impad[i].specification.length > 400) {
                            astspecstring = astspecstring.slice(0, -2);
                            msg += `If you replace the 'imported file' with 'current selected file', <b>your data will exceed the limit of 390 characters within row no - <span style='color: red;'>${impad[i].rowno}</span> of the specification column.</b><br>
                                    Please unselect some columns from selected <b>columns ${astspecstring}</b> in 'CH' row of specification column or reduce some data from <b>columns ${astspecstring.replace(", ", " OR ")}</b> in 'imported file'.<br><br>`;
                        }
                    }

                    // Check for asterisk and specification length
                    if (spe?.length > 0 && ast?.length > 0) {
                        if (impad[i].asterisk.length + impad[i].specification.length > 400) {
                            msg += `If you replace the 'imported file' with 'current selected file', <b>your data will exceed the limit of 400 characters within row no - <span style='color: red;'>${impad[i].rowno}</span> of the asterisk and specification columns.</b><br>
                            Please unselect some columns from selected <b>columns ${astspecstring}</b> in 'CH' row of asterisk or specification columns or reduce some data from <b>columns ${astspecstring.replace(", ", " OR ")}</b> in 'imported file'.<br><br>`;
                        }
                    }

                    // Process dbpad rows
                    for (let j = dbr.startrow + 1; j <= dbr.lastrow; j++) {
                        const isinported = impad.find(x => x.id === dbpad[j].id);
                        if (!isinported && impad[i].id === 0 && impad[i].asterisk === dbpad[j].asterisk && impad[i].specification === dbpad[j].specification && dbpad[j].predicate === "Pre" && dbpad[j].asterisk) {
                            impad[i].id = dbpad[j].id;
                            impad[i].predicate = dbpad[j].predicate;
                            impad[i].bqcodelibraryid = dbpad[j].bqcodelibraryid;
                            impad[i].unit = dbpad[j].unit;
                            impad[i].omitfromlibrary = dbpad[j].omitfromlibrary;
                            if (dbpad[j].edit) {
                                impad[i].edit = dbpad[j].edit;
                                dbpad[j + 1].rowno = impad[i].rowno;
                                impad.splice(i + 1, 0, dbpad[j + 1]);
                                impr.lastrow++;
                                i++;
                            }
                        }
                    }
                }
            },


            autodetectcolumnsfromexcel() {
                var cc = this.detailform;
                let detailIndex = 0;
                let bqcodecolindex = -1, qtycolindex = -1, CHIndex = 0;
                let bqcodeCache = {};
                let bqcodeslist = [];
                let selectedProperties = ["section", "group", "work", "level1", "level2", "level3"];
                let chqty = ["area total", "length total", "count", "measurement", "qty", "Qty"];
                let chqtymatch = {};
                let firstmatchbqcode = "";
                let iscolumndetected = false;

                let asteriskCols = {};
                let specificationCols = {};
                let nonEmptyColIndicies = {};

                // Loop through details
                for (let i = 0; i < projectamos.details.length; i++) {
                    let detail = projectamos.details[i];

                    if (detail.predicate === "CH") {
                        CHIndex = i;
                        asteriskCols = {};
                        specificationCols = {};
                        nonEmptyColIndicies = {};
                        chqtymatch = {};

                        bqcodecolindex = -1;
                        qtycolindex = -1;
                        let bqcodeastsigncount = 0;

                        for (let j = 1; j <= 20; j++) {
                            let colValue = detail[`col${j}`]?.trim();

                            if (colValue) {
                                if (colValue.startsWith("asterisk")) {
                                    let match = colValue.match(/^asterisk(\d+)?$/);
                                    if (match && !asteriskCols[match[0]] && !detail.asterisk?.includes(`{${j}}`) && !detail.specification?.includes(`{${j}}`)) {
                                        asteriskCols[match[0]] = j;
                                    }
                                }

                                if (colValue.startsWith("specification")) {
                                    let match = colValue.match(/^specification(\d+)?$/);
                                    if (match && !specificationCols[match[0]] && !detail.asterisk?.includes(`{${j}}`) && !detail.specification?.includes(`{${j}}`)) {
                                        specificationCols[match[0]] = j;
                                    }
                                }

                                // Handle quantity columns
                                let anyQtyFilled = chqty.find(value => value.toLowerCase().trim() === colValue.toLowerCase().trim());
                                if (anyQtyFilled && !detail.asterisk?.includes(`{${j}}`) && !detail.specification?.includes(`{${j}}`)) {
                                    chqtymatch[j] = anyQtyFilled;
                                }

                                if (colValue === "bqcode") {
                                    let bqcodesnamelist = [];
                                    firstmatchbqcode = "";

                                    for (let k = i + 1; k < projectamos.details.length; k++) {
                                        let bqdetail = projectamos.details[k];

                                        if (bqdetail.predicate === "Pre") {
                                            let bqcodevalue = bqdetail[`col${j}`]?.trim();
                                            if (bqcodevalue && !bqcodeslist.find(item => item.bqcode.trim() === bqcodevalue) && !bqcodesnamelist.includes(bqcodevalue)) {
                                                if (!bqcodesnamelist.length) {
                                                    firstmatchbqcode = bqcodevalue;
                                                }
                                                bqcodesnamelist.push(bqcodevalue);
                                            }
                                        } else if (bqdetail.predicate === "SN" || bqdetail.predicate === "CH") {
                                            break;
                                        }
                                    }
                                }
                            }
                        }

                        // Process asterisk and specification columns
                        processCols(detail, asteriskCols, specificationCols, nonEmptyColIndicies);
                    }

                    // Process 'Pre' rows
                    else if (detail.predicate === "Pre") {
                        processPre(detail, asteriskCols, specificationCols, nonEmptyColIndicies, bqcodeslist, detailIndex, qtycolindex, bqcodecolindex);
                    }
                }
            },

            processCols(detail, asteriskCols, specificationCols, nonEmptyColIndicies) {
                if (Object.keys(asteriskCols).length) {
                    detail.asterisk += Object.values(asteriskCols).map(val => `{${val}}`).join('');
                    Object.keys(asteriskCols).forEach(key => nonEmptyColIndicies[asteriskCols[key]] = false);
                }

                if (Object.keys(specificationCols).length) {
                    detail.specification += Object.values(specificationCols).map(val => `{${val}}`).join('');
                    Object.keys(specificationCols).forEach(key => nonEmptyColIndicies[specificationCols[key]] = false);
                }
            },

            processPre(detail, asteriskCols, specificationCols, nonEmptyColIndices, qtyColIndex, bqCodeColIndex, bqCodesList, detailIndex, rowIndex, paDetails) {
                let isColumnDetected = false;

                // Process asterisk columns
                if (asteriskCols && Object.keys(asteriskCols).length > 0) {
                    const columnValues = Object.values(asteriskCols).map(colIndex => {
                        const colValue = detail[`col${colIndex}`] || "";

                        if (colValue && nonEmptyColIndices[colIndex] === false) {
                            nonEmptyColIndices[colIndex] = true;
                        }

                        return colValue;
                    });

                    detail.asterisk += columnValues.join("; ");
                    isColumnDetected = true;
                }

                // Process specification columns
                if (specificationCols && Object.keys(specificationCols).length > 0) {
                    const columnValues = Object.values(specificationCols).map(colIndex => {
                        const colValue = detail[`col${colIndex}`] || "";

                        if (colValue && nonEmptyColIndices[colIndex] === false) {
                            nonEmptyColIndices[colIndex] = true;
                        }

                        return colValue;
                    });

                    detail.specification += columnValues.join("; ");
                    isColumnDetected = true;
                }

                // Process quantity column
                if (qtyColIndex !== -1) {
                    const qtyValue = detail[`col${qtyColIndex}`];
                    if (qtyValue && !isNaN(qtyValue)) {
                        detail.qty = parseFloat(qtyValue);
                    }
                    isColumnDetected = true;
                }

                // Process BQCode column
                if (bqCodeColIndex !== -1 && detailIndex !== rowIndex) {
                    const bqCodeValue = (detail[`col${bqCodeColIndex}`] || "").trim();

                    if (bqCodeValue && bqCodesList.length > 0) {
                        const matchedBQCode = bqCodesList.find(item => item.bqcode.trim() === bqCodeValue);

                        if (matchedBQCode) {
                            detail.bqcodelibraryid = matchedBQCode.id;
                        }
                    }
                    isColumnDetected = true;
                }

                // Remove empty columns from asterisk and specification
                const nextDetail = paDetails[rowIndex + 1];
                const isLastRow = rowIndex === paDetails.length - 1;
                const isNextSNorCH = nextDetail && (nextDetail.predicate === "SN" || nextDetail.predicate === "CH");

                if (isLastRow || isNextSNorCH) {
                    for (const [colIndex, isNonEmpty] of Object.entries(nonEmptyColIndices)) {
                        if (!isNonEmpty) {
                            const pattern = new RegExp(`\\{${colIndex}\\}`);

                            if (paDetails[detailIndex].asterisk.includes(`{${colIndex}}`)) {
                                paDetails[detailIndex].asterisk = paDetails[detailIndex].asterisk.replace(pattern, "");
                            } else if (paDetails[detailIndex].specification.includes(`{${colIndex}}`)) {
                                paDetails[detailIndex].specification = paDetails[detailIndex].specification.replace(pattern, "");
                            }
                        }
                    }
                }

                return isColumnDetected;
            }
        },

        beforeRouteLeave(to, from, next) {
            var answer = true;
            if (this.detailform.changed) {
                var answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            }
            if (answer == true) {
                next()
                window.onbeforeunload = null;
            } else {
                next(false)
            }
            window.clearInterval(this.interval);
        },

        computed: {
             canshowctop(){
                return function (pa,row) {
                    if(pa.firstch == undefined ){
                        this.$set(pa,"firstch",row.id); 
                    }
                    if(pa.firstch == row.id){
                        return false;
                    }else{
                        return true;
                    }
                }                
            },          
            canaccessallocation() {
                if (this.user.superadmin) {
                    return true;
                }
                if (this.user.loginname == 'Maya' || this.user.loginname == 'Trevor') {
                    return true;
                } else {
                    return false;
                }
            },
            iswrapColumn(){
                return function (data,pt,cell) {
                    if(pt.columnwrapper[cell+'iswrapper']){
                        return pt.columnwrapper[cell+'iswrapper'];
                    }
                }
            },
            getdetails:function(){
                return function(ptd,row){
                    // if(row.checkarray == undefined){
                    //     row.checkarray = {};
                    //  for (let index = 1; index <= row.maxcolumn; index++) {
                    //         row.checkarray['col'+index] = true;
                    //   }
                    // }
                    row.lastch = 0;
                    if(row.command == undefined){
                        this.$set(row,'command','both');
                    }
                
                  if(this.searchbqcode != "" || this.searchastspeother != ""){


                   if (this.searchbqcode) {
                       row.isevent = undefined;
                       let shouldKeepNextEdit = false;
                       
                       ptd = ptd.filter((ptd1) => {
                            if (ptd1.predicate !== "Pre" && ptd1.predicate !== "Edit") {
                                return true;
                            }

                            if (ptd1.predicate === "Pre") {
                                if (!this.amosFiltersEnabled && this.amosDetailsFilteredIds.includes(ptd1.id)) {
                                    shouldKeepNextEdit = ptd1.edit;
                                    return true;
                                }


                                /*
                                if (ptd1.bqcodelibrary && ptd1.bqcodelibrary.bqcode &&
                                    ptd1.bqcodelibrary.bqcode.toLowerCase().replaceAll(/\s/g, '') ===
                                    this.searchbqcode.toLowerCase().replaceAll(/\s/g, '')) {
                                    if (!this.amosDetailsFilteredIds.includes(ptd1.id)) {
                                        this.amosDetailsFilteredIds.push(ptd1.id);
                                    }

                                    shouldKeepNextEdit = ptd1.edit;
                                    return true;
                                }
                                return false; */
                            }
                            
                            if (shouldKeepNextEdit && ptd1.predicate === "Edit") {
                                shouldKeepNextEdit = false;
                                return true;
                            }
                            
                            if (!ptd1.bqcodelibrary) {
                               return false;
                           }

                           if (ptd1.bqcodelibrary.bqcode
                               .toLowerCase().replaceAll(/\s/g,'')
                               .includes(this.searchbqcode.toLowerCase().replaceAll(/\s/g,'')) && ptd1.edit == true) {
                                shouldKeepNextEdit = true;
                           }

                           return ptd1.bqcodelibrary.bqcode
                               .toLowerCase().replaceAll(/\s/g,'')
                               .includes(this.searchbqcode.toLowerCase().replaceAll(/\s/g,''));
                       });
                   }

                  if (this.searchastspeother) {
                      row.isevent = undefined;
                      let shouldKeepNextEdit = false;

                      ptd = ptd.filter((ptd1,index) => {

                          if (shouldKeepNextEdit && ptd1.predicate == "Edit") {
                              shouldKeepNextEdit = false;
                              return true;
                          }

                          if(ptd1.edit == true){
                               if(ptd[index + 1]){
                                    var str = ""
                                     str += ptd[index + 1].specification == null ? " " : ptd[index + 1].specification;
                                    str += ptd[index + 1].asterisk == null ? " " : ptd[index + 1].asterisk;
                                    str += ptd[index + 1].endnotes == null ? " " :ptd[index + 1].endnotes;

                                    if (!this.amosFiltersEnabled && this.amosDetailsFilteredIds.includes(ptd[index + 1].id)) {
                                        shouldKeepNextEdit = true;
                                        /*
                                        if (this.searchbqcode && this.searchastspeother) {
                                            if (ptd1.bqcodelibrary && ptd1.bqcodelibrary.bqcode &&
                                                ptd1.bqcodelibrary.bqcode.toLowerCase().replaceAll(/\s/g, '') ===
                                                this.searchbqcode.toLowerCase().replaceAll(/\s/g, '') && 
                                                str.toLowerCase().replaceAll(/\s/g,'').includes(this.searchastspeother.toLowerCase().replaceAll(/\s/g,''))) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }*/

                                        return true;
                                    }

                                    // if (str.toLowerCase().replaceAll(/\s/g,'').includes(this.searchastspeother.toLowerCase().replaceAll(/\s/g,'')) && !this.amosDetailsFilteredIds.includes(ptd1.id)) {
                                    //     this.amosDetailsFilteredIds.push(ptd1.id);
                                    // }

                                    return str.toLowerCase().replaceAll(/\s/g,'').includes(this.searchastspeother.toLowerCase().replaceAll(/\s/g,''));
                               }
                          }
                          if(ptd1.predicate != "Pre" && ptd1.predicate != "Edit"){
                               return true;
                           }
                          var str = "";
                               str += ptd1.specification == null ? " " : ptd1.specification
                               str += ptd1.asterisk == null ? " " : ptd1.asterisk;
                               str += ptd1.endnotes == null ? " " : ptd1.endnotes;

                        
                            if (!this.amosFiltersEnabled && this.amosDetailsFilteredIds.includes(ptd1.id)) {
                                /*
                                if (this.searchbqcode && this.searchastspeother) {
                                    if (ptd1.bqcodelibrary && ptd1.bqcodelibrary.bqcode &&
                                        ptd1.bqcodelibrary.bqcode.toLowerCase().replaceAll(/\s/g, '') ===
                                        this.searchbqcode.toLowerCase().replaceAll(/\s/g, '') && 
                                        str.toLowerCase().replaceAll(/\s/g,'').includes(this.searchastspeother.toLowerCase().replaceAll(/\s/g,''))) {
                                        return true;
                                    } else if ((!ptd1.bqcodelibrary || !ptd1.bqcodelibrary.bqcode) && str.toLowerCase().replaceAll(/\s/g,'').includes(this.searchastspeother.toLowerCase().replaceAll(/\s/g,''))) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }*/

                                return true;
                            }

                            /*if (str.toLowerCase().replaceAll(/\s/g,'').includes(this.searchastspeother.toLowerCase().replaceAll(/\s/g,'')) && !this.amosDetailsFilteredIds.includes(ptd1.id)) {
                                this.amosDetailsFilteredIds.push(ptd1.id);
                            }*/

                           return str.toLowerCase().replaceAll(/\s/g,'').includes(this.searchastspeother.toLowerCase().replaceAll(/\s/g,''))
                      });
                  }
                  ptd = ptd.filter((ptd1,index) => {
                          if(ptd1.predicate != "Pre" && ptd1.predicate != "Edit"){
                              if(ptd1.predicate == "CH"){
                                  if(ptd[index + 1] && (ptd[index + 1].predicate == "Pre" ||
                                     ptd[index + 1].predicate == "Edit")){
                                      return true;
                                  }else{
                                      return false;
                                  }
                              }
                              if(ptd1.predicate == "SN"){
                                  if(ptd[index + 2] && (ptd[index + 2].predicate == "Pre" ||
                                     ptd[index + 2].predicate == "Edit")){
                                      return true;
                                  }else{
                                      return false;
                                  }
                              }
                           }else{
                               return true;
                           }
                      });

                    }
                var lastid = ptd.findLast(x => x.predicate == "CH");
                if(lastid != null){
                    row.lastch = lastid.id; 
                }
                if(row.canscroll == undefined){
                    row.canscroll = false;
                }
                if(row.pagesize == undefined){
                   row.pagesize = this.pagesize;
                }        
                if(row.page == undefined){
                    row.page = 1;
                    row.currentpage = 1;
                }        
               
                if(row.total != ptd.length){
                    row.total = ptd.length;
                    row.pagesize = this.pagesize;
                    this.setpagesize(row,ptd);
                }
                 row.total = ptd.length;
                if (row.total == 0) {
                    return [];
                }
                if (!(Math.ceil(row.total / row.pagesize) >= row.page)) {
                 row.page = Math.ceil(row.total / row.pagesize);
                 row.currentpage = row.page;
                }

                if (this.$refs.detailtable) {

                    /*
                    var cc = this.$refs.detailtable;
                    var tt = this.$refs.inputTest;

                    if (cc) {
                        this.$nextTick(() => {
                        this.$refs.detailtable.doLayout();
                            // this.$refs.inputTest.resizeTextarea();
                        })    
                    }

                    if (tt) {
                        this.$nextTick(() => {
                            this.$refs.inputTest.resizeTextarea();
                        })    
                    }*/
                    if(row.pagescroll == undefined){
                        row.pagescroll = [];
                    }
                    const scrollposition = row.pagescroll[row.page];
                    const canscroll = row.canscroll;
                    if(row.canscroll == true){
                        row.canscroll = false;
                    }
                    this.$nextTick(() => {
                        var refto = this.$refs.detailtable;
                        refto.doLayout();
                        if(canscroll == true){
                            var dd = document.querySelector("#detailtable .el-table__body-wrapper");
                            if(dd != null && scrollposition !=  undefined){
                                this.$set(dd,'scrollTop',scrollposition);
                            }else{
                                this.$set(dd,'scrollTop',0);
                            }
                        }
                    })
                }
                return ptd.slice(row.pagesize * row.page - row.pagesize, row.pagesize * row.page);
                }
            },
            getprojectamoses: function () {
                if (!this.detailform.projectamoses) {
                    return [];
                }
               var amd =  this.detailform.projectamoses.filter((row)=>{
                    var dt = this.getdetails(row.details,row);
                    if(row.page == undefined){
                         row.pagesize = this.pagesize;
                         row.page = 1;
                         row.currentpage = row.page; 
                         row.total = dt.length;
                         if (row.total == 0) {
                             return [];
                         }
                         if (!(Math.ceil(row.total / row.pagesize) >= row.page)) {
                          row.page = Math.ceil(row.total / row.pagesize);
                          row.currentpage = row.page;
                          }
                     }
                     if(this.viewmode == true && this.showamosid == null && dt.length > 0)
                     {
                        this.showamosid = row.id;
                        this.expandedrows.push(row.id);
                        this.mycustomheight = (document.documentElement.clientHeight - 240);
                     }
                     if(this.showamosid != null) {
                        if(row.id == this.showamosid) {
                            return true;
                        }
                        else { 
                            return false;
                        }
                     }
                     else {
                         if(dt.length != 0){
    
                             return true;
                         }else{
                             return false;
                         }
                     }
                })
                return amd;
            },

            isOkButtonDisabled: function() {
                // return !(( this.detailform.replace == true && this.detailform.replaceid != null) || this.detailform.replace === false );
                return this.replaceoraddradio && !this.detailform.replaceid;
            },
        },

        mounted: function() {
            // Change project page filters append search button
            const amosFiltersSearchBtn = document.querySelectorAll('.el-input-group__append, .el-input-group__prepend');
            amosFiltersSearchBtn.forEach(element => {
                element.style.padding = '0px 12px';
            });
        },

        created: function () {
            this.searchastspeother = "";
            this.searchbqcode = "";
            if(this.$store.state.billbqcode){
                this.$set(this,'searchbqcode',this.$store.state.billbqcode)
                this.amosFilters.searchBQCode = this.$store.state.billbqcode;
            }
             if(this.$store.state.billspecification){
                 this.searchastspeother += this.$store.state.billspecification;
                 this.amosFilters.searchAstSpeOther = this.$store.state.billspecification;
               this.$store.state.billspecification = "";
             }
            if( this.$store.state.billasterisk){
                this.searchastspeother += this.$store.state.billasterisk;
                this.amosFilters.searchAstSpeOther = this.$store.state.billspecification;
               this.$store.state.billasterisk = "";
            }
            this.$bus.$on('setuser', (user) => {
                this.user = user;
            });
            var columns = [{ key: 0, value: ' ' }];
            for (var i = 1; i <= 10; i++) {
                columns.push({ key: i, value: ("0" + i).substr(("0" + i).length - 2) });
            }
            this.columns = columns;
            if (window.File && window.FileReader && window.FileList && window.Blob) {
                // Great success! All the File APIs are supported.
            } else {
                alert('The File APIs are not fully supported in this browser.');
            }
            //window.addEventListener('beforeunload', this.beforeunload);
            window.onbeforeunload = this.beforewindowunload;
            //setInterval(this.autosave, 10 * 60000);
            this.interval = window.setInterval(this.autosave, 10 * 60000);
            window.addEventListener('resize', this.handleResize)

            const params = new URLSearchParams(window.location.search);
            if (params.size == 3) {
                this.$training.getprojectusingparam(params);
            }
        },

        components: {
            'projectselectpart': projectselectpart,
            'projectbqcode': projectbqcode,
            'amtemplateedit': amtemplateedit,
            'customdialog': customdialogbox,
            'custominputdialog': custominputdialog,
            intimechecklist:intimechecklist,
            CustomPageDraggable:CustomPageDraggable,
            rfientryform:rfientryform,
            'amosallocation':amosallocation,
            'displaydropboxmedia': displaydropboxmedia,
        },

        beforeDestroy: function () {
            window.removeEventListener('resize', this.handleResize)
        },

        destroyed() {
            window.removeEventListener('resize', this.getheight);
        },

        updated() {
            window.addEventListener('resize', this.getheight);
        }

    }

</script>
<style itemscope>
    h3{
        margin-top:20px;
        margin-left: 8px;
    }

    .lightblue {
        background-color: rgba(0, 100, 255, 0.25) !important;
    }

    .el-table .cell {
        /*make more space for textboxes inside el-table*/
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    .blue {
        color: blue;
    }

    .centermiddle {
        position: fixed;
        top: 50%;
        left: 15%;
        transform: translate(-50%, -50%);
        z-index: 5;
        border: 1px solid grey;
    }

    .el-input-number--mini {
        width: 100%;
    }

    .el-input-number .el-input__inner {
        text-align: right;
    }


    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 0px;
    }

    .el-form-item {
        margin-bottom: 0px;
    }

    .el-col {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .grey {
        background-color: grey !important;
    }

    .lightgray {
        background-color: lightgray !important;
    }

    .el-textarea__inner {
        padding: 5px;
    }
    /* .el-input__prefix {
        left: -2px;
        top: 8%;


    } */
    .padding_left_5{
        padding-left:5px;
    }
    .CH_row_style{
        font-weight: bold;
        color:#4f4f4f;
        text-overflow: clip;
        white-space: pre;
    }
    .black_cell_style{
        background-color: rgb(158, 255, 166);
        /* color: white; */
    }
   
    .white_cell_style{
        background-color: white !important;
        /* color: black !important; */
    }
   .el-table--enable-row-hover .el-table__body tr:hover>td.black_cell_style{
     background-color: rgb(158, 255, 166) !important;
   }
    .el-table--enable-row-hover .el-table__body tr:hover>td.white_cell_style{
      background-color: white !important;
   }
   .wrapColumnTexttwo .cell {
    white-space: pre !important;
   }
   .hidecoll{
    display: none;
   }
   .marginright11px{
    margin-left:11px;
   }
</style>
