import { render, staticRenderFns } from "./projectbill.vue?vue&type=template&id=09a11f8a&"
import script from "./projectbill.vue?vue&type=script&lang=js&"
export * from "./projectbill.vue?vue&type=script&lang=js&"
import style0 from "./projectbill.vue?vue&type=style&index=0&id=09a11f8a&prod&itemscope=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports