<template>
    <div>
        <el-row>
            <el-col :span="14" class="headerstyle">AMOS Allocation Algorithm</el-col>
            <el-col :span="10">
                <div class="padding2px right">
                    <el-button :disabled="!editpermission" class="marginleft4px" @click="saveallocation" slot="" type="success">Save</el-button>
                    <el-button :disabled="!editpermission" class="marginleft4px" slot="" @click="closebox"
                        type="info">Close</el-button>
                    <el-tooltip content="Click for guidance" placement="top">
                        <b @click="$training.Gettrainingpopup('info_amos_allocation')" class="helpiconstyle" style="margin: 0px 5px !important;">i</b>
                    </el-tooltip>
                </div>
            </el-col>
        </el-row>
        <div>
            <hr class="hrstyle" />
        </div>
        <el-row>
            <el-col :span="24">
                <projectselectpart :custom="custom" @projectsectionchange="projectsectionchange"> </projectselectpart>
            </el-col>
        </el-row>
        <el-row>
            <div>
                <el-collapse v-model="collapsename" class="amosrowcollapse schedulerow">
                    <el-collapse-item title=" Selected Schedule and Row" name="1">
                        <div class="spacebetween" />  
                        <!-- <projectselectpart :custom="custom" @projectsectionchange="projectsectionchange"> </projectselectpart> -->
                        <el-row :gutter="5">
                            <el-col :span="6">
                                <el-select @change="changefile" v-model="amosfileid" placeholder="Select File">
                                    <el-option v-for="item in projectamos.projectamoses" :key="item.id"
                                        :label="item.filename" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="5">
                                <div v-if="amosfile != undefined">
                                    <b> Added by:</b> {{ amosfile.editedbyloginname }}
                                </div>
                            </el-col>
                        </el-row>
                        <div class="spacebetween" />  
                        <el-row :gutter="5" v-if="amosfile != null" class="blueshadowbox">
                            <el-col :span="5" >
                                <el-row :gutter="5">
                                    <el-col :span="7">
                                        <div>Row</div>
                                    </el-col>
                                    <el-col :span="12" >
                                        <el-select @change="changeamosrow" v-model="amosrowid" placeholder="Row">
                                            <el-option v-for="item in getamosrows" :key="item.id" :label="item.rowno"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>

                            </el-col>
                            <el-col :span="19" >
                                <div v-if="amosrowselect">
                                    <span> {{ getamtemplate(amosrowselect.chinddex) }}</span>
                                    <span style='font-size:25px; writing-mode: vertical-rl;'>&#8226;</span>
                                    <span >{{ getnrmcode(amosrowselect.chinddex) }} </span>
                                </div>
                            </el-col>
                        </el-row>
                        <div class="spacebetween" />  
                        <el-row :gutter="5" v-if="amosrowselect != null && amosfile != null">
                            <el-col :span="7" class="blueshadowbox">
                                <!-- <el-select @change="changebqcode" v-model="bqcodeid" calss="fullwidth" placeholder="Select BQ_Codes">
                                    <el-option v-for="item in this.mlbqcodes[this.amosrow.id].mlbqcodes" :key="item.bql.id" :label="item.bql.bqcode" :value="item.id">
                                    </el-option>
                                </el-select> -->
                                <el-autocomplete class="inline-input fullwidth"
                                                         popper-class="morewidth-autocomplete"
                                                         v-model="bqcode"
                                                         :fetch-suggestions="(query, done) => {querySearchBQCode(query, done)}"
                                                         placeholder=""
                                                         :disabled="!editpermission"
                                                         :select-when-unmatched="true"
                                                         value-key="bqcode"
                                                         @select="handleSelectBQCode($event)"
                                                         @Clear="handleSelectBQCode($event)"
                                                         @change="changebqcode($event)"
                                                         :trigger-on-focus="true"
                                                         :clearable="true">
                                            <template slot-scope="{ item }">
                                                <div v-if="item && item.bql">
                                                    <div style="white-space:normal;"><el-row><el-col :span="12">{{ item.bql.bqcode }}</el-col> <el-col :span="12"> <span class="right"> {{ item.count }}</span></el-col></el-row></div>
                                                    <div style="white-space:normal;font-size: x-small"><span>{{ item.bql.section }} | {{ item.bql.group }} | {{ item.bql.work }} | {{ item.bql.level1 }} | {{ item.bql.level2 }} | {{ item.bql.level3 }}</span></div>
                                                </div>
                                            </template>
                                        </el-autocomplete>
                            </el-col>
                            <el-col :span="8" class="blueshadowbox">
                                <div v-if="amosrowselect">
                                    <b> Based on:</b> {{ bccodestatus }}
                                </div>
                            </el-col>
                            <el-col :span="9">
                                <div v-if="amosrowselect">
                                    <b> Include Past Since:</b> <span>
                                        <el-date-picker
                                            @change="changepastsince"    
                                            v-model="regex.pastsince"
                                            type="date"
                                            class="custom-date-picker-psp"
                                            placeholder="Include Past Since">
                                            </el-date-picker>
                                    </span>
                                </div>
                            </el-col>
                        </el-row>
                        <div class="spacebetween" />  
                    </el-collapse-item>
                </el-collapse>
            </div>
        </el-row>
        <el-row>
            <div>
                <el-collapse v-model="collapsename"  class="amosrowcollapse regexbqcode">
                    <el-collapse-item title=" AMOS Column Allocation" name="2">
                        <div class="spacebetween" />  
                        <el-row v-if="amosrowselect != null">
                            <div class="bqcodeselectionwidth" v-for="itme in 20">
                                <el-row style="border: 1px solid gray; padding: 3px;"
                                    v-if="itme > 0 && amosrowselect['col' + itme]">
                                    <el-col :span="2">
                                        <el-tag> {{ 'col' + itme }}</el-tag>
                                    </el-col>
                                    <el-col :span="22">
                                        <div style="word-wrap: break-word;">
                                            {{ amosrowselect['col' + itme] }}
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-row>
                        <div class="spacebetween" />  
                    </el-collapse-item>
                </el-collapse>
            </div>
        </el-row>
        <div v-if="bqcode != null">
        <el-row >
            <el-col :span="24">
                <el-radio-group @change="columntypechanged" v-model="columntype" size="small">
                <el-radio-button  label="bqcode">BQ_Code</el-radio-button>
                <el-radio-button label="qty" >Quantity(Qty)</el-radio-button>
                <el-radio-button label="specification" >Specification</el-radio-button>
                <el-radio-button label="asterisk" >Asterisk*</el-radio-button>
                <el-radio-button label="endnotes" >Endnotes</el-radio-button>
                </el-radio-group>
            </el-col>
        </el-row>
        <el-row v-if="columntype" >
            <div>
                <el-collapse v-model="collapsename" class="amosrowcollapse regexbqcode">
                    <el-collapse-item :title=" selectcolumn  + ' - Global REGEX'" name="3">
                        <template slot="title">
                            {{   selectcolumn  + ' - Global REGEX' }} <span style="padding-left: 400px;">
                                <el-switch
                                        @change="allocationchanged"
                                        v-model="regex.globalregex.active"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                        </el-switch> 
                            </span>
                            </template>
                        <div class="spacebetween" />
                            <el-row>
                                <el-col :span="2">
                                    <span><b>Past</b></span>
                                    <br>
                                    <span><i  @click="showbox(regex.globalregex,'regexinfoshow')" class="el-icon-edit copycontrolfont"></i></span>
                                </el-col>
                                <el-col :span="22">
                                    <!-- <el-input
                                        type="textarea"
                                        :rows="2"
                                        @change="allocationchanged"
                                        placeholder="Please input Past"
                                        v-model="regex.globalregex.regexinfo">
                                        </el-input>     -->
                                        <div :class="regex.globalregex.regexinfoshow == true ? '' : 'disabledDiv'">
                                            <!-- <div
                                                :contenteditable="true"
                                                :spellcheck="false"
                                                v-html="regex.globalregex.regexinfo"
                                                class="regexinfobox"
                                                @blur="($event)=>{ updateHtmlregexinfo($event,regex.globalregex,'regexinfo')}"
                                                
                                            ></div> -->
                                            <highlightcodeeditor ref="globalregex" v-model="regex.globalregex.regexinfo" height="50px"  @input="(e)=>{regexinput(e,regex.globalregex,'regexinfo')} " :id="1" ></highlightcodeeditor>
                                            </div>
                                </el-col>
                            </el-row>   
                            <el-row>
                                <el-col :span="2">
                                    <span><b>F&O</b></span>
                                    <br>
                                    <span><i @click="showbox(regex.globalregex,'regexshow')" class="el-icon-edit copycontrolfont"></i></span>
                                </el-col>
                                <el-col :span="22">
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        :disabled="!regex.globalregex.regexshow"
                                        @change="allocationchanged"
                                        placeholder="Please input F&O"
                                        v-model="regex.globalregex.regex" 
                                        @input="$training.checkregex(regex.globalregex.regex,'globalregex')">
                                        </el-input>   
                                        <div class="padleft10px alert-danger" style="display: none;" id="globalregex">
                                            <strong>Invalid Regular Expression.</strong>
                                        </div> 
                                </el-col>
                            </el-row>  
                            <el-row>
                                <el-col :span="2">
                                    <span><b>Active</b></span>
                                    <!-- <br> -->
                                    <!-- <span><i class="el-icon-edit copycontrolfont"></i></span> -->
                                </el-col>
                                <el-col :span="22">
                                    <el-switch
                                        @change="allocationchanged"
                                        v-model="regex.globalregex.active"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                        </el-switch> 
                                </el-col>
                            </el-row>    
                        <div class="spacebetween" />
                    </el-collapse-item>
                </el-collapse>
            </div>
        </el-row>
        <el-row v-if="columntype">
            <div>
                <el-collapse v-model="collapsename" class="amosrowcollapse regexbqcode">
                    <el-collapse-item :title=" selectcolumn  + ' - Group REGEX'" name="4">
                        <template slot="title">
                            {{   selectcolumn  + ' - Group REGEX' }} <span style="padding-left: 400px;">
                                <el-switch
                                        @change="allocationchanged"
                                        v-model="regex.groupregex.active"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                        </el-switch> 
                            </span>
                            </template>
                        <div class="spacebetween" />
                            <el-row>
                                <el-col :span="2">
                                    <span><b>Past</b></span>
                                    <br>
                                    <span><i  @click="showbox(regex.groupregex,'regexinfoshow')" class="el-icon-edit copycontrolfont"></i></span>
                                </el-col>
                                <el-col :span="22">
                                    <!-- <el-input
                                        type="textarea"
                                        :rows="2"
                                        @change="allocationchanged"
                                        placeholder="Please input Past"
                                        v-model="regex.groupregex.regexinfo">
                                        </el-input>     -->
                                        <div :class="regex.groupregex.regexinfoshow == true ? '' : 'disabledDiv'">
                                            <!-- <div
                                                :contenteditable="true"
                                                :spellcheck="false"
                                                v-html="!regex.groupregex.regexinfo"
                                                class="regexinfobox"
                                                @blur="($event)=>{ updateHtmlregexinfo($event,regex.groupregex,'regexinfo')}"
                                                
                                            ></div> -->
                                            <highlightcodeeditor ref="groupregex" v-model="regex.groupregex.regexinfo"  height="50px"  @input="(e)=>{regexinput(e,regex.groupregex,'regexinfo')} " :id="2" ></highlightcodeeditor>
                                            </div>
                                </el-col>
                            </el-row>   
                            <el-row>
                                <el-col :span="2">
                                    <span><b>F&O</b></span>
                                    <br>
                                    <span><i @click="showbox(regex.groupregex,'regexshow')" class="el-icon-edit copycontrolfont"></i></span>
                                </el-col>
                                <el-col :span="22">
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        :disabled="regex.groupregex.regexshow"
                                        @change="allocationchanged"
                                        placeholder="Please input F&O"
                                        v-model="regex.groupregex.regex"
                                        @input="$training.checkregex(regex.groupregex.regex,'groupregex')">
                                        </el-input>   
                                        <div class="padleft10px alert-danger" style="display: none;" id="groupregex">
                                            <strong>Invalid Regular Expression.</strong>
                                        </div> 
                                </el-col>
                            </el-row>    
                            <el-row>
                                <el-col :span="2">
                                    <span><b>Active</b></span>
                                    <!-- <br> -->
                                    <!-- <span><i class="el-icon-edit copycontrolfont"></i></span> -->
                                </el-col>
                                <el-col :span="22">
                                    <el-switch
                                        @change="allocationchanged"
                                        v-model="regex.groupregex.active"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                        </el-switch> 
                                </el-col>
                            </el-row>    
                        <div class="spacebetween" />
                    </el-collapse-item>
                </el-collapse>
            </div>
        </el-row>
        <el-row v-if="columntype">
            <div>
                <el-collapse v-model="collapsename" class="amosrowcollapse regexbqcode">
                    <el-collapse-item :title=" selectcolumn  + ' - Single REGEX'" name="5">
                        <template slot="title">
                            {{  selectcolumn  + ' - Single REGEX' }} <span style="padding-left: 400px;">
                                <el-switch
                                        @change="allocationchanged"
                                        v-model="regex.singleregex.active"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                        </el-switch> 
                            </span>
                            </template>
                        <div class="spacebetween" />
                            <el-row>
                                <el-col :span="2">
                                    <span><b>Past</b></span>
                                    <br>
                                    <span><i @click="showbox(regex.singleregex,'regexinfoshow')" class="el-icon-edit copycontrolfont"></i></span>
                                </el-col>
                                <el-col :span="22">
                                    <!-- <el-input
                                        type="textarea"
                                        :rows="2"
                                        @change="allocationchanged"
                                        placeholder="Please input Past"
                                        v-model="regex.singleregex.regexinfo">
                                        </el-input>     -->
                                        <div :class="regex.singleregex.regexinfoshow == true ? '' : 'disabledDiv'">
                                         <!-- <div
                                             
                                             :contenteditable="true"
                                             :spellcheck="false"
                                             v-html="regex.singleregex.regexinfo"
                                             class="regexinfobox"
                                             @blur="($event)=>{ updateHtmlregexinfo($event,regex.singleregex,'regexinfo')}"
                                             
                                         ></div> -->
                                         <highlightcodeeditor ref="singleregex" v-model="regex.singleregex.regexinfo"  height="50px"  @input="(e)=>{regexinput(e,regex.singleregex,'regexinfo')} " :id="3" ></highlightcodeeditor>
                                         </div>
                                </el-col>
                            </el-row>   
                            <el-row>
                                <el-col :span="2">
                                    <span><b>F&O</b></span>
                                    <br>
                                    <span><i @click="showbox(regex.singleregex,'regexshow')" class="el-icon-edit copycontrolfont"></i></span>
                                </el-col>
                                <el-col :span="22">
                                    <el-input
                                        type="textarea"
                                        :rows="2"
                                        :disabled="!regex.singleregex.regexshow"
                                        @change="allocationchanged"
                                        placeholder="Please input F&O"
                                        v-model="regex.singleregex.regex" 
                                        @input="$training.checkregex(regex.singleregex.regex,'singleregex')">
                                        </el-input>   
                                        <div class="padleft10px alert-danger" style="display: none;" id="singleregex">
                                            <strong>Invalid Regular Expression.</strong>
                                        </div> 
                                </el-col>
                            </el-row>  
                            <el-row>
                                <el-col :span="2">
                                    <span><b>Active</b></span>
                                    <!-- <br> -->
                                    <!-- <span><i class="el-icon-edit copycontrolfont"></i></span> -->
                                </el-col>
                                <el-col :span="22">
                                    <el-switch
                                        @change="allocationchanged"
                                        v-model="regex.singleregex.active"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                        </el-switch> 
                                </el-col>
                            </el-row>      
                        <div class="spacebetween" />
                    </el-collapse-item>
                </el-collapse>
            </div>
        </el-row>
        <el-row v-if="columntype">
            <div>
                <el-collapse v-model="collapsename" class="amosrowcollapse regexreport">
                    <el-collapse-item :title=" 'Past Matches to ' + selectcolumn + ' Report'" name="6">
                        <template slot="title">
                            {{  'Past Matches to ' + selectcolumn + ' Report' }}
                            </template>
                        <div>
                        <div class="spacebetween" />
                            <el-row>
                                <el-col :span="12">
                                    <el-button type="success" plain @click="getreport">Create Report</el-button>
                                    <el-button type="success" plain @click="exportreport">Export To XLMS</el-button>
                                </el-col>
                            </el-row>
                        <div class="spacebetween" />
                        <el-row>
                            <el-col :span="24">
                                <div>
                                <el-table  :data="showtabldata" 
                                        :border="true" 
                                        :stripe="true"
                                        row-key="id"
                                        v-el-table-infinite-scroll="() => { return load(); }" 
                                        >
                                            <el-table-column label="Ex" align="center" width="29">
                                                <template slot-scope="scope">
                                                    <el-checkbox v-model="scope.row.isignore"
                                                        @change="deleteRow($event,scope.$index, scope.row)"></el-checkbox>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="BQ Code" prop="bqcodename" width="200">
                                            </el-table-column>

                                            <el-table-column label="Specification" width="200">
                                                <template scope="scope">
                                                    <div style="word-wrap: break-word;" >
                                                    <span v-html="scope.row.specification"></span>
                                                    </div>
                                                </template>
                                            </el-table-column>

                                            <!-- <el-table-column label="SIL" prop="ss.specpos" align="center" width="40">
                                            </el-table-column> -->

                                            <el-table-column label="Asterisk" width="200">
                                                <template scope="scope">
                                                    <div style="word-wrap: break-word;">
                                                        <span  v-html="scope.row.asterisk"></span>
                                                    </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column label="End Notes" width="150">
                                                <template scope="scope">
                                                    <div style="word-wrap: break-word;">
                                                        <span  v-html="scope.row.other"></span>
                                                    </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column label="Date" prop="editedon" :formatter="dateformatter" width="90">
                                            </el-table-column>

                                            <el-table-column label="Project Name" prop="projectname" width="290">
                                            </el-table-column>
                                </el-table>
                                </div>
                            </el-col>
                        </el-row>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </el-row>
        </div>  
    </div>
</template>
<script>
import moment from "moment";
import projectselectpart from './projectselectpart.vue';
import highlightcodeeditor from './highlightcodeeditor.vue';
import elTableInfiniteScroll from "el-table-infinite-scroll";
export default {
    directives: {
        "el-table-infinite-scroll": elTableInfiniteScroll,
    },
    props: ["projectamos", "amosrow"],
    data() {
        return {
            columntype:"",
            allocations:[],
            checkwords:[],
            user: this.$store.state.user,
            bccodestatus:"",
            endlength:20,
            startlength:0,
            showtabldata:[],
            reports:[],
            regex:{
                globalregex:{},     
                groupregex:{},     
                singleregex:{},     
            },
            selectcolumn:"BQ_Code",
            bqcodes:[],
            mlbqcodes:[],
            bqcodeid: null,
            bqcode: null,
            amosrowid: null,
            amosrowselect:{},
            amosfile: null,
            amosfileid: null,
            collapsename: '1',
            custom: {
                projectdisabled: true,
                partdisabled: true,
                sectiondisabled: true,
            },
            sectionid: null,
            project: null,
            isreportchanged:false,
            isallocationchanged:false,
        }
    },
    computed: {
        getnrmcode() {
            return function (i) {
                return this.amosfile.details[i].nrmsortingref;
            }
        },
        getamtemplate() {
            return function (i) {
                if (this.amosfile.details[i].amtemplate) {

                    return this.amosfile.details[i].amtemplate.amtemplatename;
                } else {
                    return "";
                }
            }
        },
        getamosrows() {
            return this.amosfile.details.filter(x => x.predicate == "Pre");
        },
    },
    components: {
        'projectselectpart': projectselectpart,
        'highlightcodeeditor':highlightcodeeditor
    },
    methods: {
        columntypechanged(){
            // this.allocationchanged();
            // this.getregexbybqcodeid();
            var isfind = this.allocations.find(x=> x.columntype == this.columntype);
            if(isfind){
                this.regex = isfind; 
            } else{
                var obj = {
                    globalregex:{},     
                    groupregex:{},     
                    singleregex:{},
                }
                obj.columntype = this.columntype;
                obj.bqcodeid = this.bqcodeid;
                this.regex = obj; 
                this.allocations.push(this.regex)
            }
            if(!this.regex.pastsince || this.regex.pastsince == "0001-01-01T00:00:00"){
                this.$set(this.regex,'pastsince',new Date(new Date().setFullYear(new Date().getFullYear() - 1)))
            }
            this.$refs.globalregex.settexthighlight();
            this.$refs.groupregex.settexthighlight();
            this.$refs.singleregex.settexthighlight();
        },
        // checkallregex(){
        //       var singleregex = true;
        //       var globalregex = true;
        //       var groupregex = true;
        //       if(this.regex.globalregex && this.regex.globalregex.regex != null){
        //           globalregex =  this.$training.checkregex(this.regex.globalregex.regex,'globalregex');
        //       }
        //       if(this.regex.groupregex && this.regex.groupregex.regex != null){
        //           groupregex =  this.$training.checkregex(this.regex.groupregex.regex,'groupregex');
        //       }
        //       if(this.regex.singleregex && this.regex.singleregex.regex != null){
        //           singleregex =  this.$training.checkregex(this.regex.singleregex.regex,'singleregex');
        //       }
        //      if(!singleregex || !globalregex || !groupregex){
        //             this.showmsg("Invalid Regular Expression.");
        //             return false;
        //         }else{
        //             return true;
        //         }
        // },
        regexinput(e,data,key){
            this.$set(data,key,e.target.innerText);
        },
        // getregexforrow(word){
        //     var obj = {
        //         global:"",
        //         group:"",
        //         single:""
        //     }
        //     if(this.regex.globalregex.active && this.regex.globalregex.regex != ""){
        //         obj.global = this.regex.globalregex.regex;
        //     }
        //     if(this.regex.groupregex.active && this.regex.groupregex.regex != ""){
        //         obj.group = this.regex.groupregex.regex;
        //     }
        //     if(this.regex.singleregex.active && this.regex.singleregex.regex != ""){
        //         obj.single = this.regex.singleregex.regex;
        //     }
        //     return obj;
        //     // let red_color = `<span style='color:red'>${word}</span>`;
        //     // var regex = new RegExp(word, "gi");
        //     // if(this.regex.columntype == "endnotes"){
                
        //     // }
        //     // if(this.regex.columntype == "asterisk"){
                
        //     // }
        //     // if(this.regex.columntype == "specification"){
                
        //     // }
        //     // if(this.regex.columntype == "bqcode"){
                
        //     // }
        // },
        setredtext(array){
        //    var regex =  this.getregexforrow();
            // var inc_pattern = null;
            // var exc_pattern = null;
            
            // if(this.detailform.individual.include != null && this.detailform.individual.include != ""){
            //     inc_pattern = new RegExp(this.detailform.individual.include,"i");
            // }
            // if(this.detailform.individual.exclude != null && this.detailform.individual.exclude != ""){
            //     exc_pattern = new RegExp(this.detailform.individual.exclude,"i");
            // }
           array.forEach(row => {
                //  var canspe = this.$training.RG_Direction_Match(row.ss.specification,inc_pattern,exc_pattern);
                //  var canast = this.$training.RG_Direction_Match(row.ss.asterisk,inc_pattern,exc_pattern);
                //  var canoth = this.$training.RG_Direction_Match(row.ss.other,inc_pattern,exc_pattern);
                //  if(canspe || canast || canoth){
                      this.checkwords.forEach(word => {
                           word = word.replace(/ +/g, "");
                           if(word != ""){
                               let red_color = `<span style='color:red'>${word}</span>`;
                               var regex = new RegExp(word, "gi");
                               row.specification = row.specification == null ? "" : row.specification.replace(regex, red_color);
                               row.asterisk = row.asterisk == null ? "" : row.asterisk.replace(regex, red_color);
                               row.other = row.other == null ? "" : row.other.replace(regex, red_color);
                           }
                       });
                    //    ddi.push(row);
                //   }
             });
            return array;
        },
        setrowstyle(data){
            this.$emit("setrowstyle",data);
        },
        showbox(data,key){
            this.$nextTick(()=>{
                this.$set(data,key,data[key] == true ? false : true)
                // data[key] = data[key] == true ? false : true; 
            })
        },
        processCode(code) {
            let outputString = code.replace(/<\/span>/g, '');
            // Remove "<span style="color: #808080;">"
            outputString = outputString.replace(/<span\s+style="color: #808080;">/g, '');
            outputString = outputString.replace(/<span\s+style="color: green;">/g, '');
            outputString = outputString.replace(/"(.*?)"/g, (match, group) => `<span style="color: green;">"${group}"</span>`);
          return outputString.replace(/#(.*?)#/g, (match) => `<span style="color: #808080;">${match}</span>`);
        },
        updateHtmlregexinfo(e,data,key){
            if(e.target.innerText == "\n"){
                e.target.innerText = "";
                e.target.innerHTML = "";
            }
            // data[key] = e.target.innerHTML;
            const styledCode = this.processCode(e.target.innerHTML);
            data[key] = styledCode;
         
        },
        allocationchanged(){
            this.isallocationchanged = true;
        },
        reportchanged(){
            this.isreportchanged = true;
        },
        getamosallocation(){
            this.$http.post("rowdesignation/getamosallocation", {userid:this.user.id})
                .then((response) => {
                    this.hideloading();
                    this.regex = response.data;
                    if(this.regex.id == 0){
                       this.setregexrotype();
                    }
                    this.$refs.globalregex.settexthighlight();
                    this.$refs.groupregex.settexthighlight();
                    this.$refs.singleregex.settexthighlight();
                    if(this.regex.userid  == 0){
                      this.regex.userid = this.user.id;
                    }
                    if(this.bqcodeid != null){
                        this.regex.bqcodeid = this.bqcodeid; 
                    }
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },
        setreportids(){
            this.regex.reportids = "";
            var newarray = this.reports.filter(x => {
                if(x.isignore != undefined && x.isignore == true){
                    return x.projectamosdetailid;       
                }
            });
            this.regex.reportids = newarray.map(x => x.projectamosdetailid).join(", ");
             

        },
        setregexrotype(){
            this.regex.globalregex.regextype = "global";
            this.regex.groupregex.regextype = "group"; 
            this.regex.singleregex.regextype = "single";
        },
        validatesave(){
            var errors = [];
            // if(this.regex.columntype == null){
            //     errors.push("Please select the Allocation Column first before save the allocation.");
            // }
            this.allocations.forEach(e => {
                if(e.globalregex.regex != null && e.globalregex.regex.length > 250){
                errors.push(e.columntype + " - Global REGEX must be 250 characters");
                }
                if(e.globalregex.regexinfo != null && e.globalregex.regexinfo.length > 250){
                    errors.push(e.columntype + " - Global REGEX Information must be 250 characters");
                }

                if(e.groupregex.regex != null && e.groupregex.regex.length > 250){
                    errors.push(e.columntype + " - Group REGEX must be 250 characters");
                }
                if(e.groupregex.regexinfo != null && e.groupregex.regexinfo.length > 250){
                    errors.push(e.columntype + " - Group REGEX Information must be 250 characters");
                }

                if(e.singleregex.regex != null && e.singleregex.regex.length > 250){
                    errors.push(e.columntype + " - Single REGEX must be 250 characters");
                }
                if(e.singleregex.regexinfo != null && e.singleregex.singleregex.regexinfo.length > 250){
                    errors.push(e.columntype + " - Single REGEX Information must be 250 characters");
                }
                if(e.globalregex &&  !this.$training.checkregex(e.globalregex.regex,'globalregex')){
                    errors.push(e.columntype + " - Invalid Regular Expression in Global REGEX");
                }    
                if(e.groupregex && !this.$training.checkregex(e.groupregex.regex,'groupregex')){
                    errors.push(e.columntype + " - Invalid Regular Expression in Group REGEX");
                }  
                if(e.singleregex && !this.$training.checkregex(e.singleregex.regex,'singleregex')){
                    errors.push(e.columntype + " - Invalid Regular Expression in Single REGEX");
                }    
            });
            
            // if(this.regex.bqcodeid == null){
            //     errors.push("Please select the BQ_Code first in the Selected Schedule and Row section before creating the report!");
            // }
            if(errors.length > 0){
                this.showmsg(errors.join("<br/>"));
                return false;
            }
            else{
                return true;
            }        
        },
        saveallocation(){
            var save = () =>{
           this.setregexrotype();
           this.setreportids();
            this.$http.post("rowdesignation/saveamosallocation", this.allocations)
                .then((response) => {
                    this.hideloading();
                    this.isreportchanged = false;
                    this.isallocationchanged = false;
                    // this.getamosallocation();
                    this.getregexbybqcodeid();
                    this.reports = []; 
                    this.showtabldata = [];
                    this.startlength = 0;
                     this.endlength = 20;
                     this.$message({
                            showClose: true,
                            message: "Save Successful", type: "success"
                        });
                    // this.reports = response.data; 
                    // this.showtabldata = [];
                    // this.startlength = 0;
                    //  this.endlength = 20;
                    // this.showtabldata.push(...this.reports.slice(this.startlength, this.endlength));
                    // this.setbqcodes(response.data); 
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
            }
            if(this.validatesave()){
                // if(this.checkallregex()){
                    save();
                // }
            }
        },
        dateformatter: function (_row, _column, cellValue) {
            if (cellValue) {
                return moment(cellValue).format("DD-MMM-YYYY");
            }
            return cellValue;
        },
        changepastsince(event){
            this.allocationchanged();
            if(event != null){
                this.regex.pastsince = event;
                this.GetMLBQcodes();
            }
        },
        setbqcodes(data){
              this.checkwords =  data.words.split(",");
              this.bqcodes = data.mlbqcodes; 
              this.mlbqcodes[this.amosrowselect.id] = data; 
              var newstatus = "";
              if (this.bqcodes.length == 0) {
                  newstatus = " No Matches"; 
              }
              else if (this.bqcodes.length == 1 ) {
                  newstatus = " Unique Match";
                
              }
              else if (this.bqcodes.length > 1) {
                  newstatus = " Choice of Matches";
              }
              this.bccodestatus = newstatus; 

        },
        deleteRow(event,index,row){
            this.reportchanged();
        },
        load: function () {
            if(this.endlength < this.reports.length){
                this.startlength += 20;
                this.endlength += 20;
                if(this.endlength > this.reports.length)
                {
                    this.endlength = this.reports.length;
                }
                var settext = this.setredtext(this.reports.slice(this.startlength, this.endlength)); 
                this.showtabldata.push(...settext);
            }
        },
        showmsg(error){
            var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(error+style, "Validation", {
                    dangerouslyUseHTMLString: true,
                });
        },
        validatereport(){
            var errors = [];
            if(this.isreportchanged == true){
                errors.push("Do you really want to change report? you have unsaved changes!");
            }
            if(this.regex.bqcodeid == null){
                errors.push("Please select the BQ_Code first in the Selected Schedule and Row section before creating the report!");
            }
            if(errors.length > 0){
                this.showmsg(errors.join("<br/>"));
                return false;
            }
            else{
                return true;
            }        
        },
        exportreport(){
            var exportdata = () =>{
                this.$http.post("rowdesignation/exportreportxlms", this.regex,{ responseType: 'blob' })
                .then((response) => {
                    this.hideloading();
                    var blob = response.data;
                    var fileName = 'AMOSAllocationAlgorithm.xlsx';
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
            }
            if(this.validatereport()){
                // if(this.checkallregex()){
                    exportdata()
                // }
            }
        },
        getreport(){
            var save = ()=>{
            this.$http.post("rowdesignation/getamosallocationreport", this.regex)
                .then((response) => {
                    this.hideloading();
                    this.reports = response.data;
                    if(this.reports.length <= 0){
                        this.$message('AMOS data does not exist for this filter!');
                    } 
                    this.showtabldata = [];
                    this.startlength = 0;
                     this.endlength = 20;
                    this.showtabldata.push(...this.reports.slice(this.startlength, this.endlength));
                    // this.setbqcodes(response.data); 
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
            }
            if(this.validatereport()){
                // if(this.checkallregex()){
                    save()
                // }
            }
        },
       
        querySearchBQCode(query, done){
            if(this.bqcodes.length <= 0){
                done([])    
            }else{
                if(query == "" || query == undefined ){
                    done(this.bqcodes)    
                }else{
                    var isfind = this.bqcodes.filter(x => x.bql.bqcode.includes(query));
                    done(isfind)
                }
            }
        },
        GetMLBQcodes(){
            // if(this.mlbqcodes[this.amosrow.amosrow.id] == undefined){
            this.showloading();
            var obj ={
                text:this.amosrowselect.searchtext,
                sectionname:this.project.section.section,
                sectionid:null,
                editedon:this.regex.pastsince,
                include:"",
                exclude:"",
                }
            this.$http.post("rowdesignation/GetMLBQcode", obj)
                .then((response) => {
                    this.hideloading();
                    this.setbqcodes(response.data); 
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
            // }else{
            //     this.setbqcodes(this.mlbqcodes[this.amosrow.amosrow.id]);
            // }
        },
        handleSelectBQCode(event){
            this.showloading();
            this.bqcodeid = event.bqcodeid;
            this.regex.bqcodeid = event.bqcodeid;
            this.bqcode = event.bql.bqcode;
            this.getregexbybqcodeid();
        },
        getregexbybqcodeid(){
            this.$http.post("rowdesignation/getregexbybqcodeid",{bqcodeid:this.bqcodeid})
                .then((response) => {
                    this.allocations = response.data; 
                    this.hideloading();
                    // if(response.data.id == 0){
                    //     response.data.columntype = this.regex.columntype; 
                    //     response.data.bqcodeid  = this.regex.bqcodeid; 
                    // }
                    // this.regex = response.data;
                    // this.$refs.globalregex.settexthighlight();
                    // this.$refs.groupregex.settexthighlight();
                    // this.$refs.singleregex.settexthighlight();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },
        changebqcode(id) {
            if (id != "") {
                this.bqcode = id;
                // this.regex.bqcodeid = id.bqcodeid;
            } else {
                this.bqcode = null;
                this.regex.bqcodeid = null;
            }
        },
        changeamosrow(id) {
            if (id != "") {
                this.amosrowselect = this.amosfile.details.find(x => x.id == id);
                this.GetMLBQcodes();
                this.setrowstyle(this.amosrowselect);
            } else {
                this.amosrowselect = null;
            }
            this.bqcodes = [];
            this.bqcode = null;
            this.allocations = [];
            this.columntype = null;
            this.bqcodeid = null;
            this.regex.bqcodeid = null;
        },
        changefile(id) {
            if (id != "") {
                this.amosfile = this.projectamos.projectamoses.find(x => x.id == id);
            } else {
                this.amosfile = null;
            }
            this.bqcodes = [];
            this.bqcode = null;
            this.bqcodeid = null;
            this.regex.bqcodeid = null;
            this.amosrowid = null;
            this.amosrowselect = null;
        },
        
        closebox() {
            if(this.isallocationchanged == true){
                this.showmsg("Do you really want to leave? you have unsaved changes!");
            }else{
                this.$emit("closeallocation", false);
            }
        },
        projectsectionchange: function (Projectobj) {
            this.sectionid = Projectobj.sectionid;
            this.project = Projectobj.project;
            if(this.sectionid){
                this.regex.section = this.project.section.section; 
                this.regex.projectid = this.project.id;
                // this.regex.editedon = null; 
                // this.regex.editedon = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
                this.$set(this.regex,'pastsince',new Date(new Date().setFullYear(new Date().getFullYear() - 1)))
                this.changeamosrow(this.amosrowselect.id);
            }
        },
    },
    created() {
        //this.getamosallocation();
        this.amosrowselect = this.amosrow;
        this.projectamos.projectamoses.forEach(e => {
            var index = 0;
            e.details.forEach((d, i) => {
                if(this.amosrowselect.id == d.id) {
                    this.amosrowid = d.id;
                    this.amosrowselect = d;
                    this.amosfile = e;
                    this.amosfileid = e.id;
                }
                if (d.predicate == "CH") {
                    index = i;
                } else {
                    var text = "";
                    for (let index = 1; index <= 20; index++) {
                                const element = d["col"+index];
                                if(element != undefined){
                                    text += element;
                                }
                            }
                    d.searchtext = text;                            
                    d.chinddex = index;
                }
            })
        });
        // this.GetMLBQcodes();
        
    }
}
</script>
<style>
.blueshadowbox{
    background-color: #e0f5ff;
}
.disabledDiv {
    pointer-events: none;
    opacity: 0.4;
    color: #C0C4CC !important;
}
.regexinfobox{
     height: 50px; 
     height: 100%;
     min-height: 50px;
     border-radius: 4px;
}
.schedulerow .el-collapse-item__header {
    background-color: #569bb9;
    color: white;
}

.regexbqcode .el-collapse-item__header {
    background-color: #7e7e7e;
    color: white;
}

.regexreport .el-collapse-item__header {
    background-color: #78c3a5;
    color: white;
}
</style>